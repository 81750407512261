import React, { FC, useState } from 'react';
import UploadUserDialog from '../../Components/Dialogs/UploadUserDialog/UploadUserDialog';
import { importUsersFromFile } from '../../Services/http/user.service';
import ActionButton from '../../Components/ActionButton/ActionButton';
import UploadCompleteDialog from '../../Components/Dialogs/UploadCompleteDiaog/UploadCompleteDialog';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

interface Props {
    onCompleteCallback?: () => void;
}
const UploadUserActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);

    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    const toggleCompleteDialog = () => {
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
    };

    const handleUpload = (files: File[], email: string, sendEmailToUsers: boolean) => {
        const formData = new FormData();
        formData.append('userCSV', files[0]);
        importUsersFromFile(formData, email, sendEmailToUsers)
            .then((res: any) => {
                toggleDialog();
                toggleCompleteDialog();

                if (onCompleteCallback) {
                    onCompleteCallback();
                }
            })
            .catch((err) => {
                setAlertOpen(true);
                setErrorMessage(err.message);
            });
    };

    const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertOpen(false);
    };

    return (
        <>
            <ActionButton text={'Upload users'} icon={null} onClick={toggleDialog} />
            <UploadUserDialog
                isDialogOpen={isDialogOpen}
                handleUpload={handleUpload}
                onClose={toggleDialog}
            />
            <UploadCompleteDialog
                isDialogOpen={isCompleteDialogOpen}
                onClose={toggleCompleteDialog}
            />
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default UploadUserActionContainer;
