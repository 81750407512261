import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            '& .MuiBox-root': {
                color: 'red',
                display: 'flex',
            },
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: '1 1 20%',
        },
        button: {
            margin: theme.spacing(1),
        },
        row: {
            display: 'flex',
        },
    }),
);

interface EnhancedTableToolbarProps {
    numSelected: number;
    actionElementsWithNoSelection?: ReactNode[];
    actionItems?: ReactNode[];
    userRoleFilter?: ReactNode;
    eventFilter?: ReactNode;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, actionItems, actionElementsWithNoSelection, userRoleFilter, eventFilter } =
        props;

    const singleItemActions = [
        'edit user action',
        'edit event action',
        'refresh event action',
        'delete user',
    ];

    const renderIfSingleItemSelected = (actionItem: any) => {
        return numSelected === 1 ? actionItem : null;
    };

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="subtitle1"
                    id="tableTitle"
                    component="div"
                >
                    No items selected
                </Typography>
            )}
            {userRoleFilter}
            {eventFilter}
            {numSelected > 0 ? (
                <Box>
                    {actionItems &&
                        actionItems.map((actionItem) =>
                            singleItemActions.includes((actionItem as unknown as any).key)
                                ? renderIfSingleItemSelected(actionItem)
                                : actionItem,
                        )}
                </Box>
            ) : (
                <Box>
                    {actionElementsWithNoSelection &&
                        actionElementsWithNoSelection.map((actionElement) => actionElement)}
                </Box>
            )}
        </Toolbar>
    );
};

export default EnhancedTableToolbar;
