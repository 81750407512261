import React, { createContext, Dispatch, FC, Reducer, useReducer } from 'react';
import useLogger from '../Hooks/Middleware/userLogger';
import { Conversation, Room } from '../Models/socket.model';

// Actions
export const UPDATE_SELECTED_ROOM = 'UPDATE_SELECTED_ROOM';
export const UPDATE_SELECTED_MESSAGE = 'UPDATE_SELECTED_MESSAGE';

// Action interface
interface UpdateSelectedRoomAction {
    type: typeof UPDATE_SELECTED_ROOM;
    payload: Room | null;
}
interface UpdateSelectedRoomMessage {
    type: typeof UPDATE_SELECTED_MESSAGE;
    payload: Conversation[];
}

// Actions type
type RoomManagementActions = UpdateSelectedRoomAction | UpdateSelectedRoomMessage;

// State
type State = {
    selectedRoom: Room | null;
    selectedMessage: Conversation[];
};

const initialState: State = {
    selectedRoom: null,
    selectedMessage: [],
};

export const RoomManagementContext = createContext<{
    state: State;
    dispatch: Dispatch<RoomManagementActions>;
}>({
    state: initialState,
    dispatch: () => {},
});

const roomManagementReducer: Reducer<State, RoomManagementActions> = (
    state: State,
    action: RoomManagementActions,
) => {
    switch (action.type) {
        case UPDATE_SELECTED_ROOM: {
            return {
                ...state,
                selectedRoom: action.payload,
            };
        }
        case UPDATE_SELECTED_MESSAGE: {
            return {
                ...state,
                selectedMessage: action.payload,
            };
        }
        default:
            return state;
    }
};

export const RoomManagementContextProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(useLogger(roomManagementReducer), initialState);

    const value = { state, dispatch };

    return (
        <RoomManagementContext.Provider value={value}>{children}</RoomManagementContext.Provider>
    );
};
