import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { fetchEvents } from '../../Services/http/event.service';
import {
    EVENT_CODE,
    UPDATE_EVENT_LIST,
    UPDATE_SELECTED_EVENTS,
} from '../../Contexts/eventManagementContext';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import TableSearch from '../../Components/TableSearch/TableSearch';
import EventManagementTable from '../../Components/EventManagementTable/EventManagementTable';
import EditEventActionContainer from '../../Containers/EditEventActionContainer/EditEventActionContainer';
import CreateEventActionContainer from '../../Containers/CreateEventActionContainer/CreateEventActionContainer';
import { EventCell } from '../../Models/table-cells.model';
import EventAdministrationContainer from '../EventAdministrationContainer/EventAdministrationContainer';
import ExtractStatisticActionContainer from '../ExtractStatisticActionContainer/ExtractStatisticActionContainer';
import RefreshEventActionContainer from '../RefreshEventActionContainer/RefreshEventActionContainer';

const EventManagementTableContainer: FC = () => {
    const { state, dispatch } = useEventManagement();

    const [events, setEvents] = useState([] as EventCell[]);
    const [filteredEvents, setFilteredEvents] = useState([] as EventCell[]);
    const [selectedEvents, setSelectedEvents] = useState([] as EventCell[]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [totalEventsCount, setTotalEventsCount] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const [tableHeaderFilters, setTableHeaderFilters] = useState<string[]>();

    const order_by = tableHeaderFilters ? tableHeaderFilters[0] : undefined;
    const order_direction = tableHeaderFilters ? tableHeaderFilters[1] : undefined;

    useEffect(() => {
        fetchEventsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    useEffect(() => {}, [
        events,
        filteredEvents,
        order_by,
        order_direction,
        searchText,
        tableHeaderFilters,
    ]);

    const handleActionComplete = useCallback(() => {
        fetchEventsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchEventsList = () => {
        setIsLoading(true);
        getTotalEvents(page, rowsPerPage, order_by, order_direction, searchText);
    };

    // Get total event list
    const getTotalEvents = (
        page: number,
        rowsPerPage: number,
        order_by?: string,
        order_direction?: string,
        search?: string,
    ) => {
        if (!order_by) order_by = 'name';
        if (!order_direction) order_direction = 'asc';
        fetchEvents(page, rowsPerPage, order_by, order_direction, search)
            .then((res: any) => assignResponse(res))
            .catch((e) => {
                console.error(e);
                setIsLoading(false);
            });
    };

    // Assign value to local state variables
    const assignResponse = (res: any) => {
        const events = res?.data?.events;
        setEvents(events);
        setFilteredEvents(events);
        setIsLoading(false);
        setTotalEventsCount(res?.data?.total_count);
        dispatch({ type: UPDATE_EVENT_LIST, payload: { events } });
    };

    // On a event selections
    const handleSetSelectedEvents = (events: EventCell[]) => {
        setSelectedEvents(events);
        dispatch({ type: UPDATE_SELECTED_EVENTS, payload: { selectedEvents: events } });
        dispatch({ type: EVENT_CODE, payload: events[0]?.event_code });
    };

    // On search input value change
    const handleOnSearchValueChange = (searchValue?: any) => {
        setPage(1);
        searchValue?.length ? setSearchText(searchValue) : setSearchText('');
        getTotalEvents(page, rowsPerPage, order_by, order_direction, searchValue);
    };

    const actionItems: ReactNode[] = [
        <ExtractStatisticActionContainer
            key="extract statistic action"
            onCompleteCallback={handleActionComplete}
        />,
        <RefreshEventActionContainer
            key="refresh event action"
            onCompleteCallback={handleActionComplete}
        />,
        <EventAdministrationContainer
            key="event administration action"
            onCompleteCallback={handleActionComplete}
        />,
        <EditEventActionContainer
            key="edit event action"
            onCompleteCallback={handleActionComplete}
        />,
    ];

    return (
        <Paper elevation={0}>
            <Box height="100%" width="100%" marginLeft="auto" marginRight="auto">
                <TableSearch searchText={searchText} onSearch={handleOnSearchValueChange} />
                <EventManagementTable
                    isLoading={isLoading}
                    refreshEventsList={fetchEventsList}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    selectedItems={selectedEvents}
                    setSelectedItems={handleSetSelectedEvents}
                    events={filteredEvents}
                    totalEvents={totalEventsCount}
                    createEventActionElement={
                        <CreateEventActionContainer
                            key={'create event action'}
                            onCompleteCallback={handleActionComplete}
                        />
                    }
                    actionItems={actionItems}
                    tableHeadFilter={setTableHeaderFilters}
                />
            </Box>
        </Paper>
    );
};

export default EventManagementTableContainer;
