import React, { FC } from 'react';
import { DataGrid, GridRowModel } from '@material-ui/data-grid';

interface Props {
    conversationTableRows: GridRowModel[];
    columns: {
        field: string;
        headerName: string;
        flex: number;
        options?: {
            sortDirection: string;
        };
    }[];
    setSelectedMessage: (event: any) => void;
    isLoading: boolean;
}

export const RoomChatManagementTable: FC<Props> = ({
    conversationTableRows,
    columns,
    setSelectedMessage,
    isLoading = false,
}) => {
    return (
        <DataGrid
            rows={conversationTableRows}
            columns={columns}
            autoHeight={true}
            pageSize={15}
            checkboxSelection
            onSelectionModelChange={setSelectedMessage} // TODO: changed from onSelectionChange, not sure if this is right
            loading={isLoading}
        />
    );
};
