import React, { FC, useCallback, useEffect } from 'react';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme: Theme) => ({
        container: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: 20,
        },
        main: {
            display: 'flex',
            flex: '0.3 0 auto',
        },
        searchIcon: {
            color: theme.palette.text.secondary,
            marginTop: '7px',
            marginRight: '2px',
        },
        searchText: {
            flex: '1 0',
        },
        clearIcon: {
            '&:hover': {
                color: theme.palette.error.main,
            },
        },
    }),
    { name: 'MUIDataTableSearch' },
);

interface Props {
    onSearch: (text: any) => void;
    setSearchText?: Function;
    searchText?: string;
}

const TableSearch: FC<Props> = ({ onSearch, searchText }) => {
    const classes = useStyles();

    const handleTextChange = (value: any) => {
        onSearch(value?.target?.value);
    };

    return (
        <div className={classes.container}>
            <Grow appear in={true} timeout={300}>
                <div className={classes.main}>
                    <SearchIcon className={classes.searchIcon} />

                    <TextField
                        placeholder="Search..."
                        className={classes.searchText}
                        value={searchText || ''}
                        fullWidth={true}
                        onChange={handleTextChange}
                    />
                </div>
            </Grow>
        </div>
    );
};

export default TableSearch;
