import React, { FC, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';

interface Props {
    onSubmit: Function;
    loginFailMsg: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: 400,
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        errorStatus: {
            marginTop: theme.spacing(3),
            color: '#b71c1c',
        },
        input: {
            '&::placeholder': {
                textAlign: 'center',
            },
        },
        label: {
            textAlign: 'center',
        },
        loginButton: {
            marginTop: theme.spacing(6),
        },
    }),
);

const LoginForm: FC<Props> = ({ onSubmit, loginFailMsg }) => {
    const classes = useStyles();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [eventName, setEventName] = useState('');

    const errorColor = red[900];
    const onClick = (e: any) => {
        e.preventDefault();
        onSubmit(username, password, eventName);
    };

    return (
        <form className={classes.root} noValidate onSubmit={onClick}>
            <div>
                <TextField
                    placeholder="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{ classes: { input: classes.input } }}
                    autoComplete={'email'}
                />
            </div>
            <div>
                <TextField
                    placeholder="password"
                    type="password"
                    value={password}
                    InputProps={{ classes: { input: classes.input } }}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete={'current-password'}
                />
            </div>
            <div>
                <TextField
                    placeholder="event code"
                    value={eventName}
                    InputProps={{ classes: { input: classes.input } }}
                    onChange={(e) => setEventName(e.target.value)}
                    autoComplete={'on'}
                />
            </div>
            {loginFailMsg && (
                <div>
                    <Typography variant="subtitle2" className={classes.errorStatus}>
                        {loginFailMsg}
                    </Typography>
                </div>
            )}
            <div className={classes.loginButton}>
                <Button variant="contained" color="primary" type={'submit'} onClick={onClick}>
                    Login
                </Button>
            </div>
        </form>
    );
};

export default LoginForm;
