export enum RegistrationFields {
    MAIN_TITLE = 'MainTitle',
    SUBTITLE = 'Subtitle',
    FIRSTNAME = 'Firstname',
    LASTNAME = 'Lastname',
    EMAIL = 'EMail',
    COMPANY = 'Company',
    JOB_TITLE = 'JobTitle',
    PROFILE_PIC = 'ProfilePic',
    SHOWLINK_CHECKBOX = 'ShowlinkCheckBox',
}
