import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import UserManagementTableContainer from '../../Containers/UserManagementTableContainer/UserManagementTableContainer';
import { UserManagementContextProvider } from '../../Contexts/userManagementContext';
import { Breadcrumbs, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    breadcrumb: {
        marginTop: theme.spacing(6),
    },
}));

export const UserManagementPage: FC = () => {
    const classes = useStyles();
    return (
        <UserManagementContextProvider>
            <Container maxWidth={'xl'}>
                <div className={classes.breadcrumb}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href="/">
                            Home
                        </Link>
                        <Link color="textPrimary" href="/user-management" aria-current="page">
                            User Management
                        </Link>
                    </Breadcrumbs>
                </div>
                <UserManagementTableContainer />
            </Container>
        </UserManagementContextProvider>
    );
};
