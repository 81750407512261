import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface Props {
    open: boolean;
    selectedUsers: any[];
    onClose: () => void;
    onConfirm: () => void;
    dialogTitle: string;
    userActionDescription?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    overrideAllText?: string;
}

const UserActionDialog: FC<Props> = ({
    open,
    onClose,
    onConfirm,
    selectedUsers,
    dialogTitle,
    userActionDescription,
    children,
    disabled,
    overrideAllText,
}) => {
    const userList =
        selectedUsers.length > 1
            ? `Are you sure these ${selectedUsers.length} users`
            : 'Are you sure this user';

    return (
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent dividers>
                {overrideAllText ? (
                    <Typography>{overrideAllText}</Typography>
                ) : (
                    <Typography color="primary" gutterBottom>
                        {`${userList} ${userActionDescription}?`}
                    </Typography>
                )}

                {overrideAllText
                    ? ''
                    : selectedUsers.map((eachUser, index) => (
                          <Typography key={eachUser.id} gutterBottom>
                              {index + 1}. {eachUser.username}
                          </Typography>
                      ))}
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" disabled={disabled}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserActionDialog;
