import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import UploadAndChangeFile from '../../Components/UploadAndChangeFile/UploadAndChangeFile';
import { FileTypes } from '../../Enums/FileTypes.enum';
import { InputFileType } from '../../Enums/InputFileType.enums';
import { List } from '@material-ui/core';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { postFile, deleteFile } from '../../Services/http/content.service';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() =>
    createStyles({
        bannersList: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px',
        },
        accordion: {
            display: 'flex',
            flexDirection: 'column',
        },
        bannerRow: { width: '100%', marginBottom: '10px' },
        banner: {
            width: '50%',
        },
    }),
);

const EmailTemplatesAccordionContainer = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { state, dispatch } = useEventManagement();

    const [registrationPlainTextFile, setRegistrationPlainTextFile] = useState<File | null>(null);
    const [registrationPlainTextFileChanged, setRegistrationPlainTextFileChanged] =
        useState<boolean>(false);

    const [registrationHtmlFile, setRegistrationHtmlFile] = useState<File | null>(null);
    const [registrationHtmlFileChanged, setRegistrationHtmlFileChanged] = useState<boolean>(false);

    const [resetPlainTextFile, setResetPlainTextFile] = useState<File | null>(null);
    const [resetPlainTextFileChanged, setResetPlainTextFileChanged] = useState<boolean>(false);

    const [resetHtmlFile, setResetHtmlFile] = useState<File | null>(null);
    const [resetHtmlFileChanged, setResetHtmlFileChanged] = useState<boolean>(false);

    const [disabled, setDisabled] = useState<boolean>(true);

    const emailData = {
        registrationPlainInfo: {
            title: 'Plain text version',
            labelName: 'registration_plain_button',
            defaultLabelName: 'default_registration_plain_button',
            acceptType: FileTypes.EMAIL,
            type: 'registration_plain',
        },
        registrationHtmlInfo: {
            title: 'HTML version',
            labelName: 'registration_html_button',
            defaultLabelName: 'default_registration_html_button',
            acceptType: FileTypes.EMAIL,
            type: 'registration_html',
        },
        resetPlainInfo: {
            title: 'Plain text version',
            labelName: 'reset_plain_button',
            defaultLabelName: 'default_reset_plain_button',
            acceptType: FileTypes.EMAIL,
            type: 'reset_plain',
        },
        resetHtmlInfo: {
            title: 'HTML version',
            labelName: 'reset_html_button',
            defaultLabelName: 'default_rreset_html_button',
            acceptType: FileTypes.EMAIL,
            type: 'reset_html',
        },
    };

    const onCancel = async () => {
        clearFiles();
    };

    const onConfirm = async () => {
        const fileDataArray = [
            {
                file: registrationPlainTextFile,
                changedState: registrationPlainTextFileChanged,
                fileName: 'registrationEmailPlain.txt',
            },
            {
                file: registrationHtmlFile,
                changedState: registrationHtmlFileChanged,
                fileName: 'registrationEmail.html',
            },
            {
                file: resetPlainTextFile,
                changedState: resetPlainTextFileChanged,
                fileName: 'resetEmailPlain.txt',
            },
            {
                file: resetHtmlFile,
                changedState: resetHtmlFileChanged,
                fileName: 'resetEmail.html',
            },
        ];

        fileDataArray.map((emailData) => {
            if (emailData.changedState) {
                handleSelectedFile(emailData, FileTypes.EMAIL, state.eventCode);
            }
        });
        clearFiles();
    };

    const handleSelectedFile = (emailData: any, fileType: FileTypes, event_code: string) => {
        if (emailData.file) {
            const tempFile: any = emailData.file;
            const newFile = new File([tempFile], emailData.fileName);
            uploadFile(newFile, fileType, event_code);
        } else {
            deleteEmailFile(emailData.fileName, fileType, event_code);
        }
    };

    const clearFiles = () => {
        setRegistrationPlainTextFile(null);
        setRegistrationPlainTextFileChanged(false);
        setRegistrationHtmlFile(null);
        setRegistrationHtmlFileChanged(false);

        setResetPlainTextFile(null);
        setResetPlainTextFileChanged(false);
        setResetHtmlFile(null);
        setResetHtmlFileChanged(false);

        setDisabled(true);
    };

    const uploadFile = (file: File, fileType: FileTypes, event_code: string) => {
        state.eventCode &&
            postFile(file, fileType, event_code)
                .then(() => {
                    enqueueSnackbar('The new file has been uploaded successfully.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                });
    };

    const deleteEmailFile = (filename: string, fileType: FileTypes, event_code: string) => {
        state.eventCode &&
            deleteFile(filename, fileType, event_code)
                .then(() => {
                    enqueueSnackbar('File has been switched to default version.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                });
    };

    const renderUploadFile = (templateData: any) => {
        let selectedFile = null;
        let setSelectedFile: any;
        let fileType = '';
        let changed = false;
        let setChanged: any;

        switch (templateData.type) {
            case 'registration_plain': {
                selectedFile = registrationPlainTextFile;
                setSelectedFile = setRegistrationPlainTextFile;
                changed = registrationPlainTextFileChanged;
                setChanged = setRegistrationPlainTextFileChanged;
                fileType = `${InputFileType.MARKDOWN_TEXT_FILE},${InputFileType.HTML}`;
                break;
            }
            case 'registration_html': {
                selectedFile = registrationHtmlFile;
                setSelectedFile = setRegistrationHtmlFile;
                changed = registrationHtmlFileChanged;
                setChanged = setRegistrationHtmlFileChanged;
                fileType = `${InputFileType.MARKDOWN_TEXT_FILE},${InputFileType.HTML}`;
                break;
            }
            case 'reset_plain': {
                selectedFile = resetPlainTextFile;
                setSelectedFile = setResetPlainTextFile;
                changed = resetPlainTextFileChanged;
                setChanged = setResetPlainTextFileChanged;
                fileType = `${InputFileType.MARKDOWN_TEXT_FILE},${InputFileType.HTML}`;
                break;
            }
            case 'reset_html': {
                selectedFile = resetHtmlFile;
                setSelectedFile = setResetHtmlFile;
                changed = resetHtmlFileChanged;
                setChanged = setResetHtmlFileChanged;
                fileType = `${InputFileType.MARKDOWN_TEXT_FILE},${InputFileType.HTML}`;
                break;
            }

            default:
                break;
        }
        return (
            <div className={classes.bannerRow}>
                <UploadAndChangeFile
                    templateData={templateData}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    fileChanged={changed}
                    setFileChanged={setChanged}
                    inputFileType={fileType}
                    setDisabled={setDisabled}
                />
            </div>
        );
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>Email Templates</strong>
                </Typography>
            </AccordionSummary>
            <List className={classes.bannersList}>
                <Typography>
                    <strong>Registration</strong>
                </Typography>
                {renderUploadFile(emailData.registrationPlainInfo)}
                {renderUploadFile(emailData.registrationHtmlInfo)}
                <Typography>
                    <strong>Reset Password</strong>
                </Typography>
                {renderUploadFile(emailData.resetPlainInfo)}
                {renderUploadFile(emailData.resetHtmlInfo)}
            </List>
            <CancelAndConfirmButton
                onCancel={() => onCancel()}
                onSubmit={() => onConfirm()}
                disabled={disabled}
            />
        </Accordion>
    );
};

export default EmailTemplatesAccordionContainer;
