import React, { FC, useEffect, useState } from 'react';
import { Checkbox, makeStyles, TableCell, TextField, Select, MenuItem } from '@material-ui/core';
import { Preference } from '../../Models/event.model';
import PreferenceGroupRow from './PreferenceGroupRow';

const useStyles = makeStyles(() => ({
    checkboxHidden: {
        display: 'none',
    },
    itemCheckBox: {
        display: 'none',
        width: '10%',
    },
    itemLabel: {
        display: 'none',
        width: '50%',
    },
    checkbox: {
        display: 'flex',
        flexDirection: 'column',
        width: 'inherit',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
}));

interface Props {
    preferenceDisplay: Preference;
    preferenceMandatory: Preference;
    preferenceLabel: Preference;
    originalDisplay: (newValue: string) => any;
    originalMandatory: (newValue: string) => any;
    originalLabel: (newValue: string) => any;
    showDisplayedCheckbox: boolean;
    showMandatoryCheckbox: boolean;
    showLabelCheckbox: boolean;
    rowTitleStr: string;
}

const PreferenceRowMultiPicker: FC<Props> = ({
    rowTitleStr,
    preferenceDisplay,
    preferenceMandatory,
    preferenceLabel,
    originalDisplay,
    originalMandatory,
    originalLabel,
    showDisplayedCheckbox,
    showMandatoryCheckbox,
    showLabelCheckbox,
}) => {
    const classes = useStyles();
    const [checkedDisplay, setCheckedDisplay] = useState<boolean>(preferenceDisplay.value == 'yes');
    const [checkedMandatory, setCheckedMandatory] = useState<boolean>(
        preferenceMandatory.value == 'yes',
    );
    const [checkedLabel, setCheckedLabel] = useState<boolean>(!(preferenceLabel.value === ' '));

    useEffect(() => {
        setCheckedDisplay(preferenceDisplay.value == 'yes');
        setCheckedMandatory(preferenceMandatory.value == 'yes');
        setCheckedLabel(preferenceLabel.value == ' ');
    }, [preferenceDisplay, preferenceMandatory, preferenceLabel]);

    const onToggleDisplay = (event: any) => {
        setCheckedDisplay(event.target.checked);
        const newValue = event.target.checked ? 'yes' : 'no';
        return newValue;
    };
    const onToggleMandatory = (event: any) => {
        setCheckedMandatory(event.target.checked);
        const newValue = event.target.checked ? 'yes' : 'no';
        return newValue;
    };
    const onToggleLabel = (event: any) => {
        setCheckedLabel(true);
        const newValue = event.target.value;
        return newValue;
    };

    // ...we extend the onChange function that is passed down as a prop with the behaviour specific to boolean
    const onChangeDisplay: (newValue: string) => void = (...args) => {
        const newValue = onToggleDisplay.apply(this, args);
        originalDisplay.apply(this, [newValue]);
        return newValue;
    };
    const onChangeMandatory: (newValue: string) => void = (...args) => {
        const newValue = onToggleMandatory.apply(this, args);
        originalMandatory.apply(this, [newValue]);
        return newValue;
    };
    const onChangeLabel: (newValue: string) => void = (...args) => {
        const newValue = onToggleLabel.apply(this, args);
        originalLabel.apply(this, [newValue]);
        return newValue;
    };

    return (
        <PreferenceGroupRow
            preferenceDisplay={preferenceDisplay}
            preferenceMandatory={preferenceMandatory}
            preferenceLabel={preferenceLabel}
            onChangeDisplay={onChangeDisplay}
            onChangeMandatory={onChangeMandatory}
            onChangeLabel={onChangeLabel}
            rowTitleStr={rowTitleStr}
        >
            {/* ...the onChange props is shallow merged into the children by PreferenceRow */}
            {showDisplayedCheckbox ? (
                <Checkbox
                    className={classes.checkbox}
                    checked={checkedDisplay}
                    onChange={() => {}}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            ) : (
                <div className={classes.itemCheckBox} />
            )}
            {showMandatoryCheckbox ? (
                <Checkbox
                    className={classes.checkbox}
                    checked={checkedMandatory}
                    onChange={() => {}}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            ) : (
                <div className={classes.itemCheckBox} />
            )}
            {showLabelCheckbox ? (
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={preferenceLabel.value}
                    size="small"
                    className={classes.checkbox}
                    onChange={() => {}}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            ) : (
                <div className={classes.itemLabel} />
            )}
        </PreferenceGroupRow>
    );
};

export default PreferenceRowMultiPicker;
