import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ActionButton from '../../Components/ActionButton/ActionButton';

const useStyles = makeStyles(() => ({
    buttonWrapper: {
        marginBottom: '1%',
        marginRight: '2%',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));
interface Props {
    disabled: boolean;
    disabledSubmit?: boolean;
    onCancel: () => void;
    onSubmit: () => void;
}
const CancelAndConfirmButton: FC<Props> = ({ disabled, disabledSubmit, onCancel, onSubmit }) => {
    const classes = useStyles();
    return (
        <div className={classes.buttonWrapper}>
            <div className={classes.buttons}>
                <ActionButton onClick={onCancel} text={'Cancel'} disabled={disabled} />
                <ActionButton
                    text={'Confirm'}
                    disabled={disabledSubmit ? disabledSubmit : disabled}
                    onClick={onSubmit}
                />
            </div>
        </div>
    );
};

export default CancelAndConfirmButton;
