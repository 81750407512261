/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { MenuItem, Select, makeStyles, Checkbox, Typography } from '@material-ui/core';

import { Preference } from '../../Models/event.model';

const useStyles = makeStyles(() => ({
    dropdown: {
        width: '10%',
        margin: '0 auto',
    },
    checkbox: {
        display: 'flex',
        width: '20%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    title: {
        display: 'flex',
        width: '15%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    infoBar: {
        width: '10%',
    },
    default: {
        color: 'gray',
    },
    valueChange: {
        color: 'red',
    },
}));

interface Props {
    isAdmin: boolean;
    preference: Preference;
    originalOnChange: (newValue: any) => void;
    originalOnChangeCheckbox: (newValue: any) => void;
    preferenceControl: Preference;
}

const PreferenceRowDropdown: FC<Props> = ({
    isAdmin,
    preference,
    originalOnChange,
    originalOnChangeCheckbox,
    preferenceControl,
}) => {
    const classes = useStyles();
    const [checkedPreference, setChecked] = useState<boolean>(
        preferenceControl?.initialValue === 'yes',
    );
    const [selectedPreference, setSelectedPreference] = useState<string>(preference?.initialValue!);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isChangedDropdown, setIsChangedDropdown] = useState<boolean>(false);
    const [isChangedCheckbox, setIsChangedCheckbox] = useState<boolean>(false);

    const checkIfValueChanged = (newValue: string, preference: Preference, type: string) => {
        let changed = false;
        const initialValue = preference.initialValue;
        if (newValue !== initialValue) {
            changed = true;
        } else {
            changed = false;
        }
        if (type === 'D') {
            setIsChangedDropdown(changed);
            setIsChanged(changed || isChangedCheckbox);
        } else if (type === 'C') {
            setIsChangedCheckbox(changed);
            setIsChanged(changed || isChangedDropdown);
        }
    };

    useEffect(() => {
        setChecked(preferenceControl?.value === 'yes');
        setSelectedPreference(preference?.value!);
        return () => {
            setIsChanged(false);
            setIsChangedCheckbox(false);
            setIsChangedDropdown(false);
        };
    }, [preference, preferenceControl]);

    const onToggleCheckbox = (isChecked: boolean) => {
        setChecked(isChecked);
        const newValue = isChecked ? 'yes' : 'no';
        return newValue;
    };

    const onChangeDropdown = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
    ) => {
        const newValue = e.target.value as string;
        originalOnChange(newValue);
        setSelectedPreference(newValue);
        checkIfValueChanged(newValue, preference, 'D');
    };

    const onChangeCheckbox = (e: any, checked: boolean) => {
        const newValue = onToggleCheckbox(checked);
        originalOnChangeCheckbox(newValue);
        checkIfValueChanged(newValue, preferenceControl, 'C');
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>{preference?.name}</div>
            <Select
                className={classes.dropdown}
                variant="standard"
                value={selectedPreference}
                onChange={onChangeDropdown}
            >
                <MenuItem value={'yes'}>yes</MenuItem>
                <MenuItem value={'no'}>no</MenuItem>
                <MenuItem value={'auto'}>auto</MenuItem>
                <MenuItem value={'access_code'}>access_code</MenuItem>
            </Select>
            {isAdmin && (
                <div className={classes.checkbox}>
                    <Checkbox
                        checked={checkedPreference}
                        onChange={onChangeCheckbox}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
            )}

            <div className={classes.infoBar}>
                <Typography className={isChanged ? classes.valueChange : classes.default}>
                    {isChanged ? 'Changed' : 'No change'}
                </Typography>
            </div>
        </div>
    );
};

export default PreferenceRowDropdown;
