import React, { FC, ReactNode, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import EnhancedTableToolbar from '../EnhancedTableToolbar/EnhancedTableToolbar';
import EnhancedTableHead, { HeadCell } from '../EnhancedTableHead/EnhancedTableHead';
import { Role, User } from '../../Models/user.model';
import { Order, stableSort, getComparator } from '../../Utils/tableHelpers';
import Row from './Row';
import { UserCell, UserSubCell } from '../../Models/table-cells.model';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaginationComponent from '../Pagination/Pagination';

function createData(
    id: number,
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    company: string,
    title: string,
    active: boolean,
    not_tracked: boolean,
    subCells: UserSubCell[],
): UserCell {
    return {
        id,
        username,
        firstname,
        lastname,
        email,
        company,
        title,
        active,
        not_tracked,
        subCells,
    };
}

function createSubCells(roles: Role[]): UserSubCell[] {
    const subCells: UserSubCell[] = [];
    roles.forEach((role) => {
        const cell: UserSubCell = {
            event: role.event_code,
            roles: [{ role: role.role, room: role.room }],
        };
        if (subCells.findIndex((index) => index.event === role.event_code) !== -1) {
            subCells[subCells.findIndex((index) => index.event === role.event_code)].roles.push({
                role: role.role,
                room: role.room,
            });
        } else {
            subCells.push(cell);
        }
    });
    return subCells;
}

const headCells: HeadCell<UserCell>[] = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
    { id: 'username', numeric: false, disablePadding: false, label: 'Username' },
    { id: 'firstname', numeric: false, disablePadding: false, label: 'First Name' },
    { id: 'lastname', numeric: false, disablePadding: false, label: 'Last Name' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'company', numeric: false, disablePadding: false, label: 'Company' },
    { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
    { id: 'active', numeric: false, disablePadding: false, label: 'Active' },
    { id: 'not_tracked', numeric: false, disablePadding: false, label: 'Not Tracked' },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        loaderWrapper: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transaction: 'translateX(-50%), translateY(-50%)',
        },
        pagination: {
            display: 'inline-flex',
            position: 'relative',
            right: 0,
            justifyContent: 'right',
            alignItems: 'center',
            width: 'calc(100% - 50px)',
        },
    }),
);

export interface Props {
    tableHeadFilter: (filters: string[]) => void;
    totalUsers: number;
    selectedItems: User[];
    setSelectedItems: (users: User[]) => void;
    users: any[];
    actionItems: ReactNode[];
    userRoleFilter: ReactNode;
    eventFilter: ReactNode;
    downloadActionElement: ReactNode;
    uploadActionElement: ReactNode;
    createUserActionElement: ReactNode;
    page: number;
    setPage: (newPage: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    rowsPerPage: number;
    renderSubCellTable: (cell: UserCell) => any;
    isLoading: boolean;
    collapseAll: boolean;
}

const UserManagementTable: FC<Props> = ({
    tableHeadFilter,
    totalUsers,
    selectedItems = [],
    setSelectedItems,
    users,
    downloadActionElement,
    uploadActionElement,
    createUserActionElement,
    actionItems,
    userRoleFilter,
    eventFilter,
    page,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    renderSubCellTable,
    isLoading = false,
    collapseAll,
}) => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof UserCell>('id');
    const [rows, setRows] = useState([] as UserCell[]);

    useEffect(() => {
        const _rows: UserCell[] = [];
        users.forEach((user) => {
            const {
                id,
                username,
                email,
                firstname,
                lastname,
                company,
                job_title,
                active,
                roles,
                not_tracked,
            } = user;
            _rows.push(
                createData(
                    parseInt(id, 10),
                    username,
                    firstname,
                    lastname,
                    email,
                    company,
                    job_title,
                    active,
                    not_tracked,
                    createSubCells(roles),
                ),
            );
        });
        setRows(_rows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    useEffect(() => {}, [rows, users, page]);

    const handleRequestSort = (evt: React.MouseEvent<unknown>, property: keyof UserCell) => {
        tableHeadFilter([property, order]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.checked) {
            setSelectedItems(users);
            return;
        }
        setSelectedItems([]);
    };

    const handleClick = (row: UserCell) => {
        const isUserSelected = isSelected(row);

        if (isUserSelected) {
            setSelectedItems(selectedItems.filter((item) => item.id !== row.id));
        } else {
            setSelectedItems(
                selectedItems.concat(users[users.findIndex((index) => index.id === row.id)]),
            );
        }
    };

    const selectRows = (evt: any) => {
        setRowsPerPage(parseInt(evt.target.value, 10));
        setPage(1);
    };

    const isSelected = (item: UserCell): boolean => {
        let isUserSelected = false;

        for (let i = 0; i < selectedItems.length; i++) {
            if (selectedItems[i].id === item.id) {
                isUserSelected = true;
                break;
            }
        }

        return isUserSelected;
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selectedItems.length}
                    actionItems={actionItems}
                    userRoleFilter={userRoleFilter}
                    eventFilter={eventFilter}
                    actionElementsWithNoSelection={[
                        downloadActionElement,
                        uploadActionElement,
                        createUserActionElement,
                    ]}
                />
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'}>
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selectedItems.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                            checkboxColSpan={2}
                        />
                        <TableBody>
                            {isLoading && (
                                <div className={classes.loaderWrapper}>
                                    <CircularProgress />
                                </div>
                            )}
                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                const isItemSelected = isSelected(row);
                                return (
                                    <Row
                                        key={`${page} ${index}`}
                                        row={row}
                                        isSelected={isItemSelected}
                                        handleClick={(row) => handleClick(row)}
                                        index={index}
                                        renderSubCellTable={renderSubCellTable}
                                        collapseAll={collapseAll}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationComponent
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    selectRows={selectRows}
                    totalCount={totalUsers}
                />
            </Paper>
        </div>
    );
};

export default UserManagementTable;
