import React, { FC } from 'react';
import { UserCell, UserSubCell } from '../../Models/table-cells.model';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import ActionButton from '../../Components/ActionButton/ActionButton';
import UserRolesDialog from '../../Components/Dialogs/UserRolesDialog/UserRolesDialog';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';
import { SelectedUserRoles, UserRoles } from '../../Models/userRole.model';
import { changeRoles, removeAllRoles, removeSingleRole } from '../../Services/http/auth.service';
import { RoleWithRoomID, User } from '../../Models/user.model';
import { UPDATE_USER_LIST } from '../../Contexts/userManagementContext';
import { Room } from '../../Models/socket.model';

const rolePriority = [
    'admin',
    'access-blocked',
    'event-admin',
    'moderator',
    'room-admin',
    'exhibitor',
    'presenter',
    'visitor',
];
interface Props {
    onCompleteCallback: () => void;
    subCell?: UserSubCell;
    cell: UserCell;
    label: string;
}

const UserRoleAssignmentActionContainer: FC<Props> = ({
    onCompleteCallback,
    subCell,
    cell,
    label,
}) => {
    const { state, dispatch } = useUserManagement();
    const [open, setOpen] = React.useState(false);
    const [successMessages, setSuccessMessages] = React.useState([] as string[]);
    const [errorMessages, setErrorMessages] = React.useState([] as string[]);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = React.useState(false);

    const mapRoles = state.availableRoleIds.reduce((roleIdMapObj: any, eachRoleObj: UserRoles) => {
        roleIdMapObj[eachRoleObj.name] = eachRoleObj.id;
        return roleIdMapObj;
    }, {});

    const onToggle = () => {
        setOpen(!open);
    };

    const onCompleteDialogToggle = () => {
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
        onCompleteCallback();
    };

    //return only new roles & roles with modified rooms
    const getChangedRolesOnly = (
        selectedRoles: Map<string, number[]>,
        userRolesWithRoomIDs: RoleWithRoomID[] | undefined,
    ) => {
        const changedRoles = new Map<string, number[]>();
        selectedRoles.forEach((selectedRooms, selectedRole) => {
            const currentRole = userRolesWithRoomIDs?.filter(
                (role: any) => role.role === selectedRole,
            );
            //new role
            if (currentRole?.length === 0 || currentRole === undefined) {
                changedRoles.set(selectedRole, selectedRooms);
            } else {
                //get array of room IDs for current role
                const roomIDs = currentRole
                    .filter((role: any) => role.room !== undefined)
                    .map((room: any) => room.room);

                const isArrayOfRoomsTheSame =
                    JSON.stringify(selectedRooms.sort()) === JSON.stringify(roomIDs.sort());

                if (!isArrayOfRoomsTheSame) {
                    changedRoles.set(selectedRole, selectedRooms);
                }
            }
        });

        return changedRoles;
    };

    //return removed roles
    const getRemovedRoles = (selectedRoles: any[], userRoles: any[], eventCode: string) => {
        //filters roles assigned to that particular event code
        const filteredRoles = userRoles.filter((role) => role.event_code === eventCode);

        //Compares selected roles with the user roles and lists all the roles which are not in the selected roles
        const removedRolesWithRooms = filteredRoles.filter(
            (role1) =>
                role1.role !== 'admin' && !selectedRoles.some((role2) => role1.role === role2.role),
        );

        //Get the unique role list from the above array
        const deletedRoles = removedRolesWithRooms.filter(
            (role1, index, self) => self.findIndex((role2) => role2.role === role1.role) === index,
        );

        return deletedRoles;
    };

    const handleRolesUpdate = async (
        selectedRoles: any[],
        userId: number,
        eventCode: string,
        allUsers: User[],
        successResponses: any[],
        errResponses: any[],
    ) => {
        const currentUser = allUsers.find((_user) => _user.id === userId) as User | undefined;
        const eventRooms = state.rooms.find((_room) => _room.event === eventCode)?.rooms as
            | Room[]
            | undefined;

        // Stores all rooms of each role (key: roleName, value: array of rooms)
        const rolesMapping = new Map<string, number[]>();

        for (let roleIndex = selectedRoles.length - 1; roleIndex >= 0; roleIndex--) {
            const role = selectedRoles[roleIndex];
            if (role.role !== 'admin') {
                if (!eventCode) {
                    errResponses.push(
                        `${role.role} assignment for ${currentUser?.username} failed with Error: event code is not selected`,
                    );
                    selectedRoles.splice(roleIndex, 1);
                    continue;
                } else {
                    if (
                        role.role !== 'event-admin' &&
                        role.role !== 'visitor' &&
                        role.role !== 'access-blocked' &&
                        !role.room
                    ) {
                        errResponses.push(
                            `${role.role} assignment for ${currentUser?.username} failed with Error: room is not selected`,
                        );
                        selectedRoles.splice(roleIndex, 1);
                        continue;
                    }
                }
            }

            // Get the room array from the role key. If the array exists, add the room. If not, create a new array
            const roomArray = rolesMapping.get(role.role);
            if (roomArray) {
                //The value in roomArray was passed-by-reference, so by editing roomArray, the value inside rolesMapping changes as well
                roomArray.push(role.room);
            } else {
                role.room === undefined
                    ? rolesMapping.set(role.role, [])
                    : rolesMapping.set(role.role, [role.room]);
            }
        }

        //selecting user roles in current event and replacing null values with undefined & room names with IDs
        const userRolesWithRoomIDs = currentUser?.roles
            ?.filter((role) => role.event_code === eventCode || role.role === 'admin')
            .map((role) =>
                role.room === null
                    ? { ...role, room: undefined }
                    : { ...role, room: eventRooms?.find((_room) => _room.name === role.room)?.id },
            );

        if (currentUser?.roles) {
            //Compare old roles with new roles and Check if any of the roles have been removed.
            if (selectedRoles.length <= currentUser?.roles?.length) {
                const deletedRoles = getRemovedRoles(selectedRoles, currentUser?.roles, eventCode);
                for (const role of deletedRoles) {
                    const rolestr = role.role;
                    await removeSingleRole(mapRoles[rolestr], userId, eventCode)
                        .then((res: any) => {
                            successResponses.push(
                                `${rolestr} role is deleted from ${currentUser?.username} successfully!`,
                            );
                            return res;
                        })
                        .catch((err: any) => {
                            errResponses.push(
                                `${rolestr} role deletion from ${currentUser?.username} failed with Error: ${err?.response?.data?.message}`,
                            );
                            return err;
                        });
                }
            }
        }

        const changedRolesOnly = getChangedRolesOnly(rolesMapping, userRolesWithRoomIDs);

        // Sending all room array of a role at once to change roles because backend service expects it as this
        if (changedRolesOnly.size != 0) {
            await Promise.all(
                Array.from(changedRolesOnly, async ([rolestr, roomArray]) => {
                    await changeRoles(
                        mapRoles[rolestr],
                        userId,
                        rolestr !== 'admin' ? eventCode : undefined,
                        roomArray.length > 0 ? roomArray : undefined,
                    )
                        .then((res: any) => {
                            successResponses.push(
                                `${rolestr} assignment for ${currentUser?.username} successful!`,
                            );
                            return res;
                        })
                        .catch((err: any) => {
                            errResponses.push(
                                `${rolestr} assignment for ${currentUser?.username} failed with Error: ${err?.response?.data?.message}`,
                            );
                            return err;
                        });
                }),
            );
        }

        for (let i = 0; i < selectedRoles.length; i++) {
            if (selectedRoles[i].role !== 'admin') {
                const roomstring = eventRooms?.find(
                    (_room) => _room.id === selectedRoles[i].room,
                )?.name;
                selectedRoles[i].room = roomstring;
                selectedRoles[i].user_id = userId;
                selectedRoles[i].event_code = eventCode;
            }
        }
        const newUsers = allUsers.map((_user) => {
            if (_user.id === userId) {
                if (_user.roles) {
                    _user.roles = [
                        ..._user.roles.filter((role) => role.event_code !== eventCode),
                        ...selectedRoles,
                    ];
                }
            } else {
                if (_user.roles) {
                    _user.roles = [..._user.roles];
                }
            }
            return {
                ..._user,
            };
        });
        dispatch({ type: UPDATE_USER_LIST, payload: { users: newUsers } });
    };

    const onSave = async (selectedUserRoles: SelectedUserRoles) => {
        const allUsers = state.users;
        setSuccessMessages([]);
        setErrorMessages([]);
        const successResponses: any[] = [];
        const errResponses: any[] = [];
        //TODO: Remove this sort when endpoint accepts array
        selectedUserRoles.roles.sort((a: any, b: any) => {
            return rolePriority.indexOf(b.role) - rolePriority.indexOf(a.role);
        });
        if (selectedUserRoles.roles.length > 0) {
            await handleRolesUpdate(
                selectedUserRoles.roles,
                selectedUserRoles.id,
                selectedUserRoles.event_code,
                allUsers,
                successResponses,
                errResponses,
            );
        } else {
            await removeAllRoles(selectedUserRoles.id, selectedUserRoles.event_code)
                .then(async () => {
                    if (selectedUserRoles.roles.length === 0) {
                        successResponses.push(
                            `Removing roles for ${
                                allUsers.find((_user) => _user.id === selectedUserRoles.id)
                                    ?.username
                            } successful!`,
                        );
                        const newUsers = state.users.map((_user) => {
                            if (_user.id === selectedUserRoles.id) {
                                if (_user.roles) {
                                    _user.roles = [
                                        ..._user.roles.filter(
                                            (role) =>
                                                role.event_code !== selectedUserRoles.event_code,
                                        ),
                                    ];
                                }
                            }
                            return {
                                ..._user,
                            };
                        });
                        dispatch({ type: UPDATE_USER_LIST, payload: { users: newUsers } });
                        onCompleteCallback();
                    }
                })
                .catch((err: any) => {
                    errResponses.push(
                        `Removing roles for ${
                            allUsers.find((_user) => _user.id === selectedUserRoles.id)?.username
                        } failed with Error: ${err?.response?.data?.message}`,
                    );
                });
        }
        setOpen(false);
        setSuccessMessages(successResponses);
        setErrorMessages(errResponses);
        setIsCompleteDialogOpen(true);
    };

    return (
        <>
            <ActionButton onClick={onToggle} text={label} icon={null} />
            <UserRolesDialog
                open={open}
                onClose={onToggle}
                onConfirm={onSave}
                cell={cell}
                subCell={subCell}
            />
            <CompleteDialog
                title="Role change"
                successMessages={successMessages}
                errorMessages={errorMessages}
                open={isCompleteDialogOpen}
                onClose={onCompleteDialogToggle}
            />
        </>
    );
};

export default UserRoleAssignmentActionContainer;
