import React, { createContext, Dispatch, FC, Reducer, useReducer } from 'react';
import { EventCell } from '../Models/table-cells.model';

// Actions
export const UPDATE_EVENT_LIST = 'UPDATE_EVENT_LIST';
export const UPDATE_SELECTED_EVENTS = 'UPDATE_SELECTED_EVENTS';
export const EDIT_ADMINISTRATION = 'EDIT_ADMINISTRATION';
export const EVENT_CODE = 'EVENT_CODE';

// Action interface
interface UpdateEventListAction {
    type: typeof UPDATE_EVENT_LIST;
    payload: {
        events: EventCell[];
    };
}
interface UpdateSelectedEventsAction {
    type: typeof UPDATE_SELECTED_EVENTS;
    payload: {
        selectedEvents: EventCell[];
    };
}
interface EditAdministration {
    type: typeof EDIT_ADMINISTRATION;
    payload: boolean;
}
interface EventCode {
    type: typeof EVENT_CODE;
    payload: string;
}

// Actions type
type EventManagementActions =
    | UpdateEventListAction
    | UpdateSelectedEventsAction
    | EditAdministration
    | EventCode;

// State
type State = {
    events: EventCell[];
    selectedEvents: EventCell[];
    isAdministrationOpen: boolean;
    eventCode: string;
};

const initialState: State = {
    events: [],
    selectedEvents: [],
    isAdministrationOpen: false,
    eventCode: '',
};

export const EventManagementContext = createContext<{
    state: State;
    dispatch: Dispatch<EventManagementActions>;
}>({
    state: initialState,
    dispatch: () => {},
});

const eventManagementReducer: Reducer<State, EventManagementActions> = (
    state: State,
    action: EventManagementActions,
) => {
    switch (action.type) {
        case UPDATE_EVENT_LIST: {
            return {
                ...state,
                events: [...action.payload.events],
            };
        }
        case UPDATE_SELECTED_EVENTS: {
            return { ...state, selectedEvents: action.payload.selectedEvents };
        }
        case EDIT_ADMINISTRATION: {
            return {
                ...state,
                isAdministrationOpen: action.payload,
            };
        }
        case EVENT_CODE: {
            return {
                ...state,
                eventCode: action.payload,
            };
        }
        default:
            return state;
    }
};

export const EventManagementContextProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(eventManagementReducer, initialState);

    const value = { state, dispatch };

    return (
        <EventManagementContext.Provider value={value}>{children}</EventManagementContext.Provider>
    );
};
