import React, { FC, useState } from 'react';
import { deactivateReportedUser } from '../../Services/http/user.service';
import ActionButton from '../../Components/ActionButton/ActionButton';
import userReportedUserManagement from '../../Hooks/ReportedUserManagement/userReportedUserManagement';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';
import DeactivateUserDialog from '../../Components/Dialogs/DeactivateUserDialog/DeactivateUserDialog';

interface Props {
    onCompleteCallback: () => void;
}

const DeactivateUserActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const [open, setOpen] = React.useState(false);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
    const { state, dispatch } = userReportedUserManagement();
    const [successMessages, setSuccessMessages] = useState([] as string[]);
    const [errorMessages, setErrorMessages] = useState([] as string[]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const onConfirm = async (action: string, message: string) => {
        const { selectedReportedUsers, reportedUsers } = state;
        const successResponses: any[] = [];
        const errResponses: any[] = [];
        await Promise.all(
            selectedReportedUsers.map(async (user) => {
                const selectedUser = reportedUsers.find(
                    (_user) => _user.reported_user_id === user.reported_user_id,
                );
                if (selectedUser?.reported_user_id) {
                    return await deactivateReportedUser(
                        selectedUser.reported_user_id,
                        action,
                        message !== '' ? message : undefined,
                    )
                        .then((msg: any) => {
                            successResponses.push(
                                `${selectedUser.reported_user_username} - User deactivated successfully`,
                            );
                        })
                        .catch((err: any) => {
                            errResponses.push(
                                `${selectedUser.reported_user_username} - ${err?.response?.data?.message}`,
                            );
                        });
                }
            }),
        );

        setOpen(false);
        setSuccessMessages(successResponses);
        setErrorMessages(errResponses);
        toggleCompleteDialog();
    };

    const toggleCompleteDialog = () => {
        if (onCompleteCallback) {
            onCompleteCallback();
        }
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
    };

    return (
        <>
            <ActionButton onClick={toggleOpen} text={'Take Action'} icon={null} />
            <DeactivateUserDialog
                open={open}
                onClose={toggleOpen}
                onConfirm={onConfirm}
                selectedUsers={state.selectedReportedUsers}
            />
            <CompleteDialog
                title="Take Action"
                successMessages={successMessages}
                errorMessages={errorMessages}
                open={isCompleteDialogOpen}
                onClose={toggleCompleteDialog}
            />
        </>
    );
};

export default DeactivateUserActionContainer;
