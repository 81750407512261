import React, { FC, ReactElement, useEffect, useState, cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Preference } from '../../Models/event.model';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    title: {
        display: 'flex',
        width: '35%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    checkbox: {
        display: 'flex',
        width: '16%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    inputField: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        width: '50%',
        '& div[class*="MuiFormControl-root"]': {
            width: '80%',
        },
    },
    infoBar: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '50%',
    },
    default: {
        color: 'gray',
    },
    itemCheckBox: {
        display: 'none',
        width: '10%',
    },
    valueChange: {
        color: 'red',
    },
    itemLabel: {
        display: 'none',
        width: '50%',
    },
}));

interface Props {
    preference: Preference;
    onChange: (newValue: string) => any;
    children: ReactElement;
}

const PreferenceValueChecked: FC<Props> = ({ preference, onChange, children }) => {
    const classes = useStyles();
    const [isChanged, setIsChanged] = useState(false);
    const preferenceTitle = preference.title ? preference.title : preference.name;

    useEffect(() => {
        checkIfValueChanged(preference.value, preference.initialValue!);
    }, [preference]);

    const checkIfValueChanged = (newValue: string, initialValue: string) => {
        if (newValue !== initialValue) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    };

    const original = onChange;
    // ...we extend the onChange function that is passed down as a prop so as to check whether the value has been changed
    onChange = (...args) => {
        const newValue = original.apply(this, args);
        checkIfValueChanged(newValue, preference.initialValue!);
    };

    return (
        <div className={classes.root}>
            <>
                <div className={classes.checkbox}>
                    {cloneElement(children, {
                        onChange,
                    })}
                </div>
                <div className={classes.title}>{preferenceTitle}</div>
            </>

            {/* ...whatever you pass into PreferenceRow as a child needs to expose an onChange prop */}

            <div className={classes.infoBar}>
                <Typography className={isChanged ? classes.valueChange : classes.default}>
                    {isChanged ? 'Changed' : 'No change'}
                </Typography>
            </div>
        </div>
    );
};

export default PreferenceValueChecked;
