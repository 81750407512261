import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import PreferenceRowBooleanPicker from '../../Components/PreferenceRow/PreferenceRowBooleanPicker';
import { Preference } from '../../Models/event.model';
import UploadFile from '../../Components/UploadFile/UploadFile';
import { FileTypes } from '../../Enums/FileTypes.enum';
import { InputFileType } from '../../Enums/InputFileType.enums';
import { List } from '@material-ui/core';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import {
    getPreferencesByCategory,
    patchPreferences,
    postPreference,
} from '../../Services/http/event.service';
import { postFile } from '../../Services/http/content.service';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() =>
    createStyles({
        bannersList: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px',
        },
        accordion: {
            display: 'flex',
            flexDirection: 'column',
        },
        tableHead: {
            background: 'gray',
        },
        tableHeadCell: {
            color: 'white',
        },
        tableRow: {
            display: 'flex',
            alignItems: 'center',
        },
        table: {
            justifySelf: 'center',
            marginLeft: 20,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        buttonWrapper: {
            marginBottom: '1%',
            marginRight: '2%',
        },
        buttons: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        button: {
            width: '50%',
            margin: '2%',
            flex: 1,
        },
        text: {
            flex: 2,
            marginLeft: -15,
        },
    }),
);

const LoginIntroductionScreenAndTransitionVideoAccordionContainer = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useEventManagement();
    const [disabled, setDisabled] = useState(true);
    const [activatePreferences, setActivatePreferences] = useState<Preference[]>([]);
    const [introFile, setIntroFile] = useState<File | null>(null);
    const [introFileSelected, setIntroFileSelected] = useState<boolean>(false);
    const [transitionFile, setTransitionFile] = useState<File | null>(null);
    const [transitionFileSelected, setTransitionFileSelected] = useState<boolean>(false);

    const introScreenBanner = {
        title: 'Introduction screen content (PNG file, MP4 video file, Markdown text file)',
        type: FileTypes.LOGIN_INFORMATION_CONTENT,
    };

    const loginTransitionBanner = {
        title: 'Login Transition Video (MP4 video file)',
        type: FileTypes.TRANSITION_CONTENT,
    };

    const onCancel = async () => {
        setDisabled(true);
        setTransitionFile(null);
        setIntroFile(null);
        setIntroFileSelected(false);
        setTransitionFileSelected(false);
        await getLoginScreenPreferences();
    };

    const onConfirm = async () => {
        if (introFile) uploadFile(introFile, introScreenBanner.type, state.eventCode);
        if (transitionFile) uploadFile(transitionFile, loginTransitionBanner.type, state.eventCode);
        submitPreferences();
    };

    const onBoolChange = (newValue: any, isLogin: boolean) => {
        const newArr = [...activatePreferences];
        let name = '';
        isLogin ? (name = 'login_information_content') : (name = 'transition_content');
        let updated = false;

        newArr.forEach((pref) => {
            if (pref.name === name) {
                pref.changed = true;
                pref.value === newValue ? setDisabled(true) : setDisabled(false);
                pref.value = newValue;
                updated = true;
            }
        });

        isLogin
            ? setIntroFileSelected(newValue === 'no' ? false : true)
            : setTransitionFileSelected(newValue === 'no' ? false : true);

        const x: Preference = {
            changed: true,
            name: name,
            category: 'general',
            value: newValue,
            initialValue: 'no',
            from_date: new Date(),
            to_date: new Date(),
            new: true,
        };

        if (!updated) {
            newArr.push(x);
            setDisabled(false);
        }

        setActivatePreferences(newArr);
    };

    const handleSelectFile = (file: File, type: string) => {
        switch (type) {
            case 'introduction_screen_content': {
                setIntroFile(file);
                setDisabled(false);
                break;
            }
            case 'login_transition_video': {
                setTransitionFile(file);
                setDisabled(false);
                break;
            }
            default: {
                break;
            }
        }
    };

    const uploadFile = (file: File, fileType: FileTypes, event_code: string) => {
        state.eventCode &&
            postFile(file, fileType, event_code)
                .then(() => {
                    enqueueSnackbar('The new files have been uploaded successfully.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                });
    };

    const submitPreferences = async () => {
        const results = await Promise.all(
            activatePreferences.map(async (preference: Preference) => {
                if (preference.changed) delete preference.changed;
                if (preference.initialValue) delete preference.initialValue;
                if (preference.from_date === null) preference.from_date = new Date();
                if (preference.to_date === null) preference.to_date = new Date();
                if (preference.subcategory === null) delete preference.subcategory;
                const pref: any = preference;
                delete pref.id;
                if (pref.new) {
                    delete pref.new;
                    pref.type = 'string';
                    return await postPreference(pref)
                        .then((success) => true)
                        .catch((error) => false);
                } else if (pref.id) {
                    delete pref.name;
                    return await patchPreferences(pref.id, pref)
                        .then((success) => true)
                        .catch((error) => false);
                }
            }),
        );
        const hasError = results.includes(false);
        enqueueSnackbar(!hasError ? 'Preferences were updated' : 'There was an error', {
            variant: !hasError ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
        onCancel();
    };

    const renderBooleanPicker = (name: string) => {
        const isLogin = name === 'login_information_content';
        const newArr = [...activatePreferences].filter((pref) => pref.name === name);
        if (newArr.length < 1) {
            const x: Preference = {
                changed: false,
                name: name,
                category: 'general',
                value: 'no',
                initialValue: 'no',
                from_date: new Date(),
                to_date: new Date(),
            };
            newArr.push(x);
        }

        return (
            <PreferenceRowBooleanPicker
                preference={newArr[0]}
                onChange={(newValue) => onBoolChange(newValue, isLogin)}
            />
        );
    };

    const renderUploadFile = (banner: any, type: string) => {
        let selectedFile = null;
        let setSelectedFile: any;
        let fileType = '';
        let disabled = false;

        switch (type) {
            case 'introduction_screen_content': {
                selectedFile = introFile;
                setSelectedFile = setIntroFile;
                fileType = `${InputFileType.PNG},${InputFileType.MP4},${InputFileType.MARKDOWN_TEXT_FILE}`;
                disabled = introFileSelected;
                break;
            }
            case 'login_transition_video': {
                selectedFile = transitionFile;
                setSelectedFile = setTransitionFile;
                fileType = InputFileType.MP4;
                disabled = transitionFileSelected;
                break;
            }
            default:
                break;
        }
        return (
            <UploadFile
                banner={banner}
                onSelect={(file) => handleSelectFile(file, type)}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                inputFileType={fileType}
                disabled={!disabled}
            />
        );
    };

    const getLoginScreenPreferences = useCallback(async () => {
        const body = {
            event_code: state.eventCode,
            type: 'specific',
            category: 'general',
        };
        await getPreferencesByCategory(body).then((response) => {
            const loginPreferencesNames = ['login_information_content', 'transition_content'];
            const loginPreferences = response.data.filter((preference: Preference) => {
                return loginPreferencesNames.includes(preference.name);
            });
            // ...set initial values
            for (const preference of loginPreferences) {
                preference.initialValue = JSON.parse(JSON.stringify(preference.value));
                preference.name === 'login_information_content'
                    ? setIntroFileSelected(preference.value === 'no' ? false : true)
                    : setTransitionFileSelected(preference.value === 'no' ? false : true);
            }

            setActivatePreferences(loginPreferences);
            setDisabled(true);
        });
    }, [state.eventCode]);

    useEffect(() => {
        getLoginScreenPreferences();
    }, [getLoginScreenPreferences]);

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>Login Introduction Screen And Transition Video</strong>
                </Typography>
            </AccordionSummary>
            <List className={classes.bannersList}>
                {renderBooleanPicker('login_information_content')}
                {renderUploadFile(introScreenBanner, 'introduction_screen_content')}
                {renderBooleanPicker('transition_content')}
                {renderUploadFile(loginTransitionBanner, 'login_transition_video')}
            </List>
            <CancelAndConfirmButton
                onCancel={() => onCancel()}
                onSubmit={() => onConfirm()}
                disabled={disabled}
            />
        </Accordion>
    );
};

export default LoginIntroductionScreenAndTransitionVideoAccordionContainer;
