import React, { FC, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import { UPDATE_ROLE_ID_LIST } from '../../Contexts/userManagementContext';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import { UserRole } from '../../Models/user.model';
import { getUserRoleList } from '../../Services/http/auth.service';
import useAuth from '../../Hooks/Auth/useAuth';

interface Props {
    onCompleteCallback: (value: string) => void;
}

const UserRoleFilterActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { state: userState, dispatch } = useUserManagement();
    const { state: authState } = useAuth();

    const [roles, setRoles] = React.useState<UserRole[]>([
        {
            id: 0,
            name: 'All roles',
            description: 'all roles',
        },
        {
            id: 0,
            name: 'Empty role',
            description: 'Empty role',
        },
    ]);
    const [selectedRole, setSelectedRole] = React.useState<UserRole>(roles[0]);
    const [fileredRoles, setFileredRoles] = React.useState<UserRole[]>([]);
    const [hasEventAdminRole] = React.useState(
        authState.currentUser.roles.some((role: any) => role.role === 'event-admin') &&
            !authState.currentUser.roles.some((role: any) => role.role === 'admin'),
    );

    useEffect(() => {
        if (roles?.length < 2 && userState?.availableRoleIds?.length) {
            setRoles(roles.concat(userState.availableRoleIds));
        } else {
            getUserRoleList().then((res: any) => {
                setRoles(
                    roles.concat(
                        res?.data?.roles.sort((a: any, b: any) => {
                            if (a.name) {
                                return a.name.localeCompare(b.name);
                            } else return -1;
                        }),
                    ),
                );
                dispatch({
                    type: UPDATE_ROLE_ID_LIST,
                    payload: { availableRoleIds: res.data.roles },
                });
            });
        }

        if (selectedRole) {
            emitCallback(selectedRole);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (roles?.length > 2) {
            const roleList = getFilteredRoles(roles);
            if (roleList) {
                hasEventAdminRole ? setFileredRoles(roleList) : setFileredRoles(roles);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles]);

    useEffect(() => {}, [selectedRole]);

    const changeActiveRole = (value: any) => {
        if (!value) {
            value = roles[0];
        }
        setSelectedRole(value);
        emitCallback(value);
    };

    const emitCallback = (value: UserRole) => {
        if (value && value?.name === 'All roles') {
            onCompleteCallback(roles[0].name);
        } else if (value?.name === 'Empty role') {
            onCompleteCallback('empty-role');
        } else {
            onCompleteCallback(value.name);
        }
    };

    const getFilteredRoles = (roles: any[]) => {
        if (roles) {
            const filteredRoleList = roles?.filter(
                (role) => !(role.name === 'admin' || role.name === 'Empty role'),
            );
            return filteredRoleList;
        }
        return;
    };

    return (
        <Autocomplete
            id="role-based-filter"
            onChange={(role, value) => changeActiveRole(value)}
            options={fileredRoles}
            getOptionLabel={(option) => option.name}
            value={selectedRole}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
    );
};

export default UserRoleFilterActionContainer;
