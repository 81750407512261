import io, { Socket } from 'socket.io-client';
import { User } from '../../Models/user.model';
import Config from '../../config.json';

const apiUrl = Config.VSF_K8S_API_URL;

export const connectMessagingSocket = (user: User, room_id?: number, event_code?: String) => {
    return io(`${apiUrl}`, {
        path: '/messaging',
        query: {
            frontend: 'admin',
            roomId: room_id,
            event_code,
        },
        auth: {
            jwt_token: user.jwt_token || '',
        },
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionAttempts: Infinity,
        reconnectionDelayMax: 3000,
        withCredentials: true,
    });
};

export const emitMessagingSocket = (
    data: string,
    socketConnection: Socket,
    socketTopic: string,
) => {
    socketConnection.emit(socketTopic, data, (statusCode: any) => {
        console.log(statusCode);
    });
};
