import React, { FC, useState } from 'react';
import { logoutUser } from '../../Services/http/auth.service';
import ActionButton from '../../Components/ActionButton/ActionButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';
import UserActionDialog from '../../Components/Dialogs/UserActionDialog/UserActionDialog';

const LogoutContainer: FC = () => {
    const [open, setOpen] = React.useState(false);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
    const { state } = useUserManagement();
    const [successMessages, setSuccessMessages] = useState([] as string[]);
    const [errorMessages, setErrorMessages] = useState([] as string[]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const onConfirm = async () => {
        const { selectedUsers, users } = state;
        const successResponses: any[] = [];
        const errResponses: any[] = [];
        await Promise.all(
            selectedUsers.map(async (user) => {
                const selectedUser = users.find((_user) => _user.id === user.id);
                if (selectedUser?.id) {
                    await logoutUser(selectedUser.id)
                        .then((msg: any) => {
                            successResponses.push(
                                `${selectedUser.username} - Session deleted successfully`,
                            );
                        })
                        .catch((err: any) =>
                            errResponses.push(
                                `${selectedUser.username} - ${err?.response?.data?.error}`,
                            ),
                        );
                }
            }),
        );

        setOpen(false);
        setSuccessMessages(successResponses);
        setErrorMessages(errResponses);
        toggleCompleteDialog();
    };

    const toggleCompleteDialog = () => {
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
    };

    return (
        <>
            <ActionButton onClick={toggleOpen} text={'Logout Users'} icon={<ExitToAppIcon />} />
            <UserActionDialog
                open={open}
                onClose={toggleOpen}
                onConfirm={onConfirm}
                selectedUsers={state.selectedUsers}
                dialogTitle={'Logout Users'}
                userActionDescription={'should be logged out'}
            />
            <CompleteDialog
                title="Logout Users"
                successMessages={successMessages}
                errorMessages={errorMessages}
                open={isCompleteDialogOpen}
                onClose={toggleCompleteDialog}
            />
        </>
    );
};

export default LogoutContainer;
