import axios from 'axios';
import { Event } from '../../Models/event.model';
import Config from '../../config.json';

const apiUrl = Config.VSF_K8S_API_URL;
const apiVersion = Config.VSF_K8S_API_VERSION;

const servicePrefix = `${apiUrl}/event${apiVersion}`;

export const fetchAllEvents = () => {
    return axios.get(`${servicePrefix}/events`);
};

export const fetchEvents = (
    page: number,
    rowsPerPage: number,
    order_by?: string,
    order_direction?: string,
    search?: string,
) => {
    const params = {
        page: page,
        limit: rowsPerPage,
    };
    if (order_by) Object.assign(params, { order_by: order_by });
    if (order_direction) Object.assign(params, { order_direction: order_direction });
    if (search) Object.assign(params, { search: search });
    return axios.get(`${servicePrefix}/events`, { params });
};

export const createEvent = (event: Event) => {
    const payload = {
        name: event.name,
        code: event.event_code, // ...this naming mismatch will be resolved by BE. Update once this happens
        mode: event.mode,
        description: event.description,
    };
    return axios.post(`${servicePrefix}/event`, payload);
};

export const editEvent = (event: Event) => {
    const payload = {
        name: event.name,
        mode: event.mode,
        description: event.description,
    };
    return axios.patch(`${servicePrefix}/event/${event.event_code}`, payload);
};

export const postFaq = (file: File, event_code: string, frontend: string) => {
    const formData = new FormData();
    formData.append('content', file);
    return axios.post(`${servicePrefix}/faqs`, formData, {
        params: {
            event_code,
            frontend,
        },
    });
};

export const getOpeningHours = (code: string) => {
    return axios.get(`${servicePrefix}/event/${code}/openhours`);
};

export const postOpeningHours = (code: string, body: any) => {
    return axios.post(`${servicePrefix}/event/${code}/openhours`, body);
};

export const postPreference = (body: any) => {
    return axios.post(`${servicePrefix}/preference`, body);
};

export const getPreferencesByCategory = (body: any) => {
    return axios.get(`${servicePrefix}/preference`, { params: body });
};

export const patchPreferences = (id: number, body: any) => {
    return axios.patch(`${servicePrefix}/preference/${id}`, body);
};

export const resetEventBadges = (eventCode: string) => {
    return axios.delete(`${servicePrefix}/passport/event/${eventCode}`);
};

export const postRefreshUsers = (body: any) => {
    return axios.post(`${servicePrefix}/refresh-users`, body);
};
