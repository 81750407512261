import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC } from 'react';

interface Props {
    isDialogOpen: boolean;
    title?: string;
    content?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm: () => void;
    onClose: () => void;
}

const AlertDialog: FC<Props> = ({
    isDialogOpen,
    title,
    content,
    confirmButtonText = 'confirm',
    cancelButtonText = 'cancel',
    onConfirm,
    onClose,
}) => {
    return (
        <Dialog
            open={isDialogOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {cancelButtonText}
                </Button>
                <Button onClick={onConfirm} color="primary">
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
