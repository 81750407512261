import React, { FC, useEffect, useState } from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';
import { Preference } from '../../Models/event.model';
import PreferenceValueChecked from './PreferenceValueChecked';

const useStyles = makeStyles(() => ({
    checkbox: {
        display: 'flex',
        flexDirection: 'column',
        width: 'inherit',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
}));

interface Props {
    preference: Preference;
    onChange: (newValue: string) => void;
}

const PreferenceRowCheckBox: FC<Props> = ({ preference, onChange }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState<boolean>(preference.value == 'yes');

    useEffect(() => {
        setChecked(preference.value == 'yes');
    }, [preference]);

    const onToggle = (event: any) => {
        setChecked(event.target.checked);
        const newValue = event.target.checked ? 'yes' : 'no';
        return newValue;
    };

    const original = onChange;
    // ...we extend the onChange function that is passed down as a prop with the behaviour specific to boolean
    onChange = (...args) => {
        const newValue = onToggle.apply(this, args);
        original.apply(this, [newValue]);
        return newValue;
    };

    return (
        <PreferenceValueChecked preference={preference} onChange={onChange}>
            {/* ...the onChange props is shallow merged into the children by PreferenceRow */}
            <Checkbox
                className={classes.checkbox}
                checked={checked}
                onChange={() => {}}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </PreferenceValueChecked>
    );
};

export default PreferenceRowCheckBox;
