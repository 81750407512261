import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import EnhancedTableHead, { HeadCell } from '../EnhancedTableHead/EnhancedTableHead';
import { BaseRoom, Room } from '../../Models/socket.model';
import EnhancedTableToolbar from '../EnhancedTableToolbar/EnhancedTableToolbar';
import ActionButton from '../ActionButton/ActionButton';
import { ManageRoomDialog } from './ManageRoomDialog';
import { createRoom, updateRoom } from '../../Services/http/content.service';
import { useSnackbar } from 'notistack';
// import PaginationComponent from '../Pagination/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteRoomContainer from './DeleteRoomContainer';
import { Order, getComparator, stableSort } from '../../Utils/tableHelpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        loaderWrapper: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transaction: 'translateX(-50%), translateY(-50%)',
        },
        pagination: {
            display: 'inline-flex',
            position: 'relative',
            right: 0,
            justifyContent: 'right',
            alignItems: 'center',
            width: 'calc(100% - 50px)',
        },
    }),
);

export interface RoomCell {
    id: number;
    name: string;
    link: string;
    type: string;
    multiplayer: boolean;
    restricted: boolean;
    distribution: boolean;
    selected: boolean;
}

interface Props {
    rooms: Room[] | [];
    openChat: (room: Room) => void;
    isLoading: boolean;
    refetchRooms: () => void;
    eventCode: string;
    // page: number;
    // setPage: (newPage: number) => void;
    // setRowsPerPage: (rowsPerPage: number) => void;
    // rowsPerPage: number;
}

const getRoomCells: (rooms: Room[]) => RoomCell[] = (rooms) => {
    return rooms.map((room) => {
        return {
            selected: false,
            link: room.link ? room.link : '',
            multiplayer: room.link ? !!room.link.match('meeting') : false,
            ...room,
        };
    });
};

const headCells: HeadCell<RoomCell>[] = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'link', numeric: false, disablePadding: false, label: 'Link' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'multiplayer', numeric: false, disablePadding: false, label: 'Multiplayer' },
    { id: 'restricted', numeric: false, disablePadding: false, label: 'Restricted' },
    { id: 'distribution', numeric: false, disablePadding: false, label: 'Distribution' },
];

const getStringFromBoolean = (value: boolean) => {
    return value ? 'yes' : 'no';
};

const RoomRow: FC<{
    roomItem: RoomCell;
    index: number;
    isItemSelected: boolean;
    handleClick: (room: RoomCell) => void;
}> = ({ roomItem, index, isItemSelected, handleClick }) => {
    return (
        <TableRow hover key={index} selected={isItemSelected}>
            <TableCell padding="checkbox">
                <Checkbox
                    onClick={() => handleClick(roomItem)}
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': `enhanced-table-checkbox-${index}`,
                    }}
                />
            </TableCell>
            <TableCell />
            <TableCell>{roomItem.id}</TableCell>
            <TableCell>{roomItem.name}</TableCell>
            <TableCell>{roomItem.link}</TableCell>
            <TableCell>{roomItem.type}</TableCell>
            <TableCell>{getStringFromBoolean(roomItem.multiplayer)}</TableCell>
            <TableCell>{getStringFromBoolean(roomItem.restricted)}</TableCell>
            <TableCell>{getStringFromBoolean(roomItem.distribution)}</TableCell>
        </TableRow>
    );
};

const ManageRoomButton: FC<{ onClick: () => void; title: string }> = ({ onClick, title }) => {
    return (
        <>
            <ActionButton text={title} onClick={onClick} />
        </>
    );
};

const ChatRoomElement: FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <>
            <ActionButton text={'Open Chat'} onClick={onClick} />
        </>
    );
};

export const RoomsTable: FC<Props> = ({
    rooms,
    openChat,
    isLoading,
    refetchRooms,
    eventCode,
    // page,
    // setPage,
    // setRowsPerPage,
    // rowsPerPage,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [roomItems, setRoomItems] = useState<RoomCell[]>();
    const [selectedItem, setSelectedItem] = useState<Room>();
    const [showManageRoomDialog, setShowManageRoomDialog] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof RoomCell>('id');

    const handleRequestSort = (evt: React.MouseEvent<unknown>, property: keyof RoomCell) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        setRoomItems(getRoomCells(rooms));
        setSelectedItem(undefined);
    }, [rooms]);

    const isSelected = (item: RoomCell): boolean => {
        return item.id === selectedItem?.id;
    };

    const handleClick = (row: RoomCell) => {
        const isRowSelected = isSelected(row);

        if (isRowSelected) {
            setSelectedItem(undefined);
        } else {
            roomItems &&
                setSelectedItem(roomItems[roomItems.findIndex((index) => index.id === row.id)]);
        }
    };

    // const selectRows = (evt: any) => {
    //     setRowsPerPage(parseInt(evt.target.value, 10));
    //     setPage(1);
    // };

    const onSubmit: (room: BaseRoom) => void = async (room) => {
        const updateCreate = room.id ? updateRoom : createRoom;
        await updateCreate(room, eventCode)
            .then((response) => {
                setShowManageRoomDialog(false);
                enqueueSnackbar('Create/Edit room was successful', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
                refetchRooms();
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    setErrorMessage(e.response.data.message);
                }
            });
    };

    const handleDeleteComplete = () => {
        setSelectedItem(undefined);
        refetchRooms();
        // setPage(1);
    };

    const handleSelectAllClick = () => {
        setSelectedItem(undefined);
    };

    const handleCloseManageRoomDialog = () => {
        setShowManageRoomDialog(false);
        setErrorMessage('');
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selectedItem ? 1 : 0}
                    actionItems={[
                        <DeleteRoomContainer
                            key={0}
                            onCompleteCallback={() => handleDeleteComplete()}
                            room={selectedItem!}
                        />,
                        <ManageRoomButton
                            key={1}
                            onClick={() => setShowManageRoomDialog(true)}
                            title="Edit Room"
                        />,
                        <ChatRoomElement key={2} onClick={() => openChat(selectedItem!)} />,
                    ]}
                    actionElementsWithNoSelection={[
                        <ManageRoomButton
                            key={0}
                            onClick={() => setShowManageRoomDialog(true)}
                            title="Create Room"
                        />,
                    ]}
                />
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'}>
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selectedItem ? 1 : 0}
                            headCells={headCells}
                            checkboxColSpan={2}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={roomItems?.length || 0}
                        />
                        <TableBody>
                            {isLoading && (
                                <div className={classes.loaderWrapper}>
                                    <CircularProgress />
                                </div>
                            )}
                            {roomItems &&
                                stableSort(roomItems, getComparator(order, orderBy)).map(
                                    (row, index) => {
                                        const isItemSelected = isSelected(row);
                                        return (
                                            <RoomRow
                                                key={`${index}`}
                                                // key={`${page} ${index}`}
                                                roomItem={row}
                                                index={index}
                                                isItemSelected={isItemSelected}
                                                handleClick={(room) => handleClick(room)}
                                            />
                                        );
                                    },
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <PaginationComponent
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    selectRows={selectRows}
                    totalCount={rooms?.length}
                /> */}
                {showManageRoomDialog && (
                    <ManageRoomDialog
                        room={selectedItem}
                        handleClose={() => handleCloseManageRoomDialog()}
                        handleSubmit={onSubmit}
                        errorMessage={errorMessage}
                    />
                )}
            </Paper>
        </div>
    );
};
