import React, { FC, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
    id?: string;
    text: string;
    icon?: ReactNode;
    disabled?: boolean;
    onClick: () => void;
}
const styles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
    }),
);

const ActionButton: FC<Props> = ({ id, icon, text, disabled, onClick }) => {
    const classes = styles();
    return (
        <Button
            id={id}
            key={text}
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={icon}
            disabled={disabled}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};

export default ActionButton;
