import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputFileType } from '../../Enums/InputFileType.enums';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    banner: {
        width: '50%',
    },
    inputField: {
        width: '30%',
    },
    baseInputField: {
        height: '42px',
        paddingRight: '20px',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    selectedFile: {
        width: '20%',
        textAlign: 'center',
    },
    button: {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        backgroundColor: 'lightgray',
        width: 100,
        height: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        borderRadius: 6,
        textAlign: 'center',
        cursor: 'pointer',
    },
    defaultButton: {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        backgroundColor: 'lightgray',
        width: 100,
        fontSize: 16,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        borderRadius: 6,
        textAlign: 'center',
        cursor: 'pointer',
    },
    input: {
        visibility: 'hidden',
    },
    disabledLabel: {
        cursor: 'default',
        color: 'gray',
    },
}));

interface Props {
    banner: any;
    onSelect: (file: File) => void;
    selectedFile: File | null;
    setSelectedFile: (file: File) => void;
    inputFileType: InputFileType | string;
    disabled?: boolean;
    includeDefault?: boolean;
    currentFile?: string | null;
    currentFilename?: string | null;
    revertToDefault?: boolean;
    setRevertToDefault?: (revert: boolean) => void;
    onImageClick?: () => void;
}
const selectText = 'Select file ...';
const defaultText = 'Default';

const UploadFile: FC<Props> = ({
    banner,
    onSelect,
    selectedFile,
    setSelectedFile,
    inputFileType,
    disabled,
    includeDefault,
    currentFile,
    currentFilename,
    revertToDefault,
    setRevertToDefault,
    onImageClick,
}) => {
    const classes = useStyles();

    // On file selection
    const onFileChange = (event: any) => {
        setSelectedFile(event?.target?.files[0]);
        onSelect(event?.target?.files[0]);
    };

    return (
        <div className={classes.container}>
            <div className={classes.banner}>{banner.title}</div>
            {includeDefault && (
                <span className={classes.baseInputField}>
                    <h1>{revertToDefault}</h1>
                    <button
                        id="defaultButton"
                        className={`${classes.defaultButton} ${disabled && classes.disabledLabel}`}
                        onClick={() => {
                            if (setRevertToDefault) setRevertToDefault(true);
                        }}
                        disabled={disabled}
                    >
                        {defaultText}
                    </button>
                </span>
            )}
            <span className={`${classes.baseInputField} ${classes.inputField}`}>
                <label
                    htmlFor={banner.name}
                    className={`${classes.button} ${disabled && classes.disabledLabel}`}
                >
                    {selectText}
                    <input
                        id={banner.name}
                        className={classes.input}
                        type="file"
                        accept={inputFileType}
                        onChange={onFileChange}
                        disabled={disabled}
                    />
                </label>
            </span>
            <div
                className={classes.selectedFile}
                style={{ color: selectedFile || revertToDefault ? 'red' : 'grey' }}
            >
                {selectedFile ? (
                    <span>File &quot;{selectedFile.name}&quot; selected</span>
                ) : includeDefault ? (
                    revertToDefault ? (
                        'Switch to default'
                    ) : currentFile ? (
                        <span>
                            File &quot;
                            <a
                                href={currentFile}
                                target="_blank"
                                rel="noreferrer"
                                onClick={onImageClick}
                            >
                                {currentFilename}
                            </a>
                            &quot; selected
                        </span>
                    ) : (
                        'Default selected'
                    )
                ) : (
                    'No file selected'
                )}
            </div>
        </div>
    );
};

export default UploadFile;
