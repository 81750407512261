import React, { FC, useState, useContext } from 'react';
import ActionConfirmDialog from '../../Components/Dialogs/ActionConfirmDialog/ActionConfirmDialog';
import { resetPassword } from '../../Services/http/auth.service';
import ActionButton from '../../Components/ActionButton/ActionButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';
import { AuthContext } from '../../Contexts/authContext';

const ResetPasswordContainer: FC = () => {
    const [open, setOpen] = React.useState(false);
    const { state: userState } = useUserManagement();
    const { state: authState } = useContext(AuthContext);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
    const [successMessages, setSuccessMessages] = useState([] as string[]);
    const [errorMessages, setErrorMessages] = useState([] as string[]);

    const onConfirm = async () => {
        const { selectedUsers, users } = userState;
        const successResponses: any[] = [];
        const errResponses: any[] = [];
        await Promise.all(
            selectedUsers.map(async (user) => {
                const selectedUser = users.find((_user) => user.id === _user.id);
                if (selectedUser?.email) {
                    await resetPassword(selectedUser.email, authState.currentUser.event_code)
                        .then((msg: any) => {
                            successResponses.push(`${selectedUser.username} ${msg?.data?.message}`);
                        })
                        .catch((err: any) =>
                            errResponses.push(`${selectedUser.username} ${err?.data?.message}`),
                        );
                } else {
                    errResponses.push(`${selectedUser?.username} has no valid E-Mail address`);
                }
            }),
        );

        setOpen(false);
        setSuccessMessages(successResponses);
        setErrorMessages(errResponses);
        toggleCompleteDialog();
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    const toggleCompleteDialog = () => {
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
    };

    return (
        <>
            <ActionButton onClick={toggleOpen} text={'Reset Password'} icon={<RotateLeftIcon />} />
            <ActionConfirmDialog
                open={open}
                onClose={toggleOpen}
                onConfirm={onConfirm}
                title={'Reset Password'}
                content={'Do you want to reset the password of selected users ?'}
            />
            <CompleteDialog
                title="Reset Password"
                successMessages={successMessages}
                errorMessages={errorMessages}
                open={isCompleteDialogOpen}
                onClose={toggleCompleteDialog}
            />
        </>
    );
};

export default ResetPasswordContainer;
