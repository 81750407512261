import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC, useEffect, useState } from 'react';

interface Props {
    isDialogOpen: boolean;
    title?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm: (e?: any) => void;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    textArea: {
        padding: '10px',
        lineHeight: ' 1.5',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxShadow: '1px 1px 1px #999',
        width: '30vw',
        height: '100px',
        msOverflowY: 'scroll',
    },
    dialog: {
        '& .MuiPaper-root': {
            padding: '20px',
        },
    },
}));

const TextInputDialog: FC<Props> = ({
    isDialogOpen,
    title,
    confirmButtonText = 'confirm',
    cancelButtonText = 'cancel',
    onConfirm,
    onClose,
}) => {
    const classes = useStyles();
    const [textAreaValue, setTextAreaValue] = useState<string>();
    const changeText = (e: any) => {
        setTextAreaValue(e.target.value);
    };
    const cancel = () => {
        setTextAreaValue(undefined);
        onClose();
    };

    const confirm = () => {
        onConfirm(textAreaValue);
        cancel();
    };

    return (
        <Dialog
            className={classes.dialog}
            open={isDialogOpen}
            onClose={cancel}
            aria-labelledby="text-input-dialog-title"
            aria-describedby="text-input-dialog-description"
        >
            <DialogTitle id="text-input-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="text-input-dialog-description"></DialogContentText>
                <textarea onChange={changeText} className={classes.textArea} />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="secondary">
                    {cancelButtonText}
                </Button>
                <Button
                    onClick={confirm}
                    variant="contained"
                    color="primary"
                    disabled={textAreaValue ? false : true}
                >
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TextInputDialog;
