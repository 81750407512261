import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { LoginPage } from './Pages/Login/LoginPage';
import ProtectedRouter from './Components/Auth/ProtectedRouter';
import { UserManagementPage } from './Pages/UserManagementPage/UserManagementPage';
import { EventManagementPage } from './Pages/EventManagement/EventManagementPage';
import { HomePage } from './Pages/HomePage/HomePage';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import HeaderNavigationContainer from './Containers/HeaderNavigationContainer/HeaderNavigationContainer';
import { RoomManagementPage } from './Pages/RoomManagementPage/RoomManagementPage';
import { ReportedUserManagementPage } from './Pages/ReportedUserManagementPage/ReportedUserManagementPage';
import useLayoutManagement from './Hooks/LayoutManagement/useLayoutManagement';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import ContextProviders from './Contexts/providers';

export const protectedRoutes = [
    {
        path: '/',
        component: <HomePage />,
        name: 'Home',
        exact: true,
    },
    {
        path: '/user-management',
        component: <UserManagementPage />,
        name: 'User Management',
        exact: true,
    },
    {
        path: '/room-management',
        component: <RoomManagementPage />,
        name: 'Room Management',
        exact: true,
    },
    {
        path: '/event-management',
        component: <EventManagementPage />,
        name: 'Event Management',
        exact: true,
    },
    {
        path: '/report-user-management',
        component: <ReportedUserManagementPage />,
        name: 'Report User Management',
        exact: true,
    },
];

const theme = createTheme({
    palette: {
        primary: {
            light: '#a100ff',
            main: '#7500c0',
            dark: '#460073',
        },
        secondary: {
            light: '#e6e6dc',
            main: '#96968c',
            dark: '#00000',
        },
        info: {
            main: '#0041f0',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainerShift: {
            width: 'calc(100% * (2 / 3))',
            marginRight: 'calc(100% / 3)',
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    }),
);

function App() {
    const layoutManagement = useLayoutManagement();
    const classes = useStyles();

    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <ContextProviders>
                    <Router>
                        <Switch>
                            <Route path="/login" component={LoginPage} />
                            {protectedRoutes.map((route) => (
                                <ProtectedRouter
                                    key={Math.random()}
                                    path={route.path}
                                    exact={route.exact}
                                >
                                    <div
                                        className={clsx({
                                            [classes.pageContainerShift]:
                                                layoutManagement.state.helpDrawerIsActive,
                                        })}
                                    >
                                        <HeaderNavigationContainer />
                                        {route.component}
                                    </div>
                                </ProtectedRouter>
                            ))}
                        </Switch>
                    </Router>
                </ContextProviders>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;
