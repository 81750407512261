import React, { FC, useEffect, useCallback, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PreferenceRowBooleanPicker from '../../Components/PreferenceRow/PreferenceRowBooleanPicker';
import PreferenceRowNumberPicker from '../../Components/PreferenceRow/PreferenceRowNumberPicker';
import PreferenceRowColorPicker from '../../Components/PreferenceRow/PreferenceRowColorPicker';
import { getPreferencesByCategory, postPreference } from '../../Services/http/event.service';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { useSnackbar } from 'notistack';

import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import { Preference } from '../../Models/event.model';

const useStyles = makeStyles(() => ({
    bannersList: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 20px',
    },
    bannerRow: { width: '100%', marginBottom: '10px' },
    loaderWrapper: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transaction: 'translateX(-50%), translateY(-50%)',
    },
}));

const Labels3dAccordionContainer: FC = () => {
    const { state, dispatch } = useEventManagement();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [disabled, setDisabled] = useState(true);
    const [labels3dPreferences, setLabels3dPreferences] = useState<Preference[]>([]);

    const getLabels3dPreferences = useCallback(async () => {
        const body = {
            event_code: state.eventCode,
            type: 'specific',
            category: '3d',
        };
        await getPreferencesByCategory(body).then((response) => {
            const labels3dRelevantPreferences = [
                'label_background_color',
                'label_font_color',
                'label_font_bold',
                'label_opacity',
            ];
            const labels3dPreferences = response.data.filter((preference: Preference) =>
                labels3dRelevantPreferences.includes(preference.name),
            );

            // ...set initial values
            for (const preference of labels3dPreferences) {
                preference.initialValue = JSON.parse(JSON.stringify(preference.value));
            }
            setLabels3dPreferences(labels3dPreferences);
        });
    }, [state.eventCode]);

    useEffect(() => {
        getLabels3dPreferences();
    }, [getLabels3dPreferences]);

    const onCancel = async () => {
        setDisabled(true);
        await getLabels3dPreferences();
    };

    const onSubmit = async () => {
        const updatedPreferences = labels3dPreferences.filter(
            (preference) => preference.value !== preference.initialValue,
        );

        const results = await Promise.all(
            updatedPreferences.map(async (preference: Preference) => {
                if (preference.id) {
                    const body = {
                        name: preference.name,
                        value: preference.value,
                        category: preference.category,
                        event_code: state.eventCode,
                    };
                    const id = preference.id;
                    return await postPreference(body)
                        .then((success) => true)
                        .catch((error) => false);
                }
            }),
        );

        const hasError = results.includes(false);
        enqueueSnackbar(!hasError ? 'Preferences were updated' : 'There was an error', {
            variant: !hasError ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });

        getLabels3dPreferences();
    };

    const handleChange = (index: number, newValue: string) => {
        const arrayCopy = [...labels3dPreferences];
        arrayCopy[index].value = newValue;
        setLabels3dPreferences(arrayCopy);
        setDisabled(false);
    };

    const renderColorInput = (preference: Preference, index: number) => {
        return (
            <li key={preference.name} className={classes.bannerRow}>
                <PreferenceRowColorPicker
                    preference={preference}
                    onChange={(newValue) => handleChange(index, newValue)}
                />
            </li>
        );
    };

    const renderNumericInput = (
        preference: Preference,
        index: number,
        min: number,
        max: number,
        step: number,
    ) => {
        return (
            <li key={preference.name} className={classes.bannerRow}>
                <PreferenceRowNumberPicker
                    preference={preference}
                    min={min}
                    max={max}
                    step={step}
                    onChange={(preference) => handleChange(index, preference)}
                />
            </li>
        );
    };

    const renderBooleanInput = (preference: Preference, index: number) => {
        return (
            <li key={preference.name} className={classes.bannerRow}>
                <PreferenceRowBooleanPicker
                    preference={preference}
                    onChange={(newValue) => handleChange(index, newValue)}
                />
            </li>
        );
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.LABELS_3D}</strong>
                </Typography>
            </AccordionSummary>
            <List className={classes.bannersList}>
                {labels3dPreferences.map((preference: Preference, i) => {
                    switch (preference.name) {
                        case 'label_font_color':
                            return renderColorInput(preference, i);
                        case 'label_background_color':
                            return renderColorInput(preference, i);
                        case 'label_font_bold':
                            return renderBooleanInput(preference, i);
                        case 'label_opacity':
                            return renderNumericInput(preference, i, 0, 1, 0.1);
                        default:
                            break;
                    }
                })}
            </List>
            <CancelAndConfirmButton onCancel={onCancel} onSubmit={onSubmit} disabled={disabled} />
        </Accordion>
    );
};

export default Labels3dAccordionContainer;
