import React, { FC } from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FileTypes } from '../../Enums/FileTypes.enum';
import { InputFileType } from '../../Enums/InputFileType.enums';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import UploadFile from '../../Components/UploadFile/UploadFile';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import { postFile } from '../../Services/http/content.service';

const useStyles = makeStyles(() => ({
    bannersList: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
    },
    userManual: { margin: '0 20px 10px 20px' },
    loaderWrapper: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transaction: 'translateX(-50%), translateY(-50%)',
    },
}));

const UserManualContainer: FC = () => {
    const classes = useStyles();
    const { state } = useEventManagement();
    const { enqueueSnackbar } = useSnackbar();

    const userManual = { title: 'User manual file (pdf Format)', type: FileTypes.USER_MANUAL };

    const [userManualFile, setUserManualFile] = useState<File | null>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);

    const uploadUserManual = (file: File, fileType: FileTypes, event_code: string) => {
        setIsLoading(true);
        if (file && fileType && event_code) {
            postFile(file, fileType, state?.eventCode)
                .then((res: any) => {
                    if (res?.status === 200) {
                        enqueueSnackbar(
                            'The new user manual file have been uploaded successfully.',
                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            },
                        );
                        setIsLoading(false);
                        setIsExpanded(true);
                        setUserManualFile(null);
                    }
                })
                .catch((e: any) => {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    setIsLoading(false);
                    setIsExpanded(true);
                });
        }
    };

    const onClick = () => {
        isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    };

    // Handles file upload event and updates state
    const handleSelectFile = (file: File) => {
        if (file) {
            setUserManualFile(file);
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const onSubmit = () => {
        if (userManualFile) {
            uploadUserManual(userManualFile, FileTypes.USER_MANUAL, state?.eventCode);
        }
    };

    const onCancel = () => {
        setUserManualFile(null);
        setIsExpanded(false);
        setDisabled(true);
    };

    const renderUploadFile = (fileType: FileTypes) => {
        let selectedFile = null;
        let setSelectedFile: any;

        switch (fileType) {
            case FileTypes.USER_MANUAL: {
                selectedFile = userManualFile;
                setSelectedFile = setUserManualFile;
                break;
            }
            default:
                break;
        }
        return (
            <UploadFile
                banner={userManual}
                onSelect={(file) => handleSelectFile(file)}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                inputFileType={InputFileType.PDF}
            />
        );
    };

    return (
        <Accordion expanded={isExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="user-manual-content"
                id="user-manual-header"
                onClick={onClick}
            >
                <Typography>
                    <strong>{EventAdministrationItems.USER_MANUAL}</strong>
                </Typography>
            </AccordionSummary>
            <div className={classes.userManual}>{renderUploadFile(userManual.type)}</div>
            <CancelAndConfirmButton onCancel={onCancel} onSubmit={onSubmit} disabled={disabled} />
            {isLoading && (
                <div className={classes.loaderWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Accordion>
    );
};

export default UserManualContainer;
