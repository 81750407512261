import React, { FC } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import {
    Menu,
    MenuItem,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    useTheme,
    ListSubheader,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import { protectedRoutes } from '../../App';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { getLocalStorageItemValue } from '../../Services/local-storage.service';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.primary.main,
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbarButtons: {
        display: 'flex',
        marginRight: theme.spacing(0),
    },
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    profileList: {
        width: 300,
        padding: 0,
    },
    listSubheader: {
        fontWeight: 'bold',
    },
}));

interface Props {
    onLogout: () => void;
    helpActionElement: React.ReactElement;
}

const HeaderNavigation: FC<Props> = ({ onLogout, helpActionElement }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const currentUser: any = JSON.parse(getLocalStorageItemValue('currentUser'));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuClick = (evt: any) => {
        setAnchorEl(evt.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar
                position="sticky"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Virtual Show Floor Admin Portal
                    </Typography>
                    <div className={classes.toolbarButtons}>
                        {helpActionElement}
                        <Button
                            color="inherit"
                            aria-controls="profile-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                        >
                            Profile
                        </Button>
                        <Menu
                            id="profile-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <List onClick={handleMenuClose} className={classes.profileList}>
                                <ListSubheader className={classes.listSubheader}>
                                    User Name
                                </ListSubheader>
                                <ListItem>
                                    <ListItemText primary={currentUser.username} />
                                </ListItem>
                                <ListSubheader className={classes.listSubheader}>
                                    Roles
                                </ListSubheader>
                                {currentUser.roles.map((role: any) => (
                                    <ListItem key={role.role}>
                                        <ListItemText primary={role.role} />
                                    </ListItem>
                                ))}
                                <ListSubheader className={classes.listSubheader}>
                                    Event Code
                                </ListSubheader>
                                <ListItem>
                                    <ListItemText primary={currentUser.event_code} />
                                </ListItem>
                            </List>
                        </Menu>
                        <Button color="inherit" onClick={onLogout}>
                            Logout
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {protectedRoutes.map((route) => (
                        <ListItem button key={route.name}>
                            <Link to={route.path}>
                                <ListItemText primary={route.name} />
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};
export default HeaderNavigation;
