import React, { FC, SyntheticEvent, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export interface Props {
    isDialogOpen: boolean;
    handleDownload: () => void;
    onClose: ((evt: SyntheticEvent<Element, Event>) => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: theme.spacing(1, 3),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: theme.spacing(3),
        },
        closeButton: {
            color: theme.palette.grey[500],
            transform: 'translateX(25%)',
        },
        progress: {
            marginRight: '20px',
        },
    }),
);

const DownloadUserDialog: FC<Props> = ({ isDialogOpen, handleDownload, onClose }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const onDownload = async () => {
        setIsLoading(true);
        await handleDownload();
        await setIsLoading(false);
    };

    return (
        <div>
            <Dialog open={isDialogOpen} onClose={onClose} fullWidth disableEnforceFocus>
                <DialogTitle className={classes.dialogTitle} disableTypography>
                    {' '}
                    <Typography>Download User List</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div className={classes.formContainer}>
                        The user list will be downloaded as a zip file. Users marked as not tracked
                        are excluded.
                    </div>
                </DialogContent>
                <DialogActions>
                    {!isLoading ? (
                        <span>
                            <Button color="secondary" onClick={onClose}>
                                Cancel
                            </Button>

                            <Button color="primary" onClick={onDownload}>
                                Download
                            </Button>
                        </span>
                    ) : (
                        <span>
                            <div className={classes.progress}>
                                <CircularProgress />
                            </div>
                        </span>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DownloadUserDialog;
