import React, { FC, useState } from 'react';
import ActionButton from '../../Components/ActionButton/ActionButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import UserActionDialog from '../../Components/Dialogs/UserActionDialog/UserActionDialog';
import { makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { postRefreshUsers } from '../../Services/http/event.service';
import { useSnackbar, VariantType } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
    inputContiainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 70,
        },
    },
}));

const RefreshUsersContainer: FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useUserManagement();
    const [open, setOpen] = React.useState(false);
    const [refreshWindow, setRefreshWindow] = useState<number>(10);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const onRefreshValueChange = (event?: any) => {
        if (event) {
            setRefreshWindow(event.target?.value);
        }
    };

    const onConfirm = async () => {
        const { selectedUsers } = state;
        const userIds = selectedUsers.map((user) => user.id);
        const refreshWindowToSend = refreshWindow > 0 ? refreshWindow * 1000 : 1;

        const body = {
            user_ids: userIds,
            refresh_delay_window: refreshWindowToSend,
        };
        await postRefreshUsers(body)
            .then((msg: any) => {
                enqueueSnackbar(msg?.data?.message || 'Refresh successful', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            })
            .catch((e: any) => {
                let errorMessage = 'Refresh Error: users not refreshed';
                let variant: VariantType = 'error';
                if (e.message === 'Request failed with status code 400') {
                    errorMessage = 'No sessions found for selected users';
                    variant = 'warning';
                }
                enqueueSnackbar(errorMessage, {
                    variant,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            });

        setOpen(false);
    };

    return (
        <>
            <ActionButton
                id="refresh-users-toggle"
                onClick={toggleOpen}
                text={'Refresh Users'}
                icon={<RefreshIcon />}
            />
            <UserActionDialog
                open={open}
                onClose={toggleOpen}
                onConfirm={onConfirm}
                selectedUsers={state.selectedUsers}
                dialogTitle={'Refresh Users'}
                disabled={refreshWindow < 0}
                overrideAllText={
                    "Do you want to force the selected user(s) to refresh their browser session? This will shortly interrupt the user's experience."
                }
            >
                <div className={classes.inputContiainer}>
                    <Typography>{'Refresh delay window (s)'}</Typography>
                    <TextField
                        id="refresh-window-input"
                        value={refreshWindow}
                        type={'number'}
                        InputProps={{ inputProps: { min: 0 } }}
                        placeholder={'Time (seconds)'}
                        onChange={onRefreshValueChange}
                    />
                </div>
            </UserActionDialog>
        </>
    );
};

export default RefreshUsersContainer;
