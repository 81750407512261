import React, { FC, useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import PreferenceRowBooleanPicker from '../../Components/PreferenceRow/PreferenceRowBooleanPicker';
import { Preference } from '../../Models/event.model';
import { getPreferencesByCategory, patchPreferences } from '../../Services/http/event.service';

const useStyles = makeStyles(() => ({
    bgm: {
        margin: '0 20px 10px 20px',
    },
    infoMessage: {
        color: 'grey',
    },
}));

const BackgroundMusicContainer: FC = () => {
    const classes = useStyles();
    const { state } = useEventManagement();
    const { enqueueSnackbar } = useSnackbar();

    const [disabled, setDisabled] = useState<boolean>(true);
    const [bgmPreference, setBgMPreference] = useState<Preference[]>([]);

    const getBgMPreference = useCallback(async () => {
        const body = {
            event_code: state.eventCode,
            type: 'specific',
            name: 'background_music',
        };
        await getPreferencesByCategory(body).then((response: any) => {
            const preference = response.data;
            preference[0].initialValue = JSON.parse(JSON.stringify(preference[0].value));
            setBgMPreference(preference);
        });
    }, [state.eventCode]);

    useEffect(() => {
        getBgMPreference();
    }, [getBgMPreference]);

    const onSubmit = async () => {
        const preference = [...bgmPreference][0];
        const body = {
            value: preference.value,
        };

        const result = await patchPreferences(Number(preference.id), body)
            .then((success) => true)
            .catch((error) => false);

        enqueueSnackbar(result ? 'Preferences were updated' : 'There was an error', {
            variant: result ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });

        getBgMPreference();
        setDisabled(true);
    };

    const onCancel = () => {
        setDisabled(true);
        getBgMPreference();
    };

    const handleChange = (newValue: string) => {
        if (bgmPreference[0].initialValue !== newValue) {
            setDisabled(false);
            const preference = [...bgmPreference];
            preference[0].value = newValue;
            setBgMPreference(preference);
        } else {
            setDisabled(true);
        }
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="bgm-content"
                id="bgm-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.BACKGROUND_MUSIC}</strong>
                </Typography>
            </AccordionSummary>
            <div className={classes.bgm}>
                {bgmPreference[0] && (
                    <PreferenceRowBooleanPicker
                        preference={bgmPreference[0]}
                        onChange={(newValue) => handleChange(newValue)}
                    />
                )}
            </div>
            <CancelAndConfirmButton onCancel={onCancel} onSubmit={onSubmit} disabled={disabled} />
        </Accordion>
    );
};

export default BackgroundMusicContainer;
