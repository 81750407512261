import React, { FC, ReactNode, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import EnhancedTableToolbar from '../EnhancedTableToolbar/EnhancedTableToolbar';
import EnhancedTableHead, { HeadCell } from '../EnhancedTableHead/EnhancedTableHead';
import { getComparator, Order, stableSort } from '../../Utils/tableHelpers';
import { EventCell } from '../../Models/table-cells.model';
import PaginationComponent from '../Pagination/Pagination';

function createData(
    name: string,
    event_code: string,
    mode: string,
    description: string,
): EventCell {
    return { name, event_code, mode, description };
}

const headCells: HeadCell<EventCell>[] = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'event_code', numeric: false, disablePadding: false, label: 'Event Code' },
    { id: 'mode', numeric: false, disablePadding: false, label: 'Mode' },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        loaderWrapper: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transaction: 'translateX(-50%), translateY(-50%)',
        },
    }),
);

export interface Props {
    isLoading: boolean;
    selectedItems: EventCell[];
    events: EventCell[];
    totalEvents: number;
    actionItems: ReactNode[];
    createEventActionElement: ReactNode;
    page: number;
    rowsPerPage: number;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newPage: number) => void;
    tableHeadFilter: (filters: string[]) => void;
    setSelectedItems: (events: EventCell[]) => void;
    refreshEventsList: () => void;
}

const EventManagementTable: FC<Props> = ({
    isLoading = true,
    selectedItems = [],
    events,
    totalEvents,
    actionItems,
    createEventActionElement,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    tableHeadFilter,
    setSelectedItems,
    refreshEventsList,
}) => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof EventCell>('name');
    const [rows, setRows] = useState([] as any[]);

    useEffect(() => {
        const _rows: any[] = [];
        events.map((event) => {
            const { name, event_code, mode, description } = event;
            _rows.push(createData(name, event_code, mode, description));
        });

        setRows(_rows);
    }, [events]);

    const handleRequestSort = (evt: React.MouseEvent<unknown>, property: keyof EventCell) => {
        tableHeadFilter([property, order]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.checked) {
            setSelectedItems(rows);
            return;
        }
        setSelectedItems([]);
    };

    const handleClick = (evt: React.MouseEvent<unknown>, event: EventCell) => {
        const isEventSelected = isSelected(event);

        if (isEventSelected) {
            setSelectedItems(selectedItems.filter((item) => item.event_code !== event.event_code));
        } else {
            setSelectedItems(selectedItems.concat(event));
        }
    };

    const isSelected = (item: EventCell): boolean => {
        let isEventSelected = false;

        for (let i = 0; i < selectedItems.length; i++) {
            if (selectedItems[i].event_code === item.event_code) {
                isEventSelected = true;
                break;
            }
        }

        return isEventSelected;
    };

    const selectRows = (evt: any) => {
        setRowsPerPage(parseInt(evt.target.value, 10));
        setPage(1);
        refreshEventsList();
    };
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selectedItems.length}
                    actionItems={actionItems}
                    actionElementsWithNoSelection={[createEventActionElement]}
                />
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={'small'}>
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selectedItems.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                            checkboxColSpan={1}
                        />
                        <TableBody>
                            {isLoading && (
                                <div className={classes.loaderWrapper}>
                                    <CircularProgress />
                                </div>
                            )}
                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                const isItemSelected = isSelected(row);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(evt) => handleClick(evt, row as EventCell)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.event_code}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell>{row.event_code}</TableCell>
                                        <TableCell>{row.mode}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationComponent
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    selectRows={selectRows}
                    totalCount={totalEvents}
                />
            </Paper>
        </div>
    );
};

export default EventManagementTable;
