import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useAuth from '../../Hooks/Auth/useAuth';
import { getLocalStorageItemValue } from '../../Services/local-storage.service';
import axiosInterceptor from '../../Utils/axiosInterceptors';
import { UPDATE_CURRENT_USER } from '../../Contexts/authContext';

const ProtectedRouter: FC<RouteProps> = ({ children, ...rest }) => {
    const { state, dispatch } = useAuth();

    const [hasLoggedIn, setHasLoggedIn] = useState<boolean | null>(null);

    useEffect(() => {
        const { currentUser } = state;
        if (currentUser) {
            if (hasLoggedIn) {
                return;
            } else {
                axiosInterceptor(currentUser);
                setHasLoggedIn(true);
            }
        } else {
            const user: any = getLocalStorageItemValue('currentUser');
            let _currentUser = '';
            if (user) {
                _currentUser = JSON.parse(user);
                axiosInterceptor(_currentUser);
                setHasLoggedIn(true);
            } else {
                setHasLoggedIn(false);
            }
            dispatch({ type: UPDATE_CURRENT_USER, payload: _currentUser });
        }
    }, [state, dispatch, hasLoggedIn]);

    if (hasLoggedIn === null) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                hasLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default ProtectedRouter;
