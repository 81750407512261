import React, { FC } from 'react';
import LoginContainer from '../../Containers/LoginContainer/LoginContainer';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

type LoginPageProps = {
    title: string;
};

export const LoginPage: FC<LoginPageProps> = () => {
    return (
        <Box
            justifyContent="center"
            alignItems="center"
            margin="auto"
            display="flex"
            height="100vh"
        >
            <Container maxWidth="lg">
                <LoginContainer />
            </Container>
        </Box>
    );
};
