import React, { FC, ReactElement, useEffect, useState, cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Preference } from '../../Models/event.model';
import { List, TableCell, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    title: {
        display: 'flex',
        width: '15%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    checkbox: {
        display: 'flex',
        width: '10%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    inputField: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        width: '50%',
        '& div[class*="MuiFormControl-root"]': {
            width: '80%',
        },
    },
    infoBar: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '15%',
    },
    default: {
        color: 'gray',
    },
    itemCheckBox: {
        display: 'none',
        width: '10%',
    },
    valueChange: {
        color: 'red',
    },
    itemLabel: {
        display: 'none',
        width: '50%',
    },
}));

interface Props {
    preferenceDisplay: Preference;
    preferenceMandatory: Preference;
    preferenceLabel: Preference;
    onChangeDisplay: (newValue: string) => any;
    onChangeMandatory: (newValue: string) => any;
    onChangeLabel: (newValue: string) => any;
    rowTitleStr: string;
    children: ReactElement[];
}

const PreferenceGroupRow: FC<Props> = ({
    preferenceDisplay,
    preferenceMandatory,
    preferenceLabel,
    onChangeDisplay,
    onChangeMandatory,
    onChangeLabel,
    rowTitleStr,
    children,
}) => {
    const classes = useStyles();
    const [isChanged, setIsChanged] = useState(false);
    const [isChangedDisplay, setIsChangedDisplay] = useState(false);
    const [isChangedMandatory, setIsChangedMandatory] = useState(false);
    const [isChangedLabel, setIsChangedLabel] = useState(false);

    const checkIfValueChanged = (newValue: string, initialValue: string, type: string) => {
        let changed = false;
        if (newValue != initialValue) {
            changed = true;
        } else {
            changed = false;
        }

        if (type == 'D') {
            setIsChangedDisplay(changed);
            setIsChanged(changed || isChangedMandatory || isChangedLabel);
        } else if (type == 'M') {
            setIsChangedMandatory(changed);
            setIsChanged(isChangedDisplay || changed || isChangedLabel);
        } else if (type == 'L') {
            setIsChangedLabel(changed);
            setIsChanged(isChangedDisplay || isChangedMandatory || changed);
        }
    };

    const originalDisplay = onChangeDisplay;
    const originalMandatory = onChangeMandatory;
    const originalLabel = onChangeLabel;
    // ...we extend the onChange function that is passed down as a prop so as to check whether the value has been changed
    onChangeDisplay = (...args) => {
        const newValue = originalDisplay.apply(this, args);
        checkIfValueChanged(newValue, preferenceDisplay.initialValue!, 'D');
    };
    onChangeMandatory = (...args) => {
        const newValue = originalMandatory.apply(this, args);
        checkIfValueChanged(newValue, preferenceMandatory.initialValue!, 'M');
    };
    onChangeLabel = (...args) => {
        const newValue = originalLabel.apply(this, args);
        checkIfValueChanged(newValue, preferenceLabel.initialValue!, 'L');
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>{rowTitleStr}</div>
            {/* ...whatever you pass into PreferenceRow as a child needs to expose an onChange prop */}
            <div className={classes.checkbox}>
                {cloneElement(children[0], {
                    onChange: onChangeDisplay,
                })}
            </div>
            <div className={classes.checkbox}>
                {cloneElement(children[1], {
                    onChange: onChangeMandatory,
                })}
            </div>
            <div className={classes.inputField}>
                {cloneElement(children[2], {
                    onChange: onChangeLabel,
                })}
            </div>
            <div className={classes.infoBar}>
                <Typography className={isChanged ? classes.valueChange : classes.default}>
                    {isChanged ? 'Changed' : 'No change'}
                </Typography>
            </div>
        </div>
    );
};

export default PreferenceGroupRow;
