import React, { FC, useEffect, useState } from 'react';
import { Checkbox, makeStyles, TextField } from '@material-ui/core';
import { Preference } from '../../Models/event.model';
import PreferenceRow from './PreferenceRow';

const useStyles = makeStyles(() => ({
    checkbox: {
        marginLeft: -12,
    },
}));

interface Props {
    preference: Preference;
    onChange: (newValue: string) => void;
}

const PreferenceRowBooleanPicker: FC<Props> = ({ preference, onChange }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState<boolean>(preference.value === 'yes');

    useEffect(() => {
        setChecked(preference.value === 'yes');
    }, [preference]);

    const onToggle = (event: any) => {
        setChecked(event.target.checked);
        const newValue = event.target.checked ? 'yes' : 'no';
        return newValue;
    };

    const original = onChange;
    // ...we extend the onChange function that is passed down as a prop with the behaviour specific to boolean
    onChange = (...args) => {
        const newValue = onToggle.apply(this, args);
        original.apply(this, [newValue]);
        return newValue;
    };

    return (
        <PreferenceRow preference={preference} onChange={onChange}>
            {/* ...the onChange props is shallow merged into the children by PreferenceRow */}
            <Checkbox
                className={classes.checkbox}
                checked={checked}
                onChange={() => {}}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </PreferenceRow>
    );
};

export default PreferenceRowBooleanPicker;
