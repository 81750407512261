import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rowLabel: {
        width: '50%',
    },
    baseInputField: {
        height: '42px',
        paddingRight: '20px',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    confirmLabelCol: {
        width: '20%',
        textAlign: 'center',
        color: 'red',
    },
    defaultButton: {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        backgroundColor: 'lightgray',
        width: 100,
        fontSize: 16,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        borderRadius: 6,
        textAlign: 'center',
        cursor: 'pointer',
    },
    disabledLabel: {
        cursor: 'default',
        color: 'gray',
    },
}));

interface Props {
    label: string;
    buttonLabel: string;
    confirmLabel: string;
    onTrigger: (trigger: boolean) => void;
    triggered?: boolean;
    disabled?: boolean;
}

const TriggerActionRow: FC<Props> = ({
    label,
    buttonLabel,
    confirmLabel,
    onTrigger,
    triggered,
    disabled,
}) => {
    const classes = useStyles();

    function onClick() {
        triggered = !triggered;
        onTrigger(triggered);
    }

    return (
        <div className={classes.container}>
            <div className={classes.rowLabel}>{label}</div>
            <span className={classes.baseInputField}>
                <button
                    id="defaultButton"
                    className={`${classes.defaultButton} ${disabled && classes.disabledLabel}`}
                    onClick={() => onClick()}
                    disabled={disabled}
                >
                    {buttonLabel}
                </button>
            </span>
            <div className={classes.confirmLabelCol}>{triggered ? confirmLabel : ''}</div>
        </div>
    );
};

export default TriggerActionRow;
