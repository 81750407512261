import { Table } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { FC } from 'react';
import { UserCell, UserSubCell } from '../../Models/table-cells.model';

const useStyles = makeStyles(
    () => ({
        roleCell: {
            borderBottom: 'none',
            paddingLeft: '0px',
        },
        accessBlocked: {
            borderBottom: 'none',
            paddingLeft: '0px',
            color: 'red',
        },
        subRow: {
            height: '32px',
        },
    }),
    { name: 'MUIDataRow' },
);

interface Props {
    cell: UserCell;
    renderSubCellActionItems: (subCell: UserSubCell, cell: UserCell) => any;
}

const SubCellsTable: FC<Props> = ({ cell, renderSubCellActionItems }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {cell.subCells.map((subCell) => {
                return (
                    <TableRow key={subCell.event}>
                        <TableCell>{subCell.event}</TableCell>
                        <TableCell>
                            {subCell.roles.map((role, key) => (
                                <Table key={key}>
                                    <TableRow className={classes.subRow}>
                                        <TableCell
                                            className={
                                                role.role === 'access-blocked'
                                                    ? classes.accessBlocked
                                                    : classes.roleCell
                                            }
                                        >
                                            {role.role}
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            ))}
                        </TableCell>
                        <TableCell>
                            {subCell.roles.map((role, key) => (
                                <Table key={key}>
                                    <TableRow className={classes.subRow}>
                                        <TableCell className={classes.roleCell}>
                                            {role.room}
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            ))}
                        </TableCell>
                        {renderSubCellActionItems(subCell, cell)}
                    </TableRow>
                );
            })}
        </React.Fragment>
    );
};

export default SubCellsTable;
