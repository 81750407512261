import React, { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FileTypes } from '../../Enums/FileTypes.enum';
import { InputFileType } from '../../Enums/InputFileType.enums';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import { postFaq } from '../../Services/http/event.service';
import UploadFile from '../../Components/UploadFile/UploadFile';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';

const useStyles = makeStyles(() => ({
    bannersList: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
    },
    banner: { margin: '0 20px 10px 20px' },
    loaderWrapper: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transaction: 'translateX(-50%), translateY(-50%)',
    },
}));

const FaqAccordionContainer: FC = () => {
    const classes = useStyles();
    const { state } = useEventManagement();
    const { enqueueSnackbar } = useSnackbar();

    const faqBanner = { title: 'FAQ template file (csv format)', type: FileTypes.FAQ };

    const [faqFile, setFaqFile] = useState<File | null>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    enum FrontEnd {
        VISITOR = 'visitor',
        BACKSTAGE = 'backstage',
    }

    const postFileFunc = (file: File) => {
        setIsLoading(true);
        state.eventCode &&
            postFaq(file, state.eventCode, FrontEnd.VISITOR)
                .then(() => {
                    enqueueSnackbar('The new files have been uploaded successfully.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    resetSelection();
                    setIsLoading(false);
                    setIsExpanded(true);
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    setIsLoading(false);
                    setIsExpanded(true);
                });
    };

    const onClick = () => {
        if (isExpanded) {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);
        }
    };

    // Handles file upload event and updates state
    const handleSelectFile = (file: File, type: FileTypes) => {
        switch (type) {
            case FileTypes.FAQ: {
                setFaqFile(file);
                setDisabled(false);
                break;
            }
            default: {
                break;
            }
        }
    };

    const onSubmit = () => {
        if (faqFile) {
            postFileFunc(faqFile);
        }
    };

    const onCancel = () => {
        resetSelection();
        setIsExpanded(false);
        setDisabled(true);
    };

    const resetSelection = () => {
        setFaqFile(null);
    };

    const renderUploadFile = (fileType: FileTypes) => {
        let selectedFile = null;
        let setSelectedFile: any;

        switch (fileType) {
            case FileTypes.FAQ: {
                selectedFile = faqFile;
                setSelectedFile = setFaqFile;
                break;
            }
            default:
                break;
        }
        return (
            <UploadFile
                banner={faqBanner}
                onSelect={(file) => handleSelectFile(file, faqBanner.type)}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                inputFileType={InputFileType.CSV}
            />
        );
    };

    return (
        <Accordion expanded={isExpanded} onClick={onClick}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.FAQ}</strong>
                </Typography>
            </AccordionSummary>
            <div className={classes.banner}>{renderUploadFile(faqBanner.type)}</div>
            <CancelAndConfirmButton onCancel={onCancel} onSubmit={onSubmit} disabled={disabled} />
            {isLoading && (
                <div className={classes.loaderWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Accordion>
    );
};

export default FaqAccordionContainer;
