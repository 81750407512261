import React, { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
        width: '100%',
    },
    title: {
        display: 'flex',
        width: '15%',
        alignItems: 'center',
    },
    checkbox: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        width: '10%',
    },
    inputField: {
        alignItems: 'center',
        display: 'flex',
        width: '50%',
        justifyContent: 'flex-start',
        '& div[class*="MuiFormControl-root"]': {
            width: '80%',
        },
    },
    infoBar: {
        alignItems: 'center',
        display: 'flex',
        width: '15%',
    },
    default: {
        fontWeight: 'bold',
    },
}));

const RegistrationFormElementHeader: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}></div>
            <div className={classes.checkbox}>
                <Typography>
                    <strong className={classes.default}>{'Display'}</strong>
                </Typography>
            </div>
            <div className={classes.checkbox}>
                <Typography>
                    <strong className={classes.default}>{'Mandatory'}</strong>
                </Typography>
            </div>
            <div className={classes.checkbox}>
                <Typography>
                    <strong className={classes.default}>{'Label'}</strong>
                </Typography>
            </div>
            <div className={classes.infoBar}></div>
        </div>
    );
};

export default RegistrationFormElementHeader;
