import React, { FC, useContext } from 'react';
import LoginForm from '../../Components/LoginForm/LoginForm';
import Grid from '@material-ui/core/Grid';
import { login } from '../../Services/http/auth.service';
import { AuthContext, UPDATE_CURRENT_USER } from '../../Contexts/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import { setLocalStorageItemValue } from '../../Services/local-storage.service';

const LoginContainer: FC = () => {
    const history = useHistory();
    const location: any = useLocation();

    const { from } = location.state || { from: { pathname: '/' } };
    const title = 'Virtual Show Floor Admin Portal';
    const [errorResponse, setErrorResponse] = React.useState('');
    const { state, dispatch } = useContext(AuthContext);
    const onSubmit = (username: string, password: string, eventName?: string) => {
        login(username.trim(), password, eventName?.trim())
            .then((res: any) => {
                if (res.data) {
                    const { user_data } = res.data;
                    setLocalStorageItemValue('currentUser', JSON.stringify(user_data));
                    dispatch({ type: UPDATE_CURRENT_USER, payload: user_data });
                    const urlParam = new URLSearchParams(location.search).get('redirectTo');
                    if (urlParam) {
                        window.location.replace(`${urlParam}?auth=${user_data.jwt_token}`);
                    } else {
                        history.replace(from);
                    }
                }
            })
            .catch((err: any) => {
                setErrorResponse(err?.response?.data?.message);
            });
    };

    return (
        <Grid container justify="center" alignItems="center" direction="column" spacing={6}>
            <Grid item>
                <h1>{title}</h1>
            </Grid>
            <Grid item>
                <LoginForm onSubmit={onSubmit} loginFailMsg={errorResponse} />
            </Grid>
        </Grid>
    );
};

export default LoginContainer;
