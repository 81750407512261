import React, { createContext, Dispatch, FC, Reducer, useReducer } from 'react';
import { Socket } from 'socket.io-client';
import useLogger from '../Hooks/Middleware/userLogger';

export const UPDATE_SOCKET_CONNECTION = 'UPDATE_SOCKET_CONNECTION';

interface UpdateSocketConnectionAction {
    type: typeof UPDATE_SOCKET_CONNECTION;
    payload: Socket | null;
}

type SocketActions = UpdateSocketConnectionAction;

type State = {
    socketConnection: Socket | null;
};

const initialState: State = {
    socketConnection: null,
};

export const SocketContext = createContext<{ state: State; dispatch: Dispatch<SocketActions> }>({
    state: initialState,
    dispatch: () => {},
});

const socketReducer: Reducer<State, SocketActions> = (state: State, action: SocketActions) => {
    switch (action.type) {
        case UPDATE_SOCKET_CONNECTION: {
            return {
                ...state,
                socketConnection: action.payload,
            };
        }
        default:
            return state;
    }
};

export const SocketContextProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(useLogger(socketReducer), initialState);

    const value = { state, dispatch };

    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};
