import React, { FC, useState } from 'react';
import HeaderNavigation from '../../Components/HeaderNavigation/HeaderNavigation';
import { logout } from '../../Services/http/auth.service';
import AlertDialog from '../../Components/Dialogs/AlertDialog/AlertDialog';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { clearLocalStorageItemValue } from '../../Services/local-storage.service';
import HelpActionContainer from '../HelpActionContainer/HelpActionContainer';

const HeaderNavigationContainer: FC = () => {
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const toggleConfirmDialog = () => {
        setIsConfirmDialogOpen(!isConfirmDialogOpen);
    };

    const onLogout = () => {
        toggleConfirmDialog();
        logout()
            .then(() => {
                clearLocalStorageItemValue('currentUser');
                window.location.reload();
            })
            .catch((err) => {
                setErrorMessage(err.message);
            });
    };

    const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertOpen(false);
    };

    return (
        <>
            <HeaderNavigation
                onLogout={toggleConfirmDialog}
                helpActionElement={<HelpActionContainer />}
            />
            <AlertDialog
                isDialogOpen={isConfirmDialogOpen}
                onClose={toggleConfirmDialog}
                onConfirm={onLogout}
                confirmButtonText="Log out"
                title="Do you want to log out?"
            />
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default HeaderNavigationContainer;
