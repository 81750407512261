import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import ReportedUserManagementTableContainer from '../../Containers/ReportedUserManagementTableContainer/ReportedUserManagementTableContainer';
import { ReportedUserManagementContextProvider } from '../../Contexts/reportedUserManagementContext';
import { Breadcrumbs, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    breadcrumb: {
        marginTop: theme.spacing(6),
    },
}));

export const ReportedUserManagementPage: FC = () => {
    const classes = useStyles();
    return (
        <ReportedUserManagementContextProvider>
            <Container maxWidth={'xl'}>
                <div className={classes.breadcrumb}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href="/">
                            Home
                        </Link>
                        <Link color="textPrimary" href="/user-management" aria-current="page">
                            Report User Management
                        </Link>
                    </Breadcrumbs>
                </div>
                <ReportedUserManagementTableContainer />
            </Container>
        </ReportedUserManagementContextProvider>
    );
};
