import React, { FC, ReactNode, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import UserManagementTable from '../../Components/UserManagementTable/UserManagementTable';
import { getUserList } from '../../Services/http/user.service';
import { getRooms } from '../../Services/http/content.service';
import UploadUserActionContainer from '../UploadUserActionContainer/UploadUserActionContainer';
import DownloadUserListActionContainer from '../DownloadUserListActionContainer/DownloadUserListActionContainer';
import AnonymizeUserActionContainer from '../AnonymizeUserActionContainer/AnonymizeUserActionContainer';
import {
    UPDATE_SELECTED_USERS,
    UPDATE_USER_LIST,
    UPDATE_ROOMS,
    UPDATE_ROLE_ID_LIST,
} from '../../Contexts/userManagementContext';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import ResetPasswordContainer from '../ResetPasswordContainer/ResetPasswordContainer';
import { User } from '../../Models/user.model';
import CreateUserActionContainer from '../CreateUserActionContainer/CreateUserActionContainer';
import TableSearch from '../../Components/TableSearch/TableSearch';
import LogoutContainer from '../LogoutContainer/LogoutContainer';
import { getUserRoleList } from '../../Services/http/auth.service';
import EditUserActionContainer from '../EditUserActionContainer/EditUserActionContainer';
import { UserCell, UserSubCell } from '../../Models/table-cells.model';
import SubCellsTableContainer from '../SubCellsTableContainer/SubCellsTableContainer';
import TableCell from '@material-ui/core/TableCell';
import DeleteUserRolesActionContainer from '../../Containers/DeleteUserRolesActionContainer/DeleteUserRolesActionContainer';
import UserRoleAssignmentActionContainer from '../../Containers/UserRoleAssignmentActionContainer/UserRoleAssignmentActionContainer';
import EventFilterActionContainer from '../../Containers/EventFilterActionContainer/EventFilterActionContainer';
import UserRoleFilterActionContainer from '../UserRoleFilterActionContainer/UserRoleFilterActionContainer';
import RefreshUsersContainer from '../RefreshUsersContainer/RefreshUsersContainer';
import DeleteAllUserRolesContainer from '../DeleteUserRolesActionContainer/DeleteAllUserRolesActionContainer';
import DeleteUserContainer from '../DeleteUserContainer/DeleteUserContainer';

const UserManagementTableContainer: FC = () => {
    const { dispatch } = useUserManagement();

    // This is not the total number of users, just the users visible right now
    const [users, setUsers] = useState([] as User[]);
    const [filteredUsers, setFilteredUsers] = useState([] as User[]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [eventCode, setEventCode] = useState('');
    const [userRole, setUserRole] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [totalUsersCount, setTotalUsersCount] = useState<number>(0);
    const [tableHeaderFilters, setTableHeaderFilters] = useState<string[]>();
    const [searchText, setSearchText] = useState<string>('');

    const order_by = tableHeaderFilters ? tableHeaderFilters[0] : undefined;
    const order_direction = tableHeaderFilters ? tableHeaderFilters[1] : undefined;

    const handleActionComplete = ({ unselectUsers = true } = {}) => {
        setUsers([]);
        dispatch({ type: UPDATE_USER_LIST, payload: { users: filteredUsers } });
        setFilteredUsers([...filteredUsers]);

        // For actions referring to a specific user, i.e. EDIT/DELETE/ADD roles,
        // we want to keep our selected users and stay on the current page
        if (unselectUsers) {
            setPage(1);
            setSelectedUsers([]);
        }
    };

    const handleDeleteUsers = () => {
        setPage(1);
        setSelectedUsers([]);
        getTotalUserList(
            rowsPerPage,
            page,
            order_by,
            order_direction,
            searchText,
            eventCode === 'All events' || userRole === 'admin' ? '' : eventCode,
            userRole === 'All roles' ? '' : userRole,
        );
    };

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page]);

    useEffect(() => {
        getRooms().then((res: any) => {
            dispatch({ type: UPDATE_ROOMS, payload: { rooms: res.data.event_rooms } });
        });

        // Role information retrieving
        getUserRoleList().then((res: any) => {
            dispatch({ type: UPDATE_ROLE_ID_LIST, payload: { availableRoleIds: res.data.roles } });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {}, [
        totalUsersCount,
        users,
        filteredUsers,
        tableHeaderFilters,
        order_by,
        order_direction,
        searchText,
        eventCode,
        userRole,
    ]);

    // Get user list by filter params
    const fetchUsers = () => {
        setIsLoading(true);
        getTotalUserList(
            rowsPerPage,
            page,
            order_by,
            order_direction,
            searchText,
            eventCode === 'All events' || userRole === 'admin' ? '' : eventCode,
            userRole === 'All roles' ? '' : userRole,
        );
    };

    // Get total user list
    const getTotalUserList = (
        rowsPerPage: number,
        page: number,
        order_by?: string,
        order_direction?: string,
        searchText?: string,
        event_code?: string,
        user_role?: string,
    ) => {
        getUserList(rowsPerPage, page, order_by, order_direction, searchText, event_code, user_role)
            .then((response) => assignResponse(response))
            .catch((e) => {
                console.error(e);
                setIsLoading(false);
            });
    };

    // Assign value to local state variables
    const assignResponse = (res: any) => {
        const receivedUsers: User[] = res?.data.users;
        setFilteredUsers(receivedUsers);
        setUsers(receivedUsers);
        setIsLoading(false);
        setTotalUsersCount(res.data.total_count);
        dispatch({ type: UPDATE_USER_LIST, payload: { users: receivedUsers } });
    };

    // On a specific user slection
    const handleSetSelectedUsers = (users: User[]) => {
        setSelectedUsers(users);
        dispatch({ type: UPDATE_SELECTED_USERS, payload: { selectedUsers: users } });
    };

    // On selected eventCode change
    const handleEventCodeChange = (eventCode: string) => {
        setPage(1);
        setEventCode(eventCode);
        getTotalUserList(
            rowsPerPage,
            page,
            order_by,
            order_direction,
            searchText,
            eventCode === 'All events' || userRole === 'admin' ? '' : eventCode,
            userRole === 'All roles' ? '' : userRole,
        );
    };

    // On selected role change
    const handleUserRoleChange = (userRole: string) => {
        if (userRole === 'admin') {
            setEventCode('All events');
        }
        setPage(1);
        setUserRole(userRole);
        getTotalUserList(
            rowsPerPage,
            page,
            order_by,
            order_direction,
            searchText,
            eventCode === 'All events' || userRole === 'admin' ? '' : eventCode,
            userRole === 'All roles' ? '' : userRole,
        );
    };

    // On search input value change
    const handleOnSearchValueChange = (searchValue?: any) => {
        setPage(1);
        searchValue?.length ? setSearchText(searchValue) : setSearchText('');
        getTotalUserList(
            rowsPerPage,
            page,
            order_by,
            order_direction,
            searchValue,
            eventCode === 'All events' || userRole === 'admin' ? '' : eventCode,
            userRole === 'All roles' ? '' : userRole,
        );
    };

    const actionItems: ReactNode[] = [
        <EditUserActionContainer
            key="edit user action"
            onCompleteCallback={handleActionComplete}
        />,
        <AnonymizeUserActionContainer
            key={'Anonymize'}
            onCompleteCallback={handleActionComplete}
        />,
        <ResetPasswordContainer key={'Reset Password'} />,
        <LogoutContainer key={'Logout Users'} />,
        <DeleteAllUserRolesContainer
            key={'Delete all roles'}
            onCompleteCallback={handleActionComplete}
        />,
        <RefreshUsersContainer key={'Refresh Users'} />,
        <DeleteUserContainer key={'Delete User'} onCompleteCallback={handleDeleteUsers} />,
    ];

    const eventFilter: ReactNode = (
        <EventFilterActionContainer
            fieldDisabled={userRole === 'admin'}
            onCompleteCallback={handleEventCodeChange}
        />
    );

    const userRoleFilter: ReactNode = (
        <UserRoleFilterActionContainer onCompleteCallback={handleUserRoleChange} />
    );

    const renderSubCellTable = (cell: UserCell) => {
        return (
            <SubCellsTableContainer
                cell={cell}
                renderSubCellActionItems={renderSubCellActionItems}
                renderAddRolesActionElement={renderAddRolesActionElement}
            />
        );
    };
    const renderSubCellActionItems = (subCell: UserSubCell, cell: UserCell) => {
        return (
            <TableCell>
                <UserRoleAssignmentActionContainer
                    onCompleteCallback={() => handleActionComplete({ unselectUsers: false })}
                    subCell={subCell}
                    cell={cell}
                    label={'Edit'}
                />
                <DeleteUserRolesActionContainer
                    onCompleteCallback={() => handleActionComplete({ unselectUsers: false })}
                    subCell={subCell}
                    username={cell.username}
                />
            </TableCell>
        );
    };

    const renderAddRolesActionElement = (cell: UserCell) => {
        return (
            <UserRoleAssignmentActionContainer
                onCompleteCallback={() => handleActionComplete({ unselectUsers: false })}
                cell={cell}
                label={'Add'}
            />
        );
    };

    return (
        <Paper elevation={0}>
            <Box height="100%" width="100%" marginLeft="auto" marginRight="auto">
                <TableSearch searchText={searchText} onSearch={handleOnSearchValueChange} />
                <UserManagementTable
                    tableHeadFilter={setTableHeaderFilters}
                    totalUsers={totalUsersCount}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    selectedItems={selectedUsers}
                    setSelectedItems={handleSetSelectedUsers}
                    users={filteredUsers}
                    downloadActionElement={
                        <DownloadUserListActionContainer
                            key="download user action"
                            onCompleteCallback={handleActionComplete}
                        />
                    }
                    uploadActionElement={
                        <UploadUserActionContainer
                            key="upload user action"
                            onCompleteCallback={handleActionComplete}
                        />
                    }
                    createUserActionElement={
                        <CreateUserActionContainer
                            key="create user action"
                            onCompleteCallback={handleActionComplete}
                        />
                    }
                    actionItems={actionItems}
                    userRoleFilter={userRoleFilter}
                    eventFilter={eventFilter}
                    renderSubCellTable={renderSubCellTable}
                    isLoading={isLoading}
                    collapseAll={true}
                />
            </Box>
        </Paper>
    );
};

export default UserManagementTableContainer;
