import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC, useEffect, useState } from 'react';
import { User } from '../../../Models/user.model';
import { fetchAllEvents } from '../../../Services/http/event.service';
import useAuth from '../../../Hooks/Auth/useAuth';

export interface UserDialogOptions {
    editUserId?: number;
    sendEmail?: boolean;
}

interface Props {
    onSubmit: (user: Partial<User>, userDialogOptions: UserDialogOptions) => void;
    toggleDialog: () => void;
    errorMessage: string;
    editUser: User | null;
    onSubmitRole?: (user: Partial<User>, userDialogOptions: UserDialogOptions) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: theme.spacing(1, 3),
            '& .MuiTypography-root': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        formContainer: {
            '& .MuiTextField-root': {
                width: 300,
            },

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: theme.spacing(3),
        },
        closeButton: {
            color: theme.palette.grey[500],
            transform: 'translateX(25%)',
        },
        dialogContent: {
            '& div': {
                marginBottom: theme.spacing(1),
            },
        },
        eventCodes: {
            maxWidth: '250px',
            '& div': {
                marginBottom: 0,
            },
        },
        sendEmail: {
            color: 'grey',
        },
        active: {
            color: 'grey',
        },
        notTracked: {
            color: 'grey',
        },
    }),
);

const UserDialog: FC<Props> = ({
    onSubmit,
    toggleDialog,
    errorMessage,
    editUser,
    onSubmitRole,
}) => {
    const classes = useStyles();
    const { state: authState } = useAuth();

    const [open, setOpen] = React.useState(true);

    const [password, setPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [sendEmail, setSendEmail] = useState(false);
    const [active, setActive] = useState(true);
    const [notTracked, setNotTracked] = useState(false);
    const [eventCode, setEventCode] = useState('');

    const [allEventCodes, setAllEventCodes] = React.useState([]); // ...for create
    const [hasAdminRole] = React.useState(
        authState.currentUser.roles.some((role: any) => role.role === 'admin'),
    );

    const [errors, setErrors] = useState({
        eventCode: '',
        email: '',
        firstname: '',
        lastname: '',
        password: '',
    });
    useEffect(() => {
        if (editUser) {
            setFirstname(editUser.firstname);
            setLastname(editUser.lastname);
            if (editUser.email) setEmail(editUser.email);
            if (editUser.company) setCompany(editUser.company);
            if (editUser.job_title) setJobTitle(editUser.job_title);
            setActive(editUser.active);
            setNotTracked(editUser.not_tracked);
        } else {
            getAllEventCodes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editUser]);

    const getAllEventCodes = () => {
        if (authState.currentUser.roles.some((role: any) => role.role !== 'admin')) {
            setEventCode(authState.currentUser.event_code);
        }
        fetchAllEvents()
            .then((res) => setAllEventCodes(res.data.events))
            .catch((error) => error);
    };

    const handleClose = () => {
        setOpen(false);
        toggleDialog();
    };

    const handleSubmit = async () => {
        if (handleValidation()) {
            if (editUser) {
                onSubmit(
                    {
                        email,
                        firstname,
                        lastname,
                        job_title: jobTitle,
                        company,
                        active,
                        not_tracked: notTracked,
                    },
                    { editUserId: editUser.id },
                );
            } else {
                onSubmit(
                    {
                        email,
                        firstname,
                        lastname,
                        password,
                        company,
                        job_title: jobTitle,
                        event_code: eventCode !== '' ? eventCode : undefined,
                        active,
                        not_tracked: notTracked,
                    },
                    { sendEmail },
                );
            }
        }
    };

    const handleSubmitRole = async () => {
        if (handleValidation()) {
            if (editUser) {
                onSubmitRole!(
                    {
                        email,
                        firstname,
                        lastname,
                        job_title: jobTitle,
                        company,
                        active,
                        not_tracked: notTracked,
                    },
                    { editUserId: editUser.id },
                );
            } else {
                onSubmitRole!(
                    {
                        email,
                        firstname,
                        lastname,
                        password,
                        company,
                        job_title: jobTitle,
                        event_code: eventCode !== '' ? eventCode : undefined,
                        active,
                        not_tracked: notTracked,
                    },
                    { sendEmail },
                );
            }
        }
    };

    const handleValidation = () => {
        const newErrors = { ...errors };
        let formIsValid = true;
        const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!editUser) {
            //Event Code
            if ((!eventCode || eventCode === '') && !hasAdminRole) {
                formIsValid = false;
                newErrors.eventCode = 'Cannot be empty';
            } else {
                newErrors.eventCode = '';
            }

            //Password
            if (password === '') {
                formIsValid = false;
                newErrors.password = 'Cannot be empty';
            } else {
                newErrors.password = '';
            }
        }

        //Email
        if (email === '') {
            formIsValid = false;
            newErrors.email = 'Cannot be empty';
        } else if (!email.match(mailformat)) {
            formIsValid = false;
            newErrors.email = 'Username is not valid';
        } else {
            newErrors.email = '';
        }

        //First Name
        if (firstname === '') {
            formIsValid = false;
            newErrors.firstname = 'Cannot be empty';
        } else {
            newErrors.firstname = '';
        }

        //Last Name
        if (lastname === '') {
            formIsValid = false;
            newErrors.lastname = 'Cannot be empty';
        } else {
            newErrors.lastname = '';
        }

        setErrors(newErrors);
        return formIsValid;
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle className={classes.dialogTitle} id="customized-dialog-title">
                    <Typography>{editUser ? 'Edit' : 'Create'} a user</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.formContainer} noValidate autoComplete="off">
                        {!editUser && (
                            <FormControl>
                                <Autocomplete
                                    className={classes.eventCodes}
                                    id="tags-outlined"
                                    options={allEventCodes}
                                    disabled={!hasAdminRole}
                                    defaultValue={
                                        authState.currentUser.roles.some(
                                            (role: any) => role.role !== 'admin',
                                        ) && { event_code: authState.currentUser.event_code }
                                    }
                                    onChange={(event, newValue: any) => {
                                        setEventCode(newValue ? newValue.event_code : '');
                                    }}
                                    getOptionLabel={(event: any) => event.event_code || ''}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            required={!hasAdminRole}
                                            {...params}
                                            variant="outlined"
                                            label="Choose event code"
                                            placeholder="Event Code"
                                            error={errors.eventCode !== ''}
                                            helperText={
                                                errors.eventCode !== '' ? errors.eventCode : ''
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        )}
                        <TextField
                            required
                            autoComplete="new-password"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={errors.email !== ''}
                            helperText={errors.email !== '' ? errors.email : ''}
                        />
                        <TextField
                            required
                            autoComplete="new-password"
                            label="First Name"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            error={errors.firstname !== ''}
                            helperText={errors.firstname !== '' ? errors.firstname : ''}
                        />
                        <TextField
                            required
                            autoComplete="new-password"
                            label="Last Name"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            error={errors.lastname !== ''}
                            helperText={errors.lastname !== '' ? errors.lastname : ''}
                        />
                        {!editUser && (
                            <TextField
                                required
                                autoComplete="new-password"
                                type="password"
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={errors.password !== ''}
                                helperText={errors.password !== '' ? errors.password : ''}
                            />
                        )}
                        <TextField
                            label="Company"
                            autoComplete="new-password"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        />
                        <TextField
                            id="jobTitle"
                            autoComplete="new-password"
                            label="Title"
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    className={classes.active}
                                    checked={!active}
                                />
                            }
                            className={classes.active}
                            label="Inactive"
                            labelPlacement="end"
                            onChange={() => setActive(!active)}
                        />
                        {!editUser && (
                            <FormControlLabel
                                value={sendEmail}
                                control={
                                    <Checkbox color="secondary" className={classes.sendEmail} />
                                }
                                className={classes.sendEmail}
                                label="Send email"
                                labelPlacement="end"
                                onChange={() => setSendEmail(!sendEmail)}
                            />
                        )}
                        {errorMessage !== '' ? (
                            <FormHelperText error>{errorMessage}</FormHelperText>
                        ) : undefined}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    className={classes.notTracked}
                                    checked={notTracked}
                                />
                            }
                            className={classes.notTracked}
                            label="Not Tracked"
                            labelPlacement="end"
                            onChange={() => setNotTracked(!notTracked)}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {editUser ? 'Update' : 'Save'}
                    </Button>
                    {!editUser && eventCode && (
                        <Button onClick={handleSubmitRole} color="primary">
                            Save and Edit Role
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserDialog;
