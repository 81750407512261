import React, { FC, ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';

import { AuthContextProvider } from './authContext';
import { RoomManagementContextProvider } from './roomManagementContext';
import { SocketContextProvider } from './socketContext';
import { EventManagementContextProvider } from './eventManagementContext';

interface Props {
    children: ReactNode;
}

const ContextProviders: FC<Props> = ({ children = null }) => {
    return (
        <SnackbarProvider maxSnack={3}>
            <AuthContextProvider>
                <RoomManagementContextProvider>
                    <EventManagementContextProvider>
                        <SocketContextProvider>{children}</SocketContextProvider>
                    </EventManagementContextProvider>
                </RoomManagementContextProvider>
            </AuthContextProvider>
        </SnackbarProvider>
    );
};

export default ContextProviders;
