import React, { createContext, Dispatch, FC, Reducer, useReducer } from 'react';
import { ReportedUserCell } from '../Models/table-cells.model';

// Actions
export const UPDATE_REPORTED_USER_LIST = 'UPDATE_REPORTED_USER_LIST';
export const UPDATE_SELECTED_REPORTED_USERS = 'UPDATE_SELECTED_REPORTED_USERS';

// Action interface
interface UpdateReportedUserListAction {
    type: typeof UPDATE_REPORTED_USER_LIST;
    payload: {
        reportedUsers: ReportedUserCell[];
    };
}

interface UpdateSelectedReportedUsers {
    type: typeof UPDATE_SELECTED_REPORTED_USERS;
    payload: {
        selectedReportedUsers: ReportedUserCell[];
    };
}

// Actions type
type ReportedUserManagementActions = UpdateReportedUserListAction | UpdateSelectedReportedUsers;

// State
type State = {
    reportedUsers: ReportedUserCell[];
    selectedReportedUsers: ReportedUserCell[];
};

const initialState: State = {
    reportedUsers: [],
    selectedReportedUsers: [],
};

export const ReportedUserManagementContext = createContext<{
    state: State;
    dispatch: Dispatch<ReportedUserManagementActions>;
}>({
    state: initialState,
    dispatch: () => {},
});

const reportedUserManagementReducer: Reducer<State, ReportedUserManagementActions> = (
    state: State,
    action: ReportedUserManagementActions,
) => {
    switch (action.type) {
        case UPDATE_REPORTED_USER_LIST: {
            return {
                ...state,
                reportedUsers: [...action.payload.reportedUsers],
            };
        }
        case UPDATE_SELECTED_REPORTED_USERS: {
            return {
                ...state,
                selectedReportedUsers: [...action.payload.selectedReportedUsers],
            };
        }
        default:
            return state;
    }
};

export const ReportedUserManagementContextProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(reportedUserManagementReducer, initialState);

    const value = { state, dispatch };

    return (
        <ReportedUserManagementContext.Provider value={value}>
            {children}
        </ReportedUserManagementContext.Provider>
    );
};
