import React, { FC } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useAuth from '../../Hooks/Auth/useAuth';
import Config from '../../config.json';

const useStyles = makeStyles((theme: Theme) => ({
    paperContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(4),
            width: theme.spacing(32),
            height: theme.spacing(16),
        },
    },
    paper: {
        cursor: 'pointer',
    },
    pageTitle: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        height: '100%',
        textDecoration: 'none',
        '& > *': {
            alignSelf: 'center',
        },
    },
}));

type TitledSectionProps = {
    title: string;
};

const TitledSection: FC<TitledSectionProps> = ({ title, children }) => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.paperContainer} variant="h3" title={title}>
                {title}
            </Typography>
            <div className={classes.paperContainer}>{children}</div>
        </>
    );
};

export const HomePage: FC = () => {
    const classes = useStyles();
    const { currentUser } = useAuth().state;

    const collabUrl = Config.VSF_K8S_W3DS_URL;
    const toCollabUrl = (msSystem?: string) => {
        return `${collabUrl}${msSystem}?auth=${currentUser.jwt_token}`;
    };

    return (
        <>
            <TitledSection title="Admin Portal">
                <Paper elevation={3} className={classes.paper}>
                    <Link to="/user-management" className={classes.pageTitle}>
                        <Typography variant="h6">User Management</Typography>
                    </Link>
                </Paper>
                <Paper elevation={3}>
                    <Link to="/room-management" className={classes.pageTitle}>
                        <Typography variant="h6">Room Management</Typography>
                    </Link>
                </Paper>
                <Paper elevation={3}>
                    <Link to="/event-management" className={classes.pageTitle}>
                        <Typography variant="h6">Event Management</Typography>
                    </Link>
                </Paper>
                <Paper elevation={3}>
                    <Link to="/report-user-management" className={classes.pageTitle}>
                        <Typography variant="h6">Report User Management</Typography>
                    </Link>
                </Paper>
            </TitledSection>

            {currentUser.roles.some(
                (role: any) => role.role === 'admin' || role.role === 'event-admin',
            ) && (
                <TitledSection title="3D/2D Content">
                    <Paper elevation={3}>
                        <Link
                            to={{ pathname: toCollabUrl('/catalogue-ms') }}
                            target="_blank"
                            className={classes.pageTitle}
                        >
                            <Typography variant="h6">3D</Typography>
                            <Typography variant="h6">Catalogue Management</Typography>
                        </Link>
                    </Paper>
                    <Paper elevation={3}>
                        <Link
                            to={{ pathname: toCollabUrl('/space-ms') }}
                            target="_blank"
                            className={classes.pageTitle}
                        >
                            <Typography variant="h6">3D</Typography>
                            <Typography variant="h6">Space Management</Typography>
                        </Link>
                    </Paper>
                    <Paper elevation={3}>
                        <Link
                            to={{ pathname: toCollabUrl('/') }}
                            target="_blank"
                            className={classes.pageTitle}
                        >
                            <Typography variant="h6">2D</Typography>
                            <Typography variant="h6">Content Management</Typography>
                        </Link>
                    </Paper>
                </TitledSection>
            )}
        </>
    );
};
