import React, { FC, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputFileType } from '../../Enums/InputFileType.enums';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    banner: {
        width: '50%',
    },
    inputField: {
        width: '30%',
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    selectedFile: {
        width: '20%',
        textAlign: 'center',
    },
    button: {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        backgroundColor: 'lightgray',
        width: 100,
        height: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        borderRadius: 6,
        textAlign: 'center',
        cursor: 'pointer',
    },
    inputButton: {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        backgroundColor: 'lightgray',
        width: 100,
        height: 25,
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        borderRadius: 6,
        textAlign: 'center',
        cursor: 'pointer',
    },
    input: {
        visibility: 'hidden',
    },
}));

interface Props {
    templateData: any;
    selectedFile: File | null;
    setSelectedFile: (file: File | null) => void;
    fileChanged: boolean;
    setFileChanged: (state: boolean) => void;
    inputFileType: InputFileType | string;
    setDisabled: (state: boolean) => void;
}
const selectText = 'Select File ..';
const selectDefaultText = 'Default';

const UploadAndChangeFile: FC<Props> = ({
    templateData,
    selectedFile,
    setSelectedFile,
    fileChanged,
    setFileChanged,
    inputFileType,
    setDisabled,
}) => {
    const inputSelect = useRef(null);

    useEffect(() => {
        const input: any = inputSelect.current;
        input.value = '';
    });

    const onFileChange = (event: any) => {
        setSelectedFile(event.target.files[0]);
        setFileChanged(true);
        setDisabled(false);
    };

    const onClickButton = (event: any) => {
        const input: any = inputSelect.current;
        input.value = '';
        setSelectedFile(null);
        setFileChanged(true);
        setDisabled(false);
    };

    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.banner}>{templateData.title}</div>
            <span className={classes.inputField}>
                <label htmlFor={templateData.defaultName} className={classes.inputButton}>
                    {selectDefaultText}
                    <input
                        id={templateData.defaultName}
                        className={classes.input}
                        type="button"
                        onClick={onClickButton}
                    />
                </label>
            </span>
            <span className={classes.inputField}>
                <label htmlFor={templateData.name} className={classes.button}>
                    {selectText}
                    <input
                        id={templateData.name}
                        ref={inputSelect}
                        className={classes.input}
                        type="file"
                        accept={inputFileType}
                        onChange={onFileChange}
                    />
                </label>
            </span>

            <div className={classes.selectedFile} style={{ color: fileChanged ? 'red' : 'grey' }}>
                {fileChanged
                    ? selectedFile
                        ? `New file "${selectedFile.name}" selected`
                        : 'Switched to default version'
                    : 'No change'}
            </div>
        </div>
    );
};

export default UploadAndChangeFile;
