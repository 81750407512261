import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import React, { FC, useEffect, useState } from 'react';
import TimezoneSelect, { allTimezones, ITimezone } from 'react-timezone-select';
import useEventManagement from '../../../Hooks/EventManagement/useEventManagement';
import { DatePickerType } from '../../../Models/date.model';
import { Statistic } from '../../../Models/event.model';
interface Props {
    onSubmit: (statistic: Statistic, eventCode?: string) => void;
    toggleDialog: () => void;
    errorMessage: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: theme.spacing(1, 3),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: theme.spacing(3),
        },
        closeButton: {
            color: theme.palette.grey[500],
            transform: 'translateX(25%)',
        },
        label: {
            whiteSpace: 'nowrap',
            marginBottom: theme.spacing(1),
        },
        progress: {
            marginRight: '20px',
        },
    }),
);

const ExtractStatisticDialog: FC<Props> = ({ onSubmit, toggleDialog, errorMessage }) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const [email, setEmail] = useState('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const locale = window.navigator.language;
    const { state } = useEventManagement();

    const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    const localeMap = {
        en: enLocale,
        de: deLocale,
    };

    useEffect(() => {
        if (startDate) validateDate(DatePickerType.Start);
        if (endDate) validateDate(DatePickerType.End);
        if (email && startDate && endDate) handleValidation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, startDate, endDate]);

    const renderDateTimePicker = (type: DatePickerType) => {
        const isStart = type === DatePickerType.Start;
        const val = isStart ? 'startDate' : 'endDate';
        return (
            <DateTimePicker
                renderInput={(props: any) => (
                    <TextField
                        {...props}
                        error={isStart ? errors.startDate !== '' : errors.endDate !== ''}
                        helperText={errors[val] !== '' ? errors[val] : ''}
                        className={classes.label}
                    />
                )}
                label={isStart ? 'Start Date' : 'End Date'}
                value={isStart ? startDate : endDate}
                onChange={(newValue) => {
                    if (isStart) {
                        setStartDate(newValue as Date);
                    } else {
                        setEndDate(newValue as Date);
                    }
                }}
            />
        );
    };

    const [errors, setErrors] = useState({
        email: '',
        date: '',
        startDate: '',
        endDate: '',
    });

    const handleClose = () => {
        setOpen(false);
        toggleDialog();
    };
    const dateWithTimeZone = (timeZone: any, dateParam: Date) => {
        const date = new Date(
            Date.UTC(
                dateParam.getFullYear(),
                dateParam.getMonth(),
                dateParam.getDate(),
                dateParam.getHours(),
                dateParam.getMinutes(),
            ),
        );

        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
        const offset = utcDate.getTime() - tzDate.getTime();

        date.setTime(date.getTime() + offset);

        return date;
    };

    const handleSubmit = async () => {
        if (handleValidation() && email && startDate && endDate) {
            setIsLoading(true);
            const timezoneValue = JSON.parse(JSON.stringify(selectedTimezone)).value
                ? JSON.parse(JSON.stringify(selectedTimezone)).value
                : selectedTimezone;

            const convertedStartDate = dateWithTimeZone(timezoneValue, startDate);
            const convertedEndDate = dateWithTimeZone(timezoneValue, endDate);
            await onSubmit({ email, to: convertedEndDate, from: convertedStartDate });
            await setIsLoading(false);
        }
    };

    const validateEmail = () => {
        const newErrors = { ...errors };
        let formIsValid = true;
        const mailformat =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // ...email
        if (email === '') {
            formIsValid = false;
            newErrors.email = 'Cannot be empty';
        } else if (!email.match(mailformat)) {
            formIsValid = false;
            newErrors.email = 'Username is not valid';
        } else {
            newErrors.email = '';
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const validateDate = (type: DatePickerType) => {
        let formIsValid = true;
        const newErrors = { ...errors };
        const isStart = type === DatePickerType.Start;
        const val = isStart ? 'startDate' : 'endDate';
        const date = isStart ? startDate : endDate;
        const name = isStart ? 'Start' : 'End';
        if (endDate !== null && startDate !== null && startDate < endDate) {
            newErrors.startDate = '';
            newErrors.endDate = '';
        }

        if (date === null) {
            formIsValid = false;
            newErrors[val] = `${name} date is required`;
        } else if (date && isNaN(date.getTime())) {
            formIsValid = false;
            newErrors[val] = `${name} date is invalid`;
        } else if (endDate && startDate && startDate > endDate) {
            formIsValid = false;
            newErrors.startDate = 'Start date must be before end date';
            newErrors.endDate = 'End date must be after start date';
        } else {
            newErrors[val] = '';
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const handleValidation = () => {
        const formIsValid = true;

        if (
            validateEmail() &&
            validateDate(DatePickerType.Start) &&
            validateDate(DatePickerType.End)
        ) {
            setIsValid(true);
        } else setIsValid(false);

        return formIsValid;
    };

    return (
        <LocalizationProvider
            dateAdapter={DateFnsAdapter}
            locale={locale === 'en-US' ? localeMap['en'] : localeMap['de']}
        >
            <div>
                <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    disableEnforceFocus
                >
                    <DialogTitle
                        disableTypography
                        className={classes.dialogTitle}
                        id="customized-dialog-title"
                    >
                        <Typography>Extract Statistics</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <form className={classes.formContainer} noValidate autoComplete="off">
                            <TimezoneSelect
                                value={selectedTimezone}
                                onChange={setSelectedTimezone}
                                timezones={{
                                    ...allTimezones,
                                }}
                                className={classes.label}
                            />
                            <TextField
                                label={`'Please specify the email address to which the statistics for event ${state.eventCode} should be sent to`}
                                value={email}
                                onChange={(e) => setEmail(e.target.value.trim())}
                                onBlur={validateEmail}
                                error={errors.email !== ''}
                                helperText={errors.email !== '' ? errors.email : ''}
                                placeholder={`Email`}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.label}
                            />
                            {renderDateTimePicker(DatePickerType.Start)}
                            {renderDateTimePicker(DatePickerType.End)}
                            {errorMessage !== '' ? (
                                <FormHelperText error>{errorMessage}</FormHelperText>
                            ) : undefined}
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {!isLoading ? (
                            <span>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>

                                <Button onClick={handleSubmit} color="primary" disabled={!isValid}>
                                    Send
                                </Button>
                            </span>
                        ) : (
                            <span>
                                <div className={classes.progress}>
                                    <CircularProgress />
                                </div>
                            </span>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        </LocalizationProvider>
    );
};

export default ExtractStatisticDialog;
