import axios from 'axios';
import Config from '../../config.json';

const apiUrl = Config.VSF_K8S_API_URL;
const apiVersion = Config.VSF_K8S_API_VERSION;

const servicePrefix = `${apiUrl}/conversation${apiVersion}`;

export const getConversationsInRoom = (roomID: number) => {
    return axios.get(`${servicePrefix}/conversations/rooms/${roomID}`);
};
export const getConversationsInRoomFromID = (conversationId: string) => {
    return axios.get(`${servicePrefix}/conversations/${conversationId}?limit=0`);
};

export const deleteMessage = (messageID: string | number) => {
    return axios.delete(`${servicePrefix}/conversations/messages/${messageID}`);
};

export const deleteAllMessages = (conversationId: string | number) => {
    return axios.delete(`${servicePrefix}/conversations/${conversationId}/messages`);
};

export const sendNewConversationMessage = (
    messageText: string,
    roomId: number,
    conversationId: string,
) => {
    const temporaryBody = {
        message: messageText,
        room_id: roomId,
        conversation_id: conversationId,
    };
    return axios.post(`${servicePrefix}/conversations/messages`, temporaryBody);
};
