import React, { FC, useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import PreferenceRowBooleanPicker from '../../Components/PreferenceRow/PreferenceRowBooleanPicker';
import { Preference } from '../../Models/event.model';
import { getPreferencesByCategory, postPreference } from '../../Services/http/event.service';

const useStyles = makeStyles(() => ({
    avatar: {
        margin: '0 20px 10px 20px',
    },
    infoMessage: {
        color: 'grey',
    },
}));

const initialPrefs = [
    {
        category: '3d',
        name: 'use_event_avatars',
        value: 'no',
        initialValue: 'no',
    },
    {
        category: 'display_avatar_details',
        name: 'hide_profile_picture',
        value: 'no',
        initialValue: 'no',
    },
    {
        category: 'camera',
        name: 'hide_camera_toggle',
        value: 'yes',
        initialValue: 'yes',
    },
];

const AvatarContainer: FC = () => {
    const classes = useStyles();
    const { state } = useEventManagement();
    const { enqueueSnackbar } = useSnackbar();

    const [disabled, setDisabled] = useState<boolean>(true);
    const [avatarPreference, setAvatarPreference] = useState<Preference[]>(initialPrefs);

    const getEventAvatarPreference = useCallback(async () => {
        const body = {
            event_code: state.eventCode,
            type: 'all',
        };
        await getPreferencesByCategory(body).then((response: any) => {
            const allPreferences = response.data.specificPreferences;
            const filteredPreferences = allPreferences.filter((pref: Preference) => {
                if (
                    pref.name === 'use_event_avatars' ||
                    pref.name === 'hide_profile_picture' ||
                    pref.name === 'hide_camera_toggle'
                ) {
                    pref.initialValue = JSON.parse(JSON.stringify(pref.value));
                    return pref;
                }
            });
            const updatedPreferences = initialPrefs.map((pref: Preference) => {
                const updatedPreference = filteredPreferences.find(
                    (filteredPref: Preference) => filteredPref.name === pref.name,
                );
                if (updatedPreference) {
                    return updatedPreference;
                } else {
                    return pref;
                }
            });
            setAvatarPreference(updatedPreferences);
        });
    }, [state.eventCode]);

    useEffect(() => {
        getEventAvatarPreference();
    }, [getEventAvatarPreference]);

    const onSubmit = async () => {
        avatarPreference.forEach(async (currentPref: Preference) => {
            if (currentPref.initialValue !== currentPref.value) {
                const body = {
                    name: currentPref.name,
                    value: currentPref.value,
                    category: currentPref.category,
                    event_code: state.eventCode,
                    type: 'string',
                };
                const result = await postPreference(body)
                    .then(() => getEventAvatarPreference())
                    .then((success) => true)
                    .catch((error) => false);

                enqueueSnackbar(result ? 'Preferences were updated' : 'There was an error', {
                    variant: result ? 'success' : 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            }
        });
        setDisabled(true);
    };

    const onCancel = () => {
        setDisabled(true);
        getEventAvatarPreference();
    };

    const handleChange = (newValue: string, prefName: string) => {
        const updAvatarPreference = avatarPreference.map((currentAvaPref) => {
            if (currentAvaPref.name === prefName) {
                currentAvaPref.value = newValue;
                return currentAvaPref;
            } else {
                return currentAvaPref;
            }
        });
        let somethingChanged;
        for (const preference of updAvatarPreference) {
            if (preference.initialValue !== preference.value) {
                somethingChanged = true;
                break;
            }
        }
        if (somethingChanged) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        setAvatarPreference(updAvatarPreference);
    };

    const filterPref = (name: string) => {
        const selectedPref = avatarPreference.filter(
            (preference: Preference) => preference.name === name,
        );
        return selectedPref[0];
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="avatar-content"
                id="avatar-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.AVATAR}</strong>
                </Typography>
            </AccordionSummary>
            <div className={classes.avatar}>
                {filterPref('use_event_avatars') && (
                    <PreferenceRowBooleanPicker
                        preference={filterPref('use_event_avatars')}
                        onChange={(newValue) =>
                            handleChange(newValue, filterPref('use_event_avatars').name)
                        }
                    />
                )}
                <Typography className={classes.infoMessage}>
                    <small> *Default avatar is used when no event avatar is uploaded</small>
                </Typography>
            </div>
            <div className={classes.avatar}>
                {filterPref('hide_profile_picture') && (
                    <PreferenceRowBooleanPicker
                        preference={filterPref('hide_profile_picture')}
                        onChange={(newValue) =>
                            handleChange(newValue, filterPref('hide_profile_picture').name)
                        }
                    />
                )}
            </div>
            <div className={classes.avatar}>
                {filterPref('hide_camera_toggle') && (
                    <PreferenceRowBooleanPicker
                        preference={filterPref('hide_camera_toggle')}
                        onChange={(newValue) =>
                            handleChange(newValue, filterPref('hide_camera_toggle').name)
                        }
                    />
                )}
            </div>
            <CancelAndConfirmButton onCancel={onCancel} onSubmit={onSubmit} disabled={disabled} />
        </Accordion>
    );
};

export default AvatarContainer;
