import axios from 'axios';
import { logout } from '../Services/http/auth.service';
import { clearLocalStorageItemValue } from '../Services/local-storage.service';

const axiosInterceptor = (currentUser: any) => {
    axios.interceptors.request.use(
        (config) => {
            if (currentUser?.jwt_token && config?.headers) {
                config.headers['Authorization'] = `Bearer ${currentUser.jwt_token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401 || error?.response?.data?.error_code === 401.1) {
                logout().then(() => {
                    clearLocalStorageItemValue('currentUser');
                    window.location.reload();
                });
            }

            return Promise.reject(error);
        },
    );
};

export default axiosInterceptor;
