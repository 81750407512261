import React, { FC, ReactElement, useEffect, useState, cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Preference } from '../../Models/event.model';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    name: {
        width: '50%',
    },
    inputField: {
        width: '30%',
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: 25,
        fontWeight: 'bold',
    },
    valueChanged: {
        width: '20%',
        textAlign: 'center',
    },
    colorPicker: {
        '& .MuiInputBase-input': {
            color: 'black',
        },
    },
}));

interface Props {
    preference: Preference;
    onChange: (newValue: string) => any;
    children: ReactElement;
}

const PreferenceRow: FC<Props> = ({ preference, onChange, children }) => {
    const classes = useStyles();
    const [isChanged, setIsChanged] = useState(false);
    const preferenceTitle = preference.title ? preference.title : preference.name;

    useEffect(() => {
        checkIfValueChanged(preference.value, preference.initialValue!);
    }, [preference]);

    const checkIfValueChanged = (newValue: string, initialValue: string) => {
        if (newValue !== initialValue) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    };

    const original = onChange;
    // ...we extend the onChange function that is passed down as a prop so as to check whether the value has been changed
    onChange = (...args) => {
        const newValue = original.apply(this, args);
        checkIfValueChanged(newValue, preference.initialValue!);
    };

    return (
        <div className={classes.container}>
            <div className={classes.name}>{preferenceTitle}</div>
            <span className={classes.inputField}>
                {/* ...whatever you pass into PreferenceRow as a child needs to expose an onChange prop */}
                {cloneElement(children, {
                    onChange,
                })}
            </span>
            <div className={classes.valueChanged} style={{ color: isChanged ? 'red' : 'grey' }}>
                {isChanged ? 'Value changed' : 'No change'}
            </div>
        </div>
    );
};

export default PreferenceRow;
