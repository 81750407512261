export enum EventAdministrationItems {
    PICTURES_AND_BANNER = 'Pictures and Banners',
    FAQ = 'Frequently Asked Questions',
    OPENING_HOURS = 'Opening Hours',
    COLOR_PALETTE = 'Color Palette',
    LABELS_3D = '3D Labels',
    PASSPORT = 'Passport',
    REGISTRATION = 'Registration Form',
    DISPLAY = 'Display',
    MANDATORY = 'Mandatory',
    LABEL = 'Label',
    USER_MANUAL = 'User Manual',
    HELP_DESK_EMAIL = 'Helpdesk Email',
    AVATAR = 'Avatar',
    BACKGROUND_MUSIC = 'Background Music',
    IDENTITY_PROVIDER = 'Identity Provider',
}
