import React, { FC, useEffect, useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Typography, TextField, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useEventManagement from '../../../Hooks/EventManagement/useEventManagement';

export interface Props {
    isDialogOpen: boolean;
    handleUpload: ((files: File[], email: string, sendEmailToUsers: boolean) => void) | undefined;
    onClose: (() => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            marginBottom: theme.spacing(1),
            whiteSpace: 'nowrap',
        },
        dialogTitle: {
            padding: theme.spacing(1, 3),
            '& .MuiTypography-root': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: theme.spacing(1),
        },
        closeButton: {
            color: theme.palette.grey[500],
            transform: 'translateX(25%)',
        },
        dialogContent: {
            '& div': {
                marginBottom: theme.spacing(1),
            },
        },
        progress: {
            marginRight: '20px',
        },
    }),
);

const UploadUserDialog: FC<Props> = ({ isDialogOpen, handleUpload, onClose }) => {
    const classes = useStyles();
    const [emailError, setEmailError] = useState('');
    const [email, setEmail] = useState('');
    const [file, setFile] = useState<File[]>([]);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sendEmailToUsers, setSendEmailToUsers] = useState(false);

    const { state } = useEventManagement();

    useEffect(() => {
        if (email.length && file.length) handleValidation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, file]);

    const validateEmail = () => {
        let newErrors = emailError;
        let formIsValid = true;
        const mailformat =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // ...email
        if (email === '') {
            formIsValid = false;
            newErrors = 'Cannot be empty';
        } else if (!email.match(mailformat)) {
            formIsValid = false;
            newErrors = 'Username is not valid';
        } else {
            newErrors = '';
        }

        setEmailError(newErrors);
        return formIsValid;
    };

    const handleValidation = () => {
        if (validateEmail() && file.length === 1) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const uploadFile = () => {
        handleUpload && email && handleUpload(file, email, sendEmailToUsers);
    };

    const toggleDialog = () => {
        setEmail('');
        setEmailError('');
        setIsValid(false);
        setSendEmailToUsers(false);
        onClose && onClose();
    };

    const handleSubmit = async () => {
        if (isValid && email && file) {
            setIsLoading(true);
            await uploadFile();
            await setIsLoading(false);
        }
    };

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={isDialogOpen}
            fullWidth
            onClose={toggleDialog}
        >
            <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
                {' '}
                <Typography>Upload Users</Typography>{' '}
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={toggleDialog}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent} dividers>
                <form className={classes.formContainer}>
                    <TextField
                        required
                        label={`Please specify the email address to which the upload summary for event ${state.eventCode} should be sent to`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value.trim())}
                        onBlur={validateEmail}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={emailError !== ''}
                        helperText={emailError !== '' ? emailError : ''}
                        placeholder={`Email`}
                        className={classes.label}
                    />
                    <FormControlLabel
                        control={<Checkbox color="secondary" checked={sendEmailToUsers} />}
                        label="Send registration email to each new user"
                        labelPlacement="end"
                        onChange={() => setSendEmailToUsers(!sendEmailToUsers)}
                    />
                    <DropzoneArea
                        acceptedFiles={['.csv', '.xlsx']}
                        showPreviews={true}
                        maxFileSize={5000000}
                        filesLimit={1}
                        onChange={(newFileObjs: any) => {
                            setFile(newFileObjs);
                        }}
                    />
                </form>
                <h5>
                    The creation of many users takes a moment. Please expect an email with the
                    attached information in a couple of minutes.
                </h5>
            </DialogContent>
            <DialogActions>
                {!isLoading ? (
                    <span>
                        <Button onClick={toggleDialog} color="secondary">
                            Cancel
                        </Button>

                        <Button color="primary" onClick={handleSubmit} disabled={!isValid}>
                            Send
                        </Button>
                    </span>
                ) : (
                    <span>
                        <div className={classes.progress}>
                            <CircularProgress />
                        </div>
                    </span>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default UploadUserDialog;
