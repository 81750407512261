import React, { FC } from 'react';
import { UserCell, UserSubCell } from '../../Models/table-cells.model';
import SubCellsTable from '../../Components/SubCellsTable/SubCellsTable';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getLocalStorageItemValue } from '../../Services/local-storage.service';

interface Props {
    cell: UserCell;
    renderSubCellActionItems: (subCell: UserSubCell, cell: UserCell) => any;
    renderAddRolesActionElement: (cell: UserCell) => any;
}

const SubCellsTableContainer: FC<Props> = ({
    cell,
    renderSubCellActionItems,
    renderAddRolesActionElement,
}) => {
    const currentUser: any = JSON.parse(getLocalStorageItemValue('currentUser'));

    return (
        <>
            <SubCellsTable cell={cell} renderSubCellActionItems={renderSubCellActionItems} />
            <TableRow>
                {currentUser.roles.some((role: any) => role.role === 'admin') && (
                    <>
                        <TableCell colSpan={3} />
                        <TableCell>{renderAddRolesActionElement(cell)}</TableCell>
                    </>
                )}
            </TableRow>
        </>
    );
};

export default SubCellsTableContainer;
