import React, { useReducer, createContext, Reducer, Dispatch, FC } from 'react';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

interface UpdateCurrentUserAction {
    type: typeof UPDATE_CURRENT_USER;
    payload: any;
}

type AuthActions = UpdateCurrentUserAction;

type State = {
    currentUser: any;
};

const initialState: State = {
    currentUser: null,
};

export const AuthContext = createContext<{ state: State; dispatch: Dispatch<AuthActions> }>({
    state: initialState,
    dispatch: () => {},
});

const authReducer: Reducer<State, AuthActions> = (state: State, action: AuthActions) => {
    switch (action.type) {
        case UPDATE_CURRENT_USER: {
            return {
                ...state,
                currentUser: action.payload,
            };
        }
        default:
            return state;
    }
};

export const AuthContextProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    const value = { state, dispatch };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
