import React, { FC } from 'react';
import ActionConfirmDialog from '../../Components/Dialogs/ActionConfirmDialog/ActionConfirmDialog';
import { UserSubCell } from '../../Models/table-cells.model';
import { removeAllRoles } from '../../Services/http/auth.service';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import ActionButton from '../../Components/ActionButton/ActionButton';
import { UPDATE_USER_LIST } from '../../Contexts/userManagementContext';

interface Props {
    onCompleteCallback: () => void;
    subCell: UserSubCell;
    username: string;
}

const DeleteUserRolesActionContainer: FC<Props> = ({ onCompleteCallback, subCell, username }) => {
    const { state, dispatch } = useUserManagement();
    const [open, setOpen] = React.useState(false);

    const onDelete = async (subCell: UserSubCell, username: string) => {
        const userId = state.users.find((user) => user.username === username)?.id;
        if (userId) {
            removeAllRoles(userId, subCell.event).then(() => {
                const newUsers = state.users.map((_user) => {
                    if (_user.id === userId) {
                        if (_user.roles) {
                            _user.roles = [
                                ..._user.roles.filter((role) => role.event_code !== subCell.event),
                            ];
                        }
                    }
                    return _user;
                });
                dispatch({ type: UPDATE_USER_LIST, payload: { users: newUsers } });
                setOpen(false);
                onCompleteCallback();
            });
        }
    };

    const onToggle = () => {
        setOpen(!open);
    };

    return (
        <>
            <ActionButton onClick={onToggle} text={'Delete'} icon={null} />
            <ActionConfirmDialog
                open={open}
                onClose={onToggle}
                onConfirm={() => onDelete(subCell, username)}
                title={'Delete event assignment'}
                content={`Do you want to delete the event assignment ${subCell.event} for user ${username} ?`}
            />
        </>
    );
};

export default DeleteUserRolesActionContainer;
