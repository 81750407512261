import axios from 'axios';
import Config from '../../config.json';

const apiUrl = Config.VSF_K8S_API_URL;
const apiVersion = Config.VSF_K8S_API_VERSION;

const servicePrefix = `${apiUrl}/statistics${apiVersion}`;

export const getStatistic = (code: any, body: any) => {
    return axios.get(`${servicePrefix}/export/event/${code}`, {
        params: body,
    });
};

export const downloadUsersListBadgesClaim = (eventCode: string) => {
    return axios.get(`${servicePrefix}/passport/claim/${eventCode}`);
};
