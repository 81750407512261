import React, { createContext, Dispatch, FC, Reducer, useReducer } from 'react';

// Actions
export const UPDATE_HELP_DRAWER_IS_ACTIVE = 'UPDATE_HELP_DRAWER_IS_ACTIVE';

// Action interface
interface UpdateHelpDrawerIsActiveAction {
    type: typeof UPDATE_HELP_DRAWER_IS_ACTIVE;
    payload: {
        helpDrawerIsActive: boolean;
    };
}

// Actions type
type LayoutManagementActions = UpdateHelpDrawerIsActiveAction;

// State
type State = {
    helpDrawerIsActive: boolean;
};

const initialState: State = {
    helpDrawerIsActive: false,
};

export const LayoutManagementContext = createContext<{
    state: State;
    dispatch: Dispatch<LayoutManagementActions>;
}>({
    state: initialState,
    dispatch: () => {},
});

const layoutManagementReducer: Reducer<State, LayoutManagementActions> = (
    state: State,
    action: LayoutManagementActions,
) => {
    switch (action.type) {
        case UPDATE_HELP_DRAWER_IS_ACTIVE: {
            return {
                ...state,
                helpDrawerIsActive: action.payload.helpDrawerIsActive,
            };
        }
        default:
            return state;
    }
};

export const LayoutManagementContextProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(layoutManagementReducer, initialState);

    const value = { state, dispatch };

    return (
        <LayoutManagementContext.Provider value={value}>
            {children}
        </LayoutManagementContext.Provider>
    );
};
