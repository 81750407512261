import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { FC, useEffect, useState } from 'react';
import { UserCell, RoleCell } from '../../Models/table-cells.model';

export interface Props {
    row: UserCell;
    isSelected: boolean;
    handleClick: (cell: UserCell) => void;
    index: number;
    renderSubCellTable: (cell: UserCell) => any;
    collapseAll: Boolean;
}

const compressRoles = (cell: UserCell) => {
    const newCell: UserCell = JSON.parse(JSON.stringify(cell));
    let newRoles: RoleCell[];
    newCell.subCells.map((subCell) => {
        newRoles = [];
        subCell.roles.map((role) => {
            if (newRoles.findIndex((index) => index.role === role.role) !== -1 && role.room) {
                const newRolesIndex = newRoles.findIndex((index) => index.role === role.role);
                if (newRoles[newRolesIndex].room === null) {
                    newRoles[newRolesIndex].room = `${role.room}`;
                } else {
                    newRoles[newRolesIndex].room += `, ${role.room}`;
                }
            } else {
                newRoles.push(role);
            }
        });
        subCell.roles = newRoles;
    });
    newCell.subCells.sort((a, b) => {
        if (a.event) {
            return a.event.localeCompare(b.event);
        } else return -1;
    });
    return newCell;
};

const Row: FC<Props> = ({
    row,
    isSelected,
    handleClick,
    index,
    renderSubCellTable,
    collapseAll = false,
}) => {
    const [cell, setCell] = useState<UserCell>(compressRoles(row));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setCell(compressRoles(row));

        if (collapseAll) {
            setOpen(false);
        }
    }, [row, collapseAll]);

    return (
        <React.Fragment>
            <TableRow hover tabIndex={-1} key={cell.id} selected={isSelected}>
                <TableCell padding="checkbox">
                    <Checkbox
                        onClick={(event) => handleClick(row)}
                        checked={isSelected}
                        inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
                    />
                </TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell
                    component="th"
                    id={`enhanced-table-checkbox-${index}`}
                    scope="row"
                    padding="none"
                >
                    {cell.id}
                </TableCell>
                <TableCell>{cell.username}</TableCell>
                <TableCell>{cell.firstname}</TableCell>
                <TableCell>{cell.lastname}</TableCell>
                <TableCell>{cell.email}</TableCell>
                <TableCell>{cell.company}</TableCell>
                <TableCell>{cell.title}</TableCell>
                <TableCell>{cell.active ? 'Yes' : 'No'}</TableCell>
                <TableCell>{cell.not_tracked ? 'True' : 'False'}</TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="sub-cell">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Event Code</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell colSpan={2}>Room Access</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderSubCellTable(cell)}</TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default Row;
