import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { TextareaAutosize, TextField } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface Props {
    open: boolean;
    selectedUsers: any[];
    onClose: () => void;
    onConfirm: (action: string, message: string) => void;
}

const DeactivateUserDialog: FC<Props> = ({ open, onClose, onConfirm, selectedUsers }) => {
    const [message, setMessage] = React.useState('');
    const deactivateUser =
        selectedUsers.length > 1
            ? `Do you accept the report of these ${selectedUsers.length} users and deactivate their accounts?`
            : 'Do you accept the report of this user and deactivate the account?';

    return (
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                Take Action
            </DialogTitle>
            <DialogContent dividers>
                <Typography color="primary" gutterBottom>
                    {deactivateUser}
                </Typography>
                {selectedUsers.map((eachUser, index) => (
                    <Typography key={index} gutterBottom>
                        {index + 1}. {eachUser.reported_user_username}
                    </Typography>
                ))}
                <TextField
                    placeholder="Optional message"
                    multiline
                    rows={2}
                    rowsMax={4}
                    onChange={(evt) => setMessage(evt.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={() => onConfirm('reject', message)} color="primary">
                    Reject
                </Button>
                <Button onClick={() => onConfirm('accept', message)} color="primary">
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeactivateUserDialog;
