import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { DatePickerType } from '../../Models/date.model';
interface Props {
    startTime: Date;
    endTime: Date;
    isNew?: boolean;
    onDelete: (start?: Date, end?: Date) => void;
    onEdit: (newDate: Date, dateType: DatePickerType) => void;
    isStartEdited?: boolean;
    isEndEdited?: boolean;
}

enum Status {
    NORMAL = 'black',
    NEW = 'green',
    EDITED = 'orange',
    DELETED = 'red',
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        tableRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        deleted: {
            '& .MuiInputBase-input': {
                color: 'red',
                textDecoration: 'line-through',
            },
        },
        edited: {
            '& .MuiInputBase-input': {
                color: 'orange',
            },
        },
        added: {
            '& .MuiInputBase-input': {
                color: 'green',
            },
        },
        notAdded: {
            display: 'none',
        },
        blue: {
            color: 'blue',
            background: 'blue',
        },
        cell5: {
            // display: 'flex',
            alignItems: 'center',
            flex: 5,
        },
        cell2: {
            display: 'flex',
            alignItems: 'center',
            flex: 2,
        },
        buttons: {
            width: '50%',
        },
    }),
);

const OpeningHours: FC<Props> = ({
    startTime,
    endTime,
    isNew = false,
    onDelete,
    onEdit,
    isStartEdited = false,
    isEndEdited = false,
}) => {
    const classes = useStyles();
    const [isDeleted, setIsDeleted] = useState(false);
    const [error, setError] = useState(false);
    const [startingTime, setStartingTime] = React.useState<Date | null>(startTime as Date);
    const [endingTime, setEndingTime] = React.useState<Date | null>(endTime as Date);

    const [startTimeStatus, setStartTimeStatus] = useState<Status>(Status.NORMAL);
    const [endTimeStatus, setEndTimeStatus] = useState<Status>(Status.NORMAL);
    const locale = window.navigator.language;

    function handleDelete() {
        onDelete();
        if (isNew) {
            return;
        }
        setIsDeleted(true);
        setStartTimeStatus(Status.DELETED);
        setEndTimeStatus(Status.DELETED);
    }

    const localeMap = {
        en: enLocale,
        de: deLocale,
    };

    useEffect(() => {
        if (isNew) {
            setStartTimeStatus(Status.NEW);
            setEndTimeStatus(Status.NEW);
        } else if (isStartEdited) {
            setStartTimeStatus(Status.EDITED);
        }
        if (isEndEdited) {
            setEndTimeStatus(Status.EDITED);
        }
    }, [isNew, isStartEdited, isEndEdited]);

    useEffect(() => {
        setStartingTime(startTime);
    }, [startTime]);

    useEffect(() => {
        setEndingTime(endTime);
    }, [endTime]);

    useEffect(() => {
        handleError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startingTime, endingTime]);

    const handleError = () => {
        startingTime && endingTime && startingTime > endingTime ? setError(true) : setError(false);
    };

    const renderDateTimePicker = (type: DatePickerType, state: Status) => {
        let colorClass = '';

        switch (state) {
            case Status.NEW: {
                colorClass = classes.added;
                break;
            }
            case Status.EDITED: {
                colorClass = classes.edited;
                break;
            }
            case Status.DELETED: {
                colorClass = classes.deleted;
                break;
            }
            default: {
                break;
            }
        }

        const isStartDate = type === DatePickerType.Start;

        return (
            <TableCell className={colorClass}>
                <DateTimePicker
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            error={error}
                            helperText={error && 'Start date must be before end date'}
                        />
                    )}
                    label={isStartDate ? 'Start Date' : 'End Date'}
                    value={isStartDate ? startingTime : endingTime}
                    onChange={(newValue) => {
                        if (isNew) {
                            if (isStartDate) {
                                setStartingTime(newValue);
                            } else {
                                setEndingTime(newValue);
                            }
                            onEdit(newValue as Date, type);
                            return;
                        }

                        if (isStartDate) {
                            setStartingTime(newValue);
                            setStartTimeStatus(Status.EDITED);
                        } else {
                            setEndingTime(newValue);
                            setEndTimeStatus(Status.EDITED);
                        }

                        onEdit(newValue as Date, type);

                        if (startTimeStatus === Status.DELETED) {
                            setStartTimeStatus(Status.EDITED);
                        }
                        if (endTimeStatus === Status.DELETED) {
                            setEndTimeStatus(Status.EDITED);
                        }

                        setIsDeleted(false);
                    }}
                />
            </TableCell>
        );
    };

    return (
        <LocalizationProvider
            dateAdapter={DateFnsAdapter}
            locale={locale === 'en-US' ? localeMap['en'] : localeMap['de']}
        >
            <TableRow>
                {renderDateTimePicker(DatePickerType.Start, startTimeStatus)}
                {renderDateTimePicker(DatePickerType.End, endTimeStatus)}
                <TableCell>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={handleDelete}
                        className={classes.buttons}
                        disabled={isDeleted}
                    >
                        Delete
                    </Button>
                </TableCell>
            </TableRow>
        </LocalizationProvider>
    );
};

export default OpeningHours;
