import React, { FC, ReactNode, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableToolbar from '../EnhancedTableToolbar/EnhancedTableToolbar';
import EnhancedTableHead, { HeadCell } from '../EnhancedTableHead/EnhancedTableHead';
import { getComparator, Order, stableSort } from '../../Utils/tableHelpers';
import { ReportedUserCell } from '../../Models/table-cells.model';
import CircularProgress from '@material-ui/core/CircularProgress';

function createData(
    event_code: string,
    reported_user_id: number,
    reported_user_username: string,
    reported_by_user_id: number,
    reported_by_user_username: string,
    action: string,
    summary: string,
    timestamp_creation: string,
    timestamp_validation: string,
    validation_notes: string,
    status: string,
): ReportedUserCell {
    return {
        event_code,
        reported_user_id,
        reported_user_username,
        reported_by_user_id,
        reported_by_user_username,
        action,
        summary,
        timestamp_creation,
        timestamp_validation,
        validation_notes,
        status,
    };
}

const headCells: HeadCell<ReportedUserCell>[] = [
    { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
    { id: 'reported_user_username', numeric: false, disablePadding: false, label: 'Reported User' },
    {
        id: 'reported_by_user_username',
        numeric: false,
        disablePadding: false,
        label: 'Reporter',
    },
    { id: 'summary', numeric: false, disablePadding: false, label: 'Reported Reason' },
    { id: 'timestamp_creation', numeric: false, disablePadding: false, label: 'Reported Time' },
    {
        id: 'timestamp_validation',
        numeric: false,
        disablePadding: false,
        label: 'Action Time',
    },
    {
        id: 'validation_notes',
        numeric: false,
        disablePadding: false,
        label: 'Notes',
    },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        loaderWrapper: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transaction: 'translateX(-50%), translateY(-50%)',
        },
        disabledRow: {
            backgroundColor: 'rgba(150, 150, 140, 0.08)',
        },
        summary: { fontSize: '16px' },
    }),
);

export interface Props {
    selectedItems: ReportedUserCell[];
    setSelectedItems: (events: ReportedUserCell[]) => void;
    reportedUsers: ReportedUserCell[];
    actionItems: ReactNode[];
    page: number;
    setPage: (newPage: number) => void;
    isLoading: boolean;
}

const ReportedUserManagementTable: FC<Props> = ({
    selectedItems = [],
    setSelectedItems,
    reportedUsers,
    actionItems,
    page,
    setPage,
    isLoading = true,
}) => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof ReportedUserCell>('status');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [rows, setRows] = useState([] as any[]);

    useEffect(() => {
        const _rows: any[] = [];
        reportedUsers.forEach((reportedUser) => {
            const {
                event_code,
                reported_user_id,
                reported_user_username,
                reported_by_user_id,
                reported_by_user_username,
                action,
                summary,
                timestamp_creation,
                timestamp_validation,
                validation_notes,
                status,
            } = reportedUser;
            _rows.push(
                createData(
                    event_code,
                    reported_user_id,
                    reported_user_username,
                    reported_by_user_id,
                    reported_by_user_username,
                    action,
                    summary,
                    timestamp_creation,
                    timestamp_validation,
                    validation_notes,
                    status,
                ),
            );
        });
        setRows(_rows);
    }, [reportedUsers]);

    const handleRequestSort = (
        evt: React.MouseEvent<unknown>,
        property: keyof ReportedUserCell,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.checked) {
            setSelectedItems(rows.filter((row: any) => row.status === 'open'));
            return;
        }
        setSelectedItems([]);
    };

    const handleClick = (evt: React.MouseEvent<unknown>, reportedUser: ReportedUserCell) => {
        const isReportedUserSelected = isSelected(reportedUser);

        if (isReportedUserSelected) {
            setSelectedItems(
                selectedItems.filter(
                    (item) => item.reported_user_username !== reportedUser.reported_user_username,
                ),
            );
        } else {
            setSelectedItems(selectedItems.concat(reportedUser));
        }
    };

    const handleChangePage = (evt: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(evt.target.value, 10));
        setPage(0);
    };

    const isSelected = (item: ReportedUserCell): boolean => {
        let isEventSelected = false;

        for (let i = 0; i < selectedItems.length; i++) {
            if (selectedItems[i].reported_user_username === item.reported_user_username) {
                isEventSelected = true;
                break;
            }
        }

        return isEventSelected;
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const dateOptions: any = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selectedItems.length}
                    actionItems={actionItems}
                />
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={'small'}>
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selectedItems.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.filter((row: any) => row.status === 'open').length}
                            headCells={headCells}
                            checkboxColSpan={1}
                        />
                        <TableBody>
                            {isLoading && (
                                <div className={classes.loaderWrapper}>
                                    <CircularProgress />
                                </div>
                            )}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            className={
                                                row.status === 'closed'
                                                    ? classes.disabledRow
                                                    : undefined
                                            }
                                            hover
                                            onClick={(evt) =>
                                                row.status !== 'closed' &&
                                                handleClick(evt, row as ReportedUserCell)
                                            }
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.action}</TableCell>
                                            <TableCell>{row.reported_user_username}</TableCell>
                                            <TableCell>{row.reported_by_user_username}</TableCell>
                                            <TableCell>
                                                <Tooltip
                                                    title={
                                                        <div className={classes.summary}>
                                                            {row.summary}
                                                        </div>
                                                    }
                                                    arrow
                                                    disableHoverListener={row.summary?.length <= 14}
                                                >
                                                    <div>
                                                        {row.summary !== 'undefined'
                                                            ? row.summary?.length > 14
                                                                ? `${row.summary.substring(
                                                                      0,
                                                                      14,
                                                                  )}... `
                                                                : row.summary
                                                            : ''}
                                                    </div>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                {row.timestamp_creation &&
                                                    `${new Date(
                                                        row.timestamp_creation,
                                                    ).toLocaleDateString(
                                                        navigator.language,
                                                        dateOptions,
                                                    )} ${new Date(
                                                        row.timestamp_creation,
                                                    ).toLocaleTimeString(navigator.language)}`}
                                            </TableCell>
                                            <TableCell>
                                                {row.timestamp_validation &&
                                                    `${new Date(
                                                        row.timestamp_validation,
                                                    ).toLocaleDateString(
                                                        navigator.language,
                                                        dateOptions,
                                                    )} ${new Date(
                                                        row.timestamp_validation,
                                                    ).toLocaleTimeString(navigator.language)}`}
                                            </TableCell>
                                            <TableCell>{row?.validation_notes}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50]}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
};

export default ReportedUserManagementTable;
