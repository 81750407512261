export enum FileTypes {
    POSTER = 'poster',
    BANNER = 'banner',
    EMAIL = 'email_assets',
    FAQ = 'FAQ',
    LOGIN_INFORMATION_CONTENT = 'login_information_content',
    TRANSITION_CONTENT = 'transition_content',
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    FIRSTNAME = 'firstname',
    LASTNAME = 'lastname',
    COMPANY = 'company',
    JOB_TITLE = 'job_title',
    PROFILE_PIC = 'profile_pic',
    USER_MANUAL = 'user_manual',
}
