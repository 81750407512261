import React, { FC, useEffect, useState } from 'react';
import { useContext } from 'react';

import Container from '@material-ui/core/Container';
import { Breadcrumbs, Button, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import EventManagementTableContainer from '../../Containers/EventManagementTableContainer/EventManagementTableContainer';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import PicturesAndBannerAccordionContainer from '../../Containers/PicturesAndBannersAccordionContainer/PicturesAndBannersAccordionContainer';
import { EDIT_ADMINISTRATION } from '../../Contexts/eventManagementContext';
import FaqAccordionContainer from '../../Containers/FaqAccordionContainer/FaqAccordionContainer';
import OpeningHoursAccordionContainer from '../../Containers/OpeningHoursAccordionContainer/OpeningHoursAccordionContainer';
import ColorPaletteAccordionContainer from '../../Containers/ColorPaletteAccordionContainer/ColorPaletteAccordionContainer';
import Labels3dAccordionContainer from '../../Containers/Labels3dAccordionContainer/Labels3dAccordionContainer';
import RegistrationContainer from '../../Containers/RegistrationContainer/RegistrationContainer';
import LoginIntroductionScreenAndTransitionVideoAccordionContainer from '../../Containers/LoginIntroductionScreenAndTransitionVideoAccordionContainer/LoginIntroductionScreenAndTransitionVideoAccordionContainer';
import EmailTemplatesAccordionContainer from '../../Containers/EmailTemplatesAccordionContainer/EmailTemplatesAccordionContainer';
import PassportAccordionContainer from '../../Containers/PassportAccordionContainer/PassportAccordionContainer';
import UserManualContainer from '../../Containers/UserManualContainer/UserManualContainer';
import HelpdeskEmailContainer from '../../Containers/HelpdeskEmailContainer/HelpdeskEmailContainer';
import AvatarContainer from '../../Containers/AvatarContainer/AvatarContainer';
import BackgroundMusicContainer from '../../Containers/BackgroundMusicContainer/BackgroundMusicContainer';
import IdentityProviderAccordionContainer from '../../Containers/IdentityProviderContainer/IdentityProviderAccordionContainer';
import { AuthContext } from '../../Contexts/authContext';
import { Role } from '../../Models/user.model';

const useStyles = makeStyles((theme: Theme) => ({
    breadcrumb: {
        marginTop: theme.spacing(6),
    },
    buttonRow: {
        margin: '40px 0',
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        width: '80%',
        marginLeft: '10%',
    },
    button: {
        width: '30%',
        fontSize: 14,
        fontWeight: 'bold',
    },
    title: {
        marginLeft: '18%',
        width: '100%',
    },
}));

export const EventManagementPage: FC = () => {
    const classes = useStyles();
    const { state, dispatch } = useEventManagement();
    const currentUser = useContext(AuthContext).state.currentUser;
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const [isAdministrationOpen, setIsAdministrationOpen] = useState<boolean>();

    useEffect(() => {
        const { isAdministrationOpen: administration } = state;
        setIsAdministrationOpen(administration);
    }, [state]);

    useEffect(() => {
        setIsAdmin(
            currentUser.roles.some(
                (role: Role) =>
                    role.role === 'admin' ||
                    (role.role === 'event-admin' && currentUser.event_code === state.eventCode),
            ),
        );
    }, [currentUser, state.eventCode]);

    return (
        <Container maxWidth={'xl'}>
            {!isAdministrationOpen && (
                <>
                    <div className={classes.breadcrumb}>
                        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                            <Link color="inherit" href="/">
                                Home
                            </Link>
                            <Link color="textPrimary" href="/user-management" aria-current="page">
                                Event Management
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <EventManagementTableContainer />
                </>
            )}
            {isAdministrationOpen && (
                <div className={classes.wrapper}>
                    <div className={classes.buttonRow}>
                        <Button
                            variant="contained"
                            onClick={() => dispatch({ type: EDIT_ADMINISTRATION, payload: false })}
                            className={classes.button}
                        >
                            Back to list of events
                        </Button>
                        <h1 className={classes.title}>
                            Event Administration for {state.eventCode}
                        </h1>
                    </div>
                    <PicturesAndBannerAccordionContainer />
                    <OpeningHoursAccordionContainer />
                    {isAdmin && <IdentityProviderAccordionContainer />}
                    <FaqAccordionContainer />
                    <ColorPaletteAccordionContainer />
                    <Labels3dAccordionContainer />
                    <RegistrationContainer />
                    <LoginIntroductionScreenAndTransitionVideoAccordionContainer />
                    <EmailTemplatesAccordionContainer />
                    <PassportAccordionContainer />
                    <UserManualContainer />
                    <HelpdeskEmailContainer />
                    <AvatarContainer />
                    <BackgroundMusicContainer />
                </div>
            )}
        </Container>
    );
};
