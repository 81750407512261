import React, { FC, useEffect, useState } from 'react';
import ColorPicker from 'material-ui-color-picker';
import { Preference } from '../../Models/event.model';
import PreferenceRow from './PreferenceRow';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    colorPicker: {
        '& .MuiInputBase-input': {
            color: 'black',
        },
    },
}));

interface Props {
    preference: Preference;
    onChange: (preference: string) => void;
}

const PreferenceRowColorPicker: FC<Props> = ({ preference, onChange }) => {
    const classes = useStyles();
    const [colorHex, setColorHex] = useState(preference.initialValue);

    const onColorChange = (newValue: any) => {
        if (newValue.toString() !== colorHex) setColorHex(newValue.toString());
        return newValue.toString();
    };

    const original = onChange;
    // ...we extend the onChange function that is passed down as a prop with the behaviour specific to numbers
    onChange = (...args) => {
        // ...call it
        const newValue = onColorChange.apply(this, args);
        original.apply(this, [newValue]);
        return newValue.toString();
    };

    return (
        <PreferenceRow preference={preference} onChange={onChange}>
            {/* ...the onChange props is shallow merged into the children by PreferenceRow */}
            <ColorPicker
                name="color"
                value={colorHex}
                onChange={onChange}
                placeholder={preference.value.toString()}
                className={classes.colorPicker}
                InputProps={{ readOnly: true }}
            />
        </PreferenceRow>
    );
};

export default PreferenceRowColorPicker;
