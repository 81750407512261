import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import PreferenceRowMultiPicker from '../../Components/PreferenceRow/PreferenceRowMultiPicker';
import PreferenceRowCheckBox from '../../Components/PreferenceRow/PreferenceRowCheckBox';
import { Preference } from '../../Models/event.model';
import { RegistrationFields } from '../../Enums/RegistrationFields.enum';
import { List } from '@material-ui/core';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import {
    getPreferencesByCategory,
    patchPreferences,
    postPreference,
} from '../../Services/http/event.service';
import { useSnackbar } from 'notistack';
import RegistrationFormElementHeader from '../../Components/PreferenceRow/RegistrationFormElementHeader';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';

const useStyles = makeStyles(() =>
    createStyles({
        checkbox: {
            display: 'flex',
            flexDirection: 'column',
            width: 'inherit',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        widthBannerlist: {
            width: '100%',
        },
        bannersList: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px',
        },
        bannersListItems: {
            padding: '0 7px',
        },
        titlePadding: {
            padding: '0 95px',
        },
        accordion: {
            display: 'flex',
            flexDirection: 'column',
        },
        tableHead: {
            background: 'gray',
        },
        tableHeadCell: {
            color: 'white',
        },
        tableRow: {
            display: 'flex',
            alignItems: 'center',
        },
        table: {
            justifySelf: 'center',
            marginLeft: 20,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        buttonWrapper: {
            marginBottom: '1%',
            marginRight: '2%',
        },
        buttons: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        button: {
            width: '50%',
            margin: '2%',
            flex: 1,
        },
        text: {
            flex: 2,
            marginLeft: -15,
        },
    }),
);

const RegistrationContainer = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useEventManagement();
    const [disabled, setDisabled] = useState(true);
    const [activatePreferences, setActivatePreferences] = useState<Preference[]>([]);
    const [mainTitleSelected, setMainTitleSelected] = useState<boolean>(false);
    const [subtitleSelected, setSubtitleSelected] = useState<boolean>(false);
    const [firstnameSelected, setFirstnameSelected] = useState<boolean>(false);
    const [lastnameSelected, setLastnameSelected] = useState<boolean>(false);
    const [emailSelected, setEmailSelected] = useState<boolean>(false);
    const [companySelected, setCompanySelected] = useState<boolean>(false);
    const [jobTitleSelected, setJobTitleSelected] = useState<boolean>(false);
    const [profilePicSelected, setProfilePicSelected] = useState<boolean>(false);

    const onCancel = async () => {
        setDisabled(true);
        setMainTitleSelected(false);
        setSubtitleSelected(false);
        setFirstnameSelected(false);
        setLastnameSelected(false);
        setEmailSelected(false);
        setCompanySelected(false);
        setJobTitleSelected(false);
        setProfilePicSelected(false);
        await getRegistrationPreferences();
    };

    const onConfirm = async () => {
        submitPreferences();
    };

    const updateButtonStatus = () => {
        const status =
            mainTitleSelected ||
            subtitleSelected ||
            firstnameSelected ||
            lastnameSelected ||
            emailSelected ||
            companySelected ||
            jobTitleSelected ||
            profilePicSelected;
        setDisabled(!status);
    };

    const onBoolChange = (
        newValue: any,
        field: RegistrationFields,
        fieldName: string,
        fieldPreference: Preference,
    ) => {
        fieldPreference.changed = true;
        fieldPreference.value === newValue ? setDisabled(true) : setDisabled(false);
        fieldPreference.value = newValue;

        const isFieldChanged = fieldPreference.value === newValue ? true : false;
        switch (field) {
            case RegistrationFields.MAIN_TITLE:
                setMainTitleSelected(isFieldChanged);
                break;
            case RegistrationFields.SUBTITLE:
                setSubtitleSelected(isFieldChanged);
                break;
            case RegistrationFields.FIRSTNAME:
                setFirstnameSelected(isFieldChanged);
                break;
            case RegistrationFields.LASTNAME:
                setLastnameSelected(isFieldChanged);
                break;
            case RegistrationFields.EMAIL:
                setEmailSelected(isFieldChanged);
                break;
            case RegistrationFields.COMPANY:
                setCompanySelected(isFieldChanged);
                break;
            case RegistrationFields.JOB_TITLE:
                setJobTitleSelected(isFieldChanged);
                break;
            case RegistrationFields.PROFILE_PIC:
                setProfilePicSelected(isFieldChanged);
                break;
            default: {
                break;
            }
        }
    };

    const submitPreferences = async () => {
        const results = await Promise.all(
            activatePreferences.map(async (preference: Preference) => {
                if (preference.changed) {
                    if (preference.changed) delete preference.changed;
                    if (preference.initialValue) delete preference.initialValue;

                    if (preference.subcategory === null) delete preference.subcategory;
                    const pref: any = preference;
                    delete pref.id;
                    delete pref.title;
                    if (pref.new) {
                        delete pref.new;
                        pref.type = 'string';
                        return await postPreference(pref)
                            .then((success) => true)
                            .catch((error) => false);
                    } else if (pref.id) {
                        delete pref.name;
                        return await patchPreferences(pref.id, pref)
                            .then((success) => true)
                            .catch((error) => false);
                    }
                }
            }),
        );
        const hasError = results.includes(false);
        enqueueSnackbar(!hasError ? 'Preferences were updated' : 'There was an error', {
            variant: !hasError ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
        onCancel();
    };

    const renderShowLinkCheckBox = () => {
        const fieldTitle = `Showlink to registration page on login page`;
        const field = RegistrationFields.SHOWLINK_CHECKBOX.toString();
        const newArr = [...activatePreferences].filter((pref) => pref.name === field);
        let currentPref: Preference;
        if (newArr.length < 1) {
            pushPreference(newArr, field, 'no');
            currentPref = newArr[0];
        } else {
            currentPref = newArr[0];
            currentPref.initialValue = currentPref.value;
        }
        currentPref.title = fieldTitle;
        return (
            <PreferenceRowCheckBox
                preference={newArr[0]}
                onChange={(newValue) => onShowLinkChange(newValue, field, currentPref)}
            />
        );
    };
    const onShowLinkChange = (newValue: any, name: string, pref: Preference) => {
        pref.changed = true;
        pref.initialValue === newValue ? setDisabled(true) : setDisabled(false);
        pref.value = newValue;
    };
    const renderBooleanPicker = (field: RegistrationFields) => {
        let showDisplayedCheckbox = true;
        let showMandatoryCheckbox = true;
        let showLabel = true;
        switch (field) {
            case RegistrationFields.MAIN_TITLE:
                showDisplayedCheckbox = false;
                showMandatoryCheckbox = false;
                showLabel = true;
                break;
            case RegistrationFields.SUBTITLE:
                showDisplayedCheckbox = false;
                showMandatoryCheckbox = false;
                showLabel = true;
                break;
            case RegistrationFields.FIRSTNAME:
                showDisplayedCheckbox = true;
                showMandatoryCheckbox = true;
                showLabel = true;
                break;
            case RegistrationFields.LASTNAME:
                showDisplayedCheckbox = true;
                showMandatoryCheckbox = true;
                showLabel = true;
                break;
            case RegistrationFields.EMAIL:
                showDisplayedCheckbox = false;
                showMandatoryCheckbox = false;
                showLabel = true;
                break;
            case RegistrationFields.COMPANY:
                showDisplayedCheckbox = true;
                showMandatoryCheckbox = true;
                showLabel = true;
                break;
            case RegistrationFields.JOB_TITLE:
                showDisplayedCheckbox = true;
                showMandatoryCheckbox = true;
                showLabel = true;
                break;
            case RegistrationFields.PROFILE_PIC:
                showDisplayedCheckbox = true;
                showMandatoryCheckbox = true;
                showLabel = false;
                break;
            default: {
                break;
            }
        }
        const dummyPrefValue: Preference = {
            changed: false,
            name: 'dummy',
            category: 'register',
            value: 'no',
            initialValue: ' ',
            new: true,
            event_code: state.eventCode,
        };
        let displayPrefValue: Preference;
        const fieldDisplay = `${field.toString()}_Display`;
        const newArrDisplay = [...activatePreferences].filter((pref) => pref.name === fieldDisplay);
        if (newArrDisplay.length < 1) {
            if (showDisplayedCheckbox) {
                pushPreference(newArrDisplay, fieldDisplay, 'no');
                displayPrefValue = newArrDisplay[0];
            } else {
                displayPrefValue = dummyPrefValue;
            }
        } else {
            displayPrefValue = newArrDisplay[0];
        }

        let mandatoryPrefValue: Preference;
        const fieldMandatory = `${field.toString()}_Mandatory`;
        const newArrMandatory = [...activatePreferences].filter(
            (pref) => pref.name === fieldMandatory,
        );
        if (newArrMandatory.length < 1) {
            if (showMandatoryCheckbox) {
                pushPreference(newArrMandatory, fieldMandatory, 'no');
                mandatoryPrefValue = newArrMandatory[0];
            } else {
                mandatoryPrefValue = dummyPrefValue;
            }
        } else {
            mandatoryPrefValue = newArrMandatory[0];
        }

        let labelPrefValue: Preference;
        const fieldLabel = `${field.toString()}_Label`;
        const newArrLabel = [...activatePreferences].filter((pref) => pref.name === fieldLabel);
        if (newArrLabel.length < 1) {
            if (showLabel) {
                pushPreference(newArrLabel, fieldLabel, ' ');
                labelPrefValue = newArrLabel[0];
            } else {
                labelPrefValue = dummyPrefValue;
            }
        } else {
            labelPrefValue = newArrLabel[0];
        }

        return (
            <PreferenceRowMultiPicker
                rowTitleStr={field.toString()}
                preferenceDisplay={displayPrefValue}
                preferenceMandatory={mandatoryPrefValue}
                preferenceLabel={labelPrefValue}
                originalDisplay={(newValue) =>
                    onBoolChange(newValue, field, fieldDisplay, newArrDisplay[0])
                }
                originalMandatory={(newValue) =>
                    onBoolChange(newValue, field, fieldMandatory, newArrMandatory[0])
                }
                originalLabel={(newValue) =>
                    onBoolChange(newValue, field, fieldLabel, newArrLabel[0])
                }
                showDisplayedCheckbox={showDisplayedCheckbox}
                showMandatoryCheckbox={showMandatoryCheckbox}
                showLabelCheckbox={showLabel}
            />
        );
    };

    const pushPreference = (newArrParam: any, nameParam: string, initialValueParam: string) => {
        const x: Preference = {
            changed: false,
            name: nameParam,
            category: 'register',
            value: 'no',
            initialValue: initialValueParam,
            new: true,
            event_code: state.eventCode,
        };
        newArrParam.push(x);
        activatePreferences.push(x);
    };
    const getRegistrationPreferences = useCallback(async () => {
        const body = {
            event_code: state.eventCode,
            type: 'specific',
            category: 'register',
        };
        await getPreferencesByCategory(body).then((response) => {
            const registrationPreferencesNames = [
                RegistrationFields.MAIN_TITLE.toString(),
                RegistrationFields.SUBTITLE.toString(),
                RegistrationFields.FIRSTNAME.toString(),
                RegistrationFields.LASTNAME.toString(),
                RegistrationFields.EMAIL.toString(),
                RegistrationFields.COMPANY.toString(),
                RegistrationFields.JOB_TITLE.toString(),
                RegistrationFields.PROFILE_PIC.toString(),
                RegistrationFields.SHOWLINK_CHECKBOX.toString(),
            ];
            const registerPreferences = response.data.filter((preference: Preference) => {
                const fieldFromPref = preference.name.split('_')[0];
                return registrationPreferencesNames.includes(fieldFromPref);
            });
            // ...set initial values
            for (const preference of registerPreferences) {
                preference.initialValue = JSON.parse(JSON.stringify(preference.value));
                const newValue = preference.value === 'no' ? false : true;
                switch (preference.name) {
                    case RegistrationFields.MAIN_TITLE.toString():
                        setMainTitleSelected(newValue);
                        break;
                    case RegistrationFields.SUBTITLE.toString():
                        setSubtitleSelected(newValue);
                        break;
                    case RegistrationFields.FIRSTNAME.toString():
                        setFirstnameSelected(newValue);
                        break;
                    case RegistrationFields.LASTNAME.toString():
                        setLastnameSelected(newValue);
                        break;
                    case RegistrationFields.EMAIL.toString():
                        setEmailSelected(newValue);
                        break;
                    case RegistrationFields.COMPANY.toString():
                        setCompanySelected(newValue);
                        break;
                    case RegistrationFields.JOB_TITLE.toString():
                        setJobTitleSelected(newValue);
                        break;
                    case RegistrationFields.PROFILE_PIC.toString():
                        setProfilePicSelected(newValue);
                        break;
                    default: {
                        break;
                    }
                }
            }

            setActivatePreferences(registerPreferences);
            setDisabled(true);
        });
    }, [state.eventCode]);

    useEffect(() => {
        getRegistrationPreferences();
    }, [getRegistrationPreferences]);

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.REGISTRATION}</strong>
                </Typography>
            </AccordionSummary>

            {/* Adopt the following code accodrding to your requirments */}
            <List className={classes.bannersList}>
                <RegistrationFormElementHeader></RegistrationFormElementHeader>
                {renderBooleanPicker(RegistrationFields.MAIN_TITLE)}
                {renderBooleanPicker(RegistrationFields.SUBTITLE)}
                {renderBooleanPicker(RegistrationFields.FIRSTNAME)}
                {renderBooleanPicker(RegistrationFields.LASTNAME)}
                {renderBooleanPicker(RegistrationFields.EMAIL)}
                {renderBooleanPicker(RegistrationFields.COMPANY)}
                {renderBooleanPicker(RegistrationFields.JOB_TITLE)}
                {renderBooleanPicker(RegistrationFields.PROFILE_PIC)}
                {renderShowLinkCheckBox()}
            </List>
            <CancelAndConfirmButton
                onCancel={() => onCancel()}
                onSubmit={() => onConfirm()}
                disabled={disabled}
            />
        </Accordion>
    );
};

export default RegistrationContainer;
