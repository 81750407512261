import React, { ChangeEvent, FC } from 'react';
import { IconButton, MenuItem, Select } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pagination: {
            display: 'inline-flex',
            position: 'relative',
            right: 0,
            justifyContent: 'right',
            alignItems: 'center',
            width: 'calc(100% - 50px)',
        },
    }),
);
interface Props {
    page: number;
    setPage: Function;
    rowsPerPage: number;
    selectRows: any;
    totalCount: number;
}

const PaginationComponent: FC<Props> = ({ page, setPage, rowsPerPage, selectRows, totalCount }) => {
    const classes = useStyles();
    const previousPage = () => {
        setPage(page - 1);
    };
    const nextPage = () => {
        setPage(page + 1);
    };

    return (
        <div id="pagination" className={classes.pagination}>
            <Select
                labelId="pagination-select"
                id="pagination-select"
                value={rowsPerPage}
                onChange={selectRows}
            >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
            </Select>
            <IconButton
                onClick={previousPage}
                disabled={page === 1}
                color="primary"
                aria-label="previous page"
            >
                <NavigateBeforeIcon />
            </IconButton>
            {page * rowsPerPage - rowsPerPage + 1} -{' '}
            {page * rowsPerPage > totalCount ? totalCount : page * rowsPerPage}
            {' of '}
            {totalCount ? totalCount : 0}
            <IconButton
                color="primary"
                aria-label="next page"
                onClick={nextPage}
                disabled={page * rowsPerPage >= (totalCount || 0)}
            >
                <NavigateNextIcon />
            </IconButton>
        </div>
    );
};
export default PaginationComponent;
