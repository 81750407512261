import React, { FC } from 'react';
import { Preference } from '../../Models/event.model';
import PreferenceRow from './PreferenceRow';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    numberPicker: {
        '& .MuiOutlinedInput-root': {
            height: '42px',
        },
    },
}));

interface Props {
    preference: Preference;
    min: number;
    max: number;
    step: number;
    onChange: (preference: string) => void;
}

const PreferenceRowNumberPicker: FC<Props> = ({ preference, onChange, max, min, step }) => {
    const classes = useStyles();
    const onTextfieldChanged = (event: any) => {
        const newValue = event.target.value;
        return newValue.toString();
    };

    const original = onChange;
    // ...we extend the onChange function that is passed down as a prop with the behaviour specific to numbers
    onChange = (...args) => {
        // ...call it
        const newValue = onTextfieldChanged.apply(this, args);
        original.apply(this, [newValue]);
        return newValue.toString();
    };

    return (
        <PreferenceRow preference={preference} onChange={onChange}>
            {/* ...the onChange props is shallow merged into the children by PreferenceRow */}
            <TextField
                type="number"
                inputProps={{ max, min, step }}
                variant="outlined"
                placeholder={preference.initialValue}
                onChange={() => {}}
                className={classes.numberPicker}
            />
        </PreferenceRow>
    );
};

export default PreferenceRowNumberPicker;
