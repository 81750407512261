import React, { FC, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import { UPDATE_EVENTS } from '../../Contexts/userManagementContext';
import { fetchAllEvents } from '../../Services/http/event.service';
import useUserManagement from '../../Hooks/UserManagement/useUserManagement';
import { Event } from '../../Models/event.model';
import useAuth from '../../Hooks/Auth/useAuth';

interface Props {
    onCompleteCallback: (value: string) => void;
    fieldDisabled: boolean;
}

const EventFilterActionContainer: FC<Props> = ({ fieldDisabled, onCompleteCallback }) => {
    const { state: userState, dispatch } = useUserManagement();
    const { state: authState } = useAuth();

    const [events, setEvents] = React.useState<Event[]>([
        {
            name: 'All events',
            event_code: 'All events',
            mode: 'all_events',
            description: 'all events',
        },
    ]);
    const [selectedEvent, setSelectedEvent] = React.useState<Event>(events[0]);
    const [hasEventAdminRole] = React.useState(
        authState.currentUser.roles.some((role: any) => role.role === 'event-admin') &&
            !authState.currentUser.roles.some((role: any) => role.role === 'admin'),
    );

    useEffect(() => {
        if (events?.length < 1) {
            setEvents(events.concat(userState?.events));
        } else {
            fetchAllEvents().then((res: any) => {
                setEvents(
                    events.concat(
                        res?.data?.events.sort((a: any, b: any) => {
                            if (a.event_code) {
                                return a.event_code.localeCompare(b.event_code);
                            } else return -1;
                        }),
                    ),
                );
                dispatch({ type: UPDATE_EVENTS, payload: { events: res.data.events } });
            });
        }

        if (selectedEvent) {
            emitCallback(selectedEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (hasEventAdminRole && authState) {
            const activeEventCode = authState.currentUser?.event_code;
            if (activeEventCode) {
                const currentEvent = {
                    name: activeEventCode,
                    event_code: activeEventCode,
                    mode: activeEventCode,
                    description: activeEventCode,
                };
                setEvents([currentEvent]);
                setSelectedEvent(currentEvent);
                emitCallback(currentEvent);
            }
        }
        if (fieldDisabled) {
            setSelectedEvent(events[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldDisabled]);

    useEffect(() => {}, [events, selectedEvent, hasEventAdminRole]);

    const changeActiveEvent = (value: any) => {
        if (!value) {
            value = events[0];
        }
        setSelectedEvent(value);
        emitCallback(value);
    };

    const emitCallback = (value: Event) => {
        if (hasEventAdminRole || value?.event_code !== 'All events') {
            onCompleteCallback(value?.event_code);
        }
        if (
            value?.event_code === 'All events' ||
            (fieldDisabled &&
                (value?.event_code !== 'All events' || value?.event_code === 'All events'))
        ) {
            onCompleteCallback(events[0].event_code);
        }
    };

    return (
        <Autocomplete
            id="event-based-filter"
            onChange={(event, value) => changeActiveEvent(value)}
            options={events}
            getOptionLabel={(option) => option.event_code}
            value={selectedEvent}
            style={{ width: 300 }}
            disabled={fieldDisabled || hasEventAdminRole}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
    );
};

export default EventFilterActionContainer;
