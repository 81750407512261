import React, { KeyboardEvent, FC, useEffect, useCallback } from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import { TextField } from '@material-ui/core';
import { getPreferencesByCategory, postPreference } from '../../Services/http/event.service';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 400,
        },
    },
    bannersList: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20px',
    },
    emailWrapper: { margin: '0 20px 10px 20px' },
    input: {
        '&::placeholder': {
            textAlign: 'center',
        },
    },
    loaderWrapper: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transaction: 'translateX(-50%), translateY(-50%)',
    },
    infoMessage: {
        color: '#b71c1c',
    },
    defaultMessage: {
        color: 'grey',
    },
}));

const HelpdeskEmailContainer: FC = () => {
    const classes = useStyles();
    const { state } = useEventManagement();
    const { enqueueSnackbar } = useSnackbar();

    const [helpEmail, setHelpEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [showInfoMessage, setShowInfoMessage] = useState<boolean>(false);
    const [showNoChange, setShowNoChange] = useState<boolean>(true);

    const getHelpEmail = useCallback(async () => {
        const body = {
            name: 'help_desk_email',
            event_code: state.eventCode,
            type: 'specific',
        };
        await getPreferencesByCategory(body).then((response: any) => {
            const preference = response.data;
            const email = preference[0] ? JSON.parse(JSON.stringify(preference[0].value)) : '';
            setHelpEmail(email);
        });
    }, [state.eventCode]);

    useEffect(() => {
        getHelpEmail();
    }, [getHelpEmail]);

    // Toggle body on click
    const onClick = () => {
        isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    };

    // On confirm button click
    const onSubmit = (e?: any) => {
        e.preventDefault();
        if (helpEmail) {
            // Regex control
            const emailReg = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$');
            const isEmailValid = emailReg.test(helpEmail);

            if (isEmailValid) {
                uploadNewHelpDeskEmail(helpEmail, state?.eventCode);
            } else {
                setHasError(true);
                setErrorMessage('Enter a valid email address');
                setSubmitDisabled(true);
                setDisabled(false);
                setShowNoChange(true);
                setShowInfoMessage(false);
            }
        }
    };

    // On upload new helpdesk email id
    const uploadNewHelpDeskEmail = (emailId: string, event_code: string) => {
        setIsLoading(true);
        if (emailId && event_code) {
            const body = {
                value: emailId.trim(),
                name: 'help_desk_email',
                category: 'general',
                event_code: state.eventCode,
                type: 'string',
            };
            postPreference(body)
                .then((res: any) => {
                    if (res?.status === 200) {
                        setDisabled(false);
                        setIsExpanded(true);
                        setShowNoChange(true);
                        setSubmitDisabled(false);
                        setShowInfoMessage(false);
                        setIsLoading(false);
                        setHasError(false);
                        enqueueSnackbar(
                            'The new help desk email address have been uploaded successfully.',
                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                            },
                        );
                    }
                })
                .catch((e: any) => {
                    setIsLoading(false);
                    setIsExpanded(true);
                    setShowInfoMessage(false);
                    setErrorMessage(e?.response?.data?.message);
                    enqueueSnackbar(e?.response?.data?.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                });
        }
    };

    // On cancel button click
    const onCancel = () => {
        getHelpEmail();
        setDisabled(true);
        setSubmitDisabled(true);
        setShowNoChange(true);
        setShowInfoMessage(false);
    };

    // On email input value change
    const onEmailValueChange = (event?: any) => {
        if (event) {
            setHelpEmail(event.target?.value);
            setIsExpanded(true);
            setDisabled(false);
            setHasError(false);
            setSubmitDisabled(false);
            setShowInfoMessage(true);
        }
    };

    // To submit on "Keyboard Enter" key press
    const onKeyPress = (e: KeyboardEvent<any>) => {
        if (e.key === 'Enter') {
            onSubmit(e);
        }
    };

    // To display help input form
    const renderEmailForm = () => {
        return (
            <div className={classes.root}>
                <Typography>{'Helpdesk email'}</Typography>
                <TextField
                    value={helpEmail}
                    autoComplete={'email'}
                    placeholder={'Email address'}
                    InputProps={{ classes: { input: classes.input } }}
                    onChange={onEmailValueChange}
                    onKeyDown={(e: any) => onKeyPress(e)}
                />
                {showInfoMessage && showNoChange && !hasError && (
                    <Typography className={classes.infoMessage}>{'Value changed'}</Typography>
                )}
                {!showInfoMessage && helpEmail && hasError && (
                    <Typography className={classes.infoMessage}>{errorMessage}</Typography>
                )}
                {!(showInfoMessage || hasError) && showNoChange && (
                    <Typography className={classes.defaultMessage}>{'No change'}</Typography>
                )}
            </div>
        );
    };

    return (
        <Accordion expanded={isExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={'helpdesk-email-content'}
                id={'helpdesk-email-header'}
                onClick={onClick}
            >
                <Typography>
                    <strong>{EventAdministrationItems.HELP_DESK_EMAIL}</strong>
                </Typography>
            </AccordionSummary>
            <div className={classes.emailWrapper}>{renderEmailForm()}</div>
            <CancelAndConfirmButton
                disabled={disabled}
                disabledSubmit={submitDisabled}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
            {isLoading && (
                <div className={classes.loaderWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Accordion>
    );
};

export default HelpdeskEmailContainer;
