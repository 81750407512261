import React, { FC, useState } from 'react';
import ActionButton from '../../Components/ActionButton/ActionButton';
import { getStatistic } from '../../Services/http/statistics.service';
import { Event, Statistic } from '../../Models/event.model';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { useSnackbar } from 'notistack';
import ExtractStatisticDialog from '../../Components/Dialogs/ExtractStatisticDialog/ExtractStatisticDialog';

interface Props {
    onCompleteCallback: () => void;
}

const ExtractStatisticActionContainer: FC<Props> = ({ onCompleteCallback }) => {
    const { state } = useEventManagement();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    const onSubmit = async (statistic: Statistic) => {
        await getStatistic(state.eventCode, statistic)
            .then((response) => {
                onCompleteCallback();
                onToggle();
                enqueueSnackbar(
                    'Event statistics are being created and will be sent shortly to the provided email address',
                    {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    },
                );
            })
            .catch((e) => {
                setErrorMessage(e.response.data.error);
                enqueueSnackbar(e.response.data.error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            });
    };

    const onToggle = () => {
        if (!open) {
            const { events, selectedEvents } = state;
            events.forEach((event) => {
                // ...button is only rendered when one event is selected
                if (event.event_code === selectedEvents[0].event_code) {
                    setSelectedEvent(event);
                }
            });
        } else {
            setSelectedEvent(null);
        }
        setErrorMessage('');
        setOpen(!open);
    };

    return (
        <>
            <ActionButton onClick={onToggle} text={'Extract Statistics'} />
            {open && (
                <ExtractStatisticDialog
                    onSubmit={onSubmit}
                    toggleDialog={onToggle}
                    errorMessage={errorMessage}
                />
            )}
        </>
    );
};

export default ExtractStatisticActionContainer;
