import React, { useState } from 'react';

const useTableSearch = (setSelectedItem: Function) => {
    const [searchText, setSearchText] = useState('');

    const onSearch = (text: string) => {
        setSearchText(text);
        setSelectedItem([]);
    };

    return [searchText, setSearchText, onSearch] as const;
};

export default useTableSearch;
