import axios from 'axios';
import Config from '../../config.json';

const apiUrl = Config.VSF_K8S_API_URL;
const apiVersion = Config.VSF_K8S_API_VERSION;
const servicePrefix = `${apiUrl}/user${apiVersion}`;

export const getUserList = (
    limit: number,
    page: number,
    order_by?: string,
    order_direction?: string,
    search?: string,
    event_code?: string,
    user_role?: string,
) => {
    const params = {
        limit: limit,
        page: page,
        frontend: 'admin',
    };
    if (order_by) Object.assign(params, { order_by: order_by });
    if (order_direction) Object.assign(params, { order_direction: order_direction });
    if (search) Object.assign(params, { search: search });
    if (event_code) Object.assign(params, { event_code: event_code });
    if (user_role) Object.assign(params, { role: user_role });
    return axios.get(`${servicePrefix}/users`, { params });
};

export const anonymizeUser = (userId: number) => {
    return axios.patch(`${servicePrefix}/user/${userId}/deactivate`);
};

export const editUser = (
    user: {
        email: string;
        firstname: string;
        lastname: string;
        job_title: string;
        company: string;
        active: boolean;
        not_tracked: boolean;
    },
    editUserId: number,
) => {
    return axios.patch(`${servicePrefix}/user/${editUserId}`, user);
};

export const getReportedUsers = () => {
    return axios.get(`${servicePrefix}/reported-users`);
};

export const deactivateReportedUser = (
    userId: number,
    action: string,
    validation_notes?: string,
) => {
    const payload = {
        validation_notes,
    };
    return axios.patch(`${servicePrefix}/report/user/${userId}`, payload, { params: { action } });
};

export const deleteUser = (userId: any) => {
    return axios.delete(`${servicePrefix}/user/${userId}`);
};

export const importUsersFromFile = (formDate: FormData, email: string, send_email: boolean) => {
    return axios.post(`${servicePrefix}/importusers`, formDate, { params: { email, send_email } });
};

export const downloadUsers = () => {
    return axios.get(`${servicePrefix}/export-users`, { responseType: 'blob' });
};
