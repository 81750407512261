import axios from 'axios';
import { FileTypes } from '../../Enums/FileTypes.enum';
import Config from '../../config.json';
import { BaseRoom } from '../../Models/socket.model';

const apiUrl = Config.VSF_K8S_API_URL;
const apiVersion = Config.VSF_K8S_API_VERSION;
const servicePrefix = `${apiUrl}/content${apiVersion}`;

export const getRooms = (event_code?: string) => {
    const params = {};
    if (event_code) {
        Object.assign(params, { event_code: event_code });
    }
    return axios.get(`${servicePrefix}/rooms`, {
        params,
    });
};

export const createRoom = (baseRoom: BaseRoom, event_code: string) => {
    const payload = {
        ...baseRoom,
    };
    return axios.post(`${servicePrefix}/rooms`, payload, { params: { event_code } });
};

export const updateRoom = (baseRoom: BaseRoom) => {
    const payload = {
        ...baseRoom,
        id: undefined,
    };
    return axios.patch(`${servicePrefix}/rooms${baseRoom.id ? `/${baseRoom.id}` : ''}`, payload);
};

export const deleteRoom = (room_id: number) => {
    return axios.delete(`${servicePrefix}/rooms/${room_id}`);
};

export const getFile = (filetype: string, filename: string, eventCode?: string) => {
    const params = { filetype, filename };

    if (eventCode) {
        Object.assign(params, { event_code: eventCode });
    }

    return axios.get(`${servicePrefix}/files`, {
        params,
        responseType: 'blob' as 'json',
    });
};

export const postFile = (file: File, filetype: FileTypes, event_code: string) => {
    const formData = new FormData();
    formData.append(file.name, file);
    return axios.post(`${servicePrefix}/files`, formData, {
        params: {
            filetype,
            event_code,
        },
    });
};

export const deleteFile = (filename: string, filetype: FileTypes, event_code: string) => {
    return axios.delete(`${servicePrefix}/files`, {
        params: {
            filetype,
            event_code,
            filename,
        },
    });
};
